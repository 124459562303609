var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue_checkbox_filters" }, [
    _c(
      "ul",
      _vm._l(_vm.options, function(option) {
        return _c("li", [
          _c("div", { staticClass: "form-group form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: {
                name: _vm.name,
                type: "checkbox",
                id: _vm.name + "_" + option.id
              },
              on: {
                change: function($event) {
                  $event.preventDefault()
                  return _vm.select(option.id)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: _vm.name + "_" + option.id }
              },
              [_vm._v(_vm._s(option.name))]
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn-switch-lang",
      attrs: { "current-lang": _vm.lang },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleLang()
        }
      }
    },
    [
      _c(
        "span",
        { staticClass: "lang_label", class: { current: _vm.lang == "es" } },
        [_vm._v(_vm._s(_vm._f("trans")("general.es")))]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "lang_label", class: { current: _vm.lang == "eu" } },
        [_vm._v(_vm._s(_vm._f("trans")("general.eu")))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div :key="key">
        <div v-if="is_full_table_error" class="box--shadowed bg-danger text-white mb-4">
            {{ 'general.same_menu' | trans }}
            <ul>
                <li v-for="menu in selected_menu_error">{{ menu.name }}</li>
            </ul>
        </div>

        <div v-for="(diner, index) in diners" class="collapse_container box--shadowed"
             v-bind:class="{'mt-4': index > 0}">
            <div data-toggle="collapse" v-bind:data-target="'#diner_' + index"
                 aria-expanded="false" v-bind:aria-controls="'#diner_' + index"
                 class="d-flex justify-content-between align-items-center collapse__toggler">
                <h3 class="collapse__toggler__label text-brand" v-bind:class="{'text-danger': maxed_menu(index) || !hasMenuAndIsRequiredDishSelectedFor(index)}">
                    <span v-if="maxed_menu(index)|| !isRequiredDishSelectedFor(index)">*</span>
                    <span v-if="diner.name">{{ diner.name }}</span>
                    <span v-else>{{ 'general.diner' | trans }} {{ index + 1 }}</span>
                    <template v-if="diner.menu">
                        <span>({{ diner.menu.name }})</span>
                        <span v-if="diner.menu.menu_type_id == 2">
<!--                        <span v-if="diner.menu.menu_type_id == 1">-->
<!--                            {{ getTotalPrice(index) }}-->
<!--                        </span>-->
<!--                        <span v-else>-->
                            {{ getMenuPrice(index) }}
                        </span>
                    </template>
                </h3>
                <i class="icon-ctrl collapse__toggler__icon text-brand"></i>
            </div>
            <div v-bind:id="'diner_' + index" class="collapse">
                <div class="mt-4">
                    <div class="form-group">
                        <label v-bind:for="'name_' + index">{{ 'general.diner_name' | trans }} *</label>
                        <input v-bind:id="'name_' + index" type="text" v-model="diners[index].name"
                               class="form-control">
                    </div>
                    <div class="form-group">
                        <label v-bind:for="'menu_id_' + index">{{ 'general.menu' | trans }}</label>
                        <v-select v-model="diners[index].menu_id"
                                  ref="menu_id" v-bind:id="'menu_id_' + index" name="menu_id"
                                  @input="selectMenuForDiner(index)"
                                  :options="menus | prepare_select('name')"
                                  :reduce="menu => menu.id">
                        </v-select>
                    </div>
                    <template v-if="diners[index].menu && diners[index].menu.menu_type_id == 1">
                        <hr class="full-width">
                        <h3 class="mb-3">{{ 'general.dishes' | trans }}</h3>
                        <template v-for="category in diners[index].menu.elements">
                            <h5 class="my-2">{{ category.name }}</h5>
                            <div v-for="dish in category.dishes" class="form-group form-check">
                                <input v-model="diners[index].dishes" v-bind:value="dish.id" type="checkbox"
                                       v-bind:id="'dish_' + index + '_' + dish.id" class="form-check-input">
                                <label v-bind:for="'dish_' + index + '_' + dish.id" class="form-check-label">
                                    {{ dish.name }} (<span v-if="dish.price !== null">{{ dish.price }}</span> <span v-if="dish.price_type !== null"> {{dish.price_type.label}} </span>)
                                </label>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="diners[index].menu && diners[index].menu.menu_type_id == 2">
                        <template v-if="maxed_menu(index)">
                            <hr class="full-width">
                            <strong class="text-danger">{{ 'general.maxed_out' | trans({number: maxNoPlatesForSelection(index)}) }}</strong>
                        </template>
                        <template v-if="!maxed_menu(index) && !isRequiredDishSelectedFor(index)">
                            <hr class="full-width">
                            <strong class="text-danger">{{ 'general.select_dish_required' | trans }}</strong>
                        </template>
                        <hr class="full-width">
                        <p v-html="diners[index].menu.observations_locale"></p>
                        <template v-if="diners[index].menu.half_menu">
                            <hr class="full-width">
                            <div class="row px-3">
                                <div class="form-check form-check-inline">
                                    <input v-model="diners[index].half_menu"
                                           v-bind:value="false"
                                           type="radio" v-bind:id="'diner_menu_full_' + index"
                                           class="form-check-input">
                                    <label v-bind:for="'diner_menu_full_' + index" class="form-check-label">
                                        {{ 'general.full_menu' | trans }} ({{ diners[index].menu.price }} €)
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input v-model="diners[index].half_menu"
                                           v-bind:value="true"
                                           type="radio" v-bind:id="'diner_menu_half_' + index"
                                           class="form-check-input">
                                    <label v-bind:for="'diner_menu_half_' + index" class="form-check-label">
                                        {{ 'general.half_menu' | trans }} ({{ diners[index].menu.half_price }} €)
                                    </label>
                                </div>
                            </div>
                        </template>
                        <hr v-if="diners[index].menu.observations_locale || diners[index].menu.half_menu" class="full-width">
                        <h3>{{ 'general.dishes' | trans }}</h3>
                        <template v-for="(category, c) in diners[index].menu.elements">
                            <template v-if="category.dish_option == 1 && category.required == 1">
                                <h5 class="my-2">
                                    {{ category.name }}
                                    <small>* {{ 'general.one_required' | trans }}</small>
                                </h5>
                                <div v-for="(dish, i) in category.dishes" class="form-group form-check">
                                    <input v-model="diners[index].dishes"
                                           v-bind:value="dish.id"
                                           type="checkbox" v-bind:id="'dish_' + index + '_' + c + '_' + i"
                                           class="form-check-input">
                                    <label v-bind:for="'dish_' + index + '_' + c + '_' + i" class="form-check-label">
                                        {{ dish.name }}
                                    </label>
                                </div>
                            </template>
                            <template v-else-if="category.required == 1">
                                <h5 class="my-2">
                                    {{ category.name }}
                                    <small>* {{ 'general.at_least_one_required' | trans }}</small>
                                </h5>
                                <div v-for="(dish, i) in category.dishes" class="form-group form-check">
                                    <input v-model="diners[index].dishes"
                                           v-bind:value="dish.id"
                                           type="checkbox" v-bind:id="'dish_' + index + '_' + c + '_' + i"
                                           class="form-check-input">
                                    <label v-bind:for="'dish_' + index + '_' + c + '_' + i" class="form-check-label">
                                        {{ dish.name }}
                                    </label>
                                </div>
                            </template>
                            <template v-else>
                                <h5 class="my-2">
                                    {{ category.name }}
                                </h5>
                                <div v-for="(dish, i) in category.dishes" class="form-group form-check">
                                    <input v-model="diners[index].dishes"
                                           v-bind:value="dish.id"
                                           type="checkbox" v-bind:id="'dish_' + index + '_' + c + '_' + i"
                                           class="form-check-input">
                                    <label v-bind:for="'dish_' + index + '_' + c + '_' + i" class="form-check-label">
                                        {{ dish.name }}
                                    </label>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
        <button class="box--square bg-brand-light text-white mt-4 align-items-center"
                v-bind:disabled="loading" @click.prevent="save(false)">
            <h3>{{ 'general.save' | trans }}</h3>
            <p v-html="$options.filters.trans('general.save_selection_p')"></p>
        </button>
        <button class="box--square bg-brand text-white mt-1 align-items-center"
           v-bind:disabled="loading || is_full_table_error || !can_confirm || !is_all_required_selected" @click.prevent="save(true)">
            <h3>{{ 'general.confirm' | trans }}</h3>
            <p v-html="$options.filters.trans('general.confirm_selection_p')"></p>
        </button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                space: " ",
                diners: this.loaded_diners,
                loading: false,
                key: 1,
            };
        },
        props: ['reservation', 'locale', 'menus', 'loaded_diners'],
        computed: {
            menu_prices() {
                var menu_prices = {};
                this.menus.forEach(menu => {
                    if (menu.menu_type_id == 1) {
                        menu.elements.forEach(category => {
                            category.dishes.forEach(dish => {
                                menu_prices[dish.id] = dish.price;
                            })
                        })
                    }
                });
                return menu_prices;
            },
            can_confirm() {
                var condition = true;
                this.diners.forEach((diner, index) => {
                    if (!diner.menu_id || !diner.name || this.maxed_menu(index)) {
                        condition = false;
                    }
                });
                return condition;
            },
            selected_menus() {
                return _.map(this.diners, diner => {
                    if (diner.menu) {
                        return diner.menu;
                    }
                });
            },
            selected_menu_error() {
                return _.filter(this.selected_menus, menu => {
                    if (menu) {
                        return menu.full_table_required;
                    }
                });
            },
            is_full_table_error() {
                var is_full_table_required = this.selected_menu_error.length;

                if (is_full_table_required > 0) {
                    var unique_menus = {};
                    this.selected_menus.forEach(menu => {
                        if (menu) {
                            unique_menus[menu.id] = menu.full_table_required;
                        }
                    });

                    if (is_full_table_required > 0 && Object.keys(unique_menus).length > 1) {
                        return true;
                    }
                }
                return false;
            },
            is_all_required_selected(){
                var condition = true;
                this.diners.forEach((diner, index) => {
                    if (! this.isRequiredDishSelectedFor(index) ) {
                        condition = false;
                    }
                });
                return condition;
            },
        },
        methods: {
            hasMenuAndIsRequiredDishSelectedFor(index) {
                var condition = false;
                if(this.diners[index].menu === null && !this.isRequiredDishSelectedFor(index)){
                    condition = true;
                }
                if(this.diners[index].menu !== null && this.isRequiredDishSelectedFor(index)){
                    condition = true;
                }
                return condition;
            },
            isRequiredDishSelectedFor(index) {
                var condition = false;
                var max_required = [];
                var required = [];
                var dish_required = [];
                var count = [];

                if (this.diners[index].menu && this.diners[index].menu.elements) {
                    this.diners[index].menu.elements.forEach((elements, key) => {
                        if (elements.required) {
                            max_required[key] = 1; //numero de platos requeridos
                            required[key] = _.map(elements.dishes, dish => {  //añado los platos obligatorios
                                return dish.id;
                            });
                        } else {
                            max_required[key] = 0;
                            required[key] = [0];
                        }
                    });

                    required.forEach((elements, alfa) => {
                        dish_required[alfa] = 0;
                        if (!elements.includes(0)) {
                            //comprobar si algun elemento de elements_dish esta seleccionado en el objeto diners[index].dishes
                            dish_required[alfa] = 0;
                            this.diners[index].dishes.forEach((dish, beta) => {
                                if (elements.includes(dish)) {
                                    dish_required[alfa] = 1;
                                }
                            });
                        } else {
                            dish_required[alfa] = 0;
                        }
                    });

                    //COMPARAR ARRAYS REQUERIDOS
                    count = max_required.length;
                    max_required.forEach((elements, key) => {
                        if (elements === dish_required[key]) {
                            count--;
                        }
                    });

                    if(count === 0){
                        condition = true;
                    }
                }
                // console.log("condition", condition);
                return condition;
            },
            selectMenuForDiner(index) {
                this.diners[index].dishes = [];
                var menu_id = this.diners[index].menu_id;
                var menu = _.find(this.menus, menu => {
                    return menu.id == menu_id;
                });
                this.diners[index].menu = menu;
            },
            getTotalPrice(index) {
                var total = 0;
                this.diners[index].dishes.forEach(dish => {
                    total = total + this.menu_prices[dish];
                });
                if (total > 0) {
                    return '(' + total + ' €)';
                }
            },
            getMenuPrice(index) {
                var total = 0;
                if (this.diners[index].half_menu) {
                    total = this.diners[index].menu.half_price;
                } else {
                    total = this.diners[index].menu.price;
                }
                if (total > 0) {
                    return '(' + total + ' €)';
                }
            },
            save(confirm) {
                if( !this.loading ){
                    this.loading = true;
                    var diners = _.map(this.diners, diner => {
                        return {
                            id: diner.id ? diner.id : null,
                            name: diner.name,
                            half_menu: diner.half_menu,
                            dishes: diner.dishes,
                            menu_id: diner.menu_id
                        };
                    });
                    var params = {diners: diners};
                    axios.post(window.config.context_prefix+'/api/client/reservations/' + this.reservation.id + '/save-selection', params)
                        .then(response => {
                            this.diners = response.data;
                            if (this.diners.length < this.reservation.no_people) {
                                for (var i = this.diners.length; i < this.reservation.no_people; i++) {
                                    this.diners.push({
                                        dishes: [],
                                        half_menu: false,
                                        menu: null,
                                        menu_id: "",
                                        name: "",
                                    });
                                }
                            }
                            if (confirm) {
                                var that = this;
                                Vue.nextTick(function () {
                                    window.location.href = "/cliente/reservas/" + that.reservation.id + "/ver-comensales";
                                })
                            }else{
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                        })
                }
            },
            maxed_menu(index) {
                var condition = false;
                if (this.diners[index].menu) {
                    if (this.diners[index].menu.menu_type_id == 2) {
                        if (this.diners[index].half_menu) {
                            if (this.diners[index].dishes.length > this.diners[index].menu.half_max_no_dishes) {
                                condition = true;
                            }
                        } else {
                            if (this.diners[index].dishes.length > this.diners[index].menu.max_no_dishes) {
                                condition = true;
                            }
                        }
                    }
                }
                return condition;
            },
            maxNoPlatesForSelection(index) {
                var condition = 0;
                if (this.diners[index].menu) {
                    if (this.diners[index].half_menu) {
                        condition = this.diners[index].menu.half_max_no_dishes;
                    } else {
                        condition = this.diners[index].menu.max_no_dishes;
                    }
                }
                return condition;
            },
        },
        mounted() {
            if (this.diners.length > 0) {
                this.diners.forEach((diner, index) => {
                    var temp = diner.dishes;
                    this.selectMenuForDiner(index);
                    this.diners[index].dishes = temp;
                });
            }
            if (this.diners.length < this.reservation.no_people)
                for (var i = this.diners.length; i < this.reservation.no_people; i++) {
                this.diners.push({
                    name: '',
                    menu_id: '',
                    half_menu: false,
                    menu: null,
                    dishes: [],
                })
            }
            this.key += 1;
        }
    }
</script>

<template>
        <button @click.prevent="toggleLang()" :current-lang="lang" class="btn-switch-lang">
            <span class="lang_label" :class="{'current' : lang=='es'}">{{ 'general.es' | trans }}</span>
            <span class="lang_label" :class="{'current' : lang=='eu'}">{{ 'general.eu' | trans }}</span>
        </button>
</template>

<script>
    export default {
        data: function () {
            return {
                lang: 'es'
            }
        },
        methods: {
            toggleLang(){
                (this.lang == 'es') ? this.lang = 'eu' : this.lang = 'es';
                this.$emit('changeLang', this.lang);
            }
        }
  }
</script>

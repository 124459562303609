<template>
    <div class="datatable__body">
        <div class="loader" v-if="loading">
            <h3>{{ 'general.loading' | trans }}</h3>
        </div>
        <template v-if="rows.length > 0">
            <datatable-row
                v-for="row in rows"
                :columns="columns"
                :row="row"
                :key="row.id"
                @reload="reload"
            ></datatable-row>
        </template>
        <div
            v-else-if="!loading"
            class="datatable__row d-flex justify-content-center pb-2">
            {{ 'general.no_data' | trans }}
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        props: ['columns', 'rows', 'loading'],
        methods: {
            reload() {
                this.$emit('reload');
            }
        }
    }
</script>

<template>
    <transition name="fade-shrink-in" appear>
        <div class="modal show" style="padding-right: 17px; display: block;"
             @click.prevent="close(false)">
            <div class="modal-dialog modal-xl modal-dialog-centered" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="myLargeModalLabel">
                            {{ 'general.reservations_details' | trans }}
                        </h5>
                        <button type="button" class="close" @click.prevent="close(false)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-2">
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label>{{ 'general.date' | trans }}</label>
                                    <p>{{ display_date }}</p>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label>{{ 'general.turn' | trans }}</label>
                                    <p>{{ display_turn }}</p>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label class="d-none d-sm-block" style="margin-bottom: 27px;"></label>
                                    <div class="form-check form-check-inline">
                                        <input id="complete_cb" type="checkbox" class="form-check-input"
                                               v-model="is_complete" value="1" @change.prevent="openConfirmationModal">
                                        <label for="complete_cb" class="form-check-label">
                                            {{ 'general.full' | trans }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mb-5" style="margin: 10px -13px;">
                        <datatable url="/api/restaurant/reservations" :prop_filters="filters"></datatable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close(false)">
                            {{ 'general.cancel' | trans }}
                        </button>
                    </div>
                </div>
            </div>
            <portal to="datatable-modals">
                <confirmation-modal
                    v-if="confirmation_modal_open"
                    :url="confirmation_modal_url"
                    :title="title | trans"
                    :text="text | trans"
                    :level="level"
                    :params="params"
                    @close="closeConfrimationModal"
                ></confirmation-modal>
            </portal>
        </div>
    </transition>
</template>

<script>
    import {openModal} from "../modal";

    export default {
        data() {
            return {
                filters: {
                    date: this.date,
                    turns: [this.turn],
                },
                is_complete: false,
                confirmation_modal_open: false,
                confirmation_modal_url: window.config.context_prefix+'/api/restaurant/close-turn',
                level: 'danger',
                params: {turn_id: this.turn, date: this.date.startDate, closed: false},
            };
        },
        props: ['date', 'turn', 'display_date', 'display_turn'],
        computed: {
            title() {
                if (this.is_complete) {
                    return 'general.turn_complete_h';
                } else {
                    return 'general.turn_un_complete_h';
                }
            },
            text() {
                if (this.is_complete) {
                    return 'general.turn_complete_p';
                } else {
                    return 'general.turn_un_complete_p';
                }
            }
        },
        methods: {
            getIsComplete() {
                axios.get(window.config.context_prefix+'/api/restaurant/is-turn-full', {params: this.params})
                    .then(response => {
                        this.is_complete = response.data;
                    });
            },
            close(reload) {
                this.$emit('close', reload);
            },
            openConfirmationModal() {
                this.params.closed = this.is_complete;
                this.confirmation_modal_open = true;
                openModal();
            },
            closeConfrimationModal(reload) {
                if (!reload) {
                    this.is_complete = !this.is_complete;
                }
                this.confirmation_modal_open = false;
            }
        },
        mounted() {
            this.getIsComplete();
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datatable__row" },
    _vm._l(_vm.columns, function(column, key) {
      return _c(
        "div",
        {
          staticClass: "datatable__cell",
          class: "datatable__cell--" + key,
          style: "width:" + column.width + ";",
          attrs: { "aria-label": _vm._f("trans")(column.title) }
        },
        [
          key === "actions"
            ? [
                _c("datatable-actions", {
                  attrs: { actions: _vm.row[key], row: _vm.row },
                  on: { reload: _vm.reload }
                })
              ]
            : [
                column.editable
                  ? _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.row[key],
                            expression: "row[key]"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.row[key] },
                        on: {
                          change: function($event) {
                            return _vm.updateField(_vm.row, key)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.row, key, $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.loading
                        ? _c("div", { staticClass: "input-group-append" }, [
                            _vm._m(0, true)
                          ])
                        : _vm._e()
                    ])
                  : _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.row[key]) }
                    })
              ]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-text" }, [
      _c("i", { staticClass: "icon-spinner spinner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-shrink-in", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal show",
        staticStyle: { "padding-right": "17px", display: "block" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.close(false)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-lg modal-dialog-centered",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title h4",
                    attrs: { id: "myLargeModalLabel" }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.data.date))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "badge bg-brand text-white" }, [
                      _vm._v(_vm._s(_vm.turn_name))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._l(_vm.elements, function(element, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "box--shadowed",
                        class: { "mt-4": index >= 1 }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("h3", [_vm._v(_vm._s(element.menu))]),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "btn-action btn-action--remove",
                              on: {
                                click: function($event) {
                                  return _vm.deleteElement(index)
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-range-picker", {
                          ref: "picker",
                          refInFor: true,
                          attrs: {
                            "locale-data": {
                              firstDay: 1,
                              format: "DD-MM-YYYY"
                            },
                            "auto-apply": _vm.auto_apply,
                            ranges: false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function(picker) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("date")(picker.startDate)
                                        ) +
                                        " - " +
                                        _vm._s(_vm._f("date")(picker.endDate)) +
                                        "\n                            "
                                    )
                                  ])
                                }
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.elements[index].range,
                            callback: function($$v) {
                              _vm.$set(_vm.elements[index], "range", $$v)
                            },
                            expression: "elements[index].range"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.deletes.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "box--shadowed bg-danger text-white",
                          class: { "mt-4": _vm.elements.length > 0 }
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm._f("trans")("general.changes_deletes"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.deletes, function(element) {
                              return _c("li", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("trans")(
                                        "general.element_deleted",
                                        {
                                          menu: element.menu,
                                          from: element.range.startDate,
                                          to: element.range.endDate
                                        }
                                      )
                                    ) +
                                    "\n                            "
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.cancel")) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-brand",
                    attrs: {
                      type: "button",
                      disabled: _vm.save_disabled || _vm.save_loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.save($event)
                      }
                    }
                  },
                  [
                    _vm.save_loading
                      ? _c("i", { staticClass: "spinner icon-spinner" })
                      : _vm._e(),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.save")) +
                        "\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
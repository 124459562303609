var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box--shadowed" },
    [
      _c(
        "div",
        {
          staticClass:
            "mb-4 d-flex justify-content-between align-items-center vue-closings__header"
        },
        [
          _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-brand",
                attrs: { type: "button" },
                on: { click: _vm.prevMonth }
              },
              [_c("i", { staticClass: "icon icon-arrow-left2" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-brand",
                attrs: { type: "button", disabled: _vm.isCurrentMonth },
                on: { click: _vm.currentMonth }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("trans")("general.current_month")) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-brand",
                attrs: { type: "button" },
                on: { click: _vm.nextMonth }
              },
              [_c("i", { staticClass: "icon icon-arrow-right2" })]
            )
          ]),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "heading-2 text-brand vue-closings__title" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("monthYearName")(_vm.display_date)) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn-action btn-action--add",
            on: { click: _vm.openModal }
          })
        ]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loader" }, [
            _c("h3", [_vm._v(_vm._s(_vm._f("trans")("general.loading")))])
          ])
        : _c(
            "div",
            [
              _vm.current_view === "calendar"
                ? [
                    _c("FullCalendar", {
                      ref: "fullCalendar",
                      attrs: {
                        defaultView: "dayGridMonth",
                        plugins: _vm.calendarPlugins,
                        events: _vm.events,
                        header: false,
                        locale: this.locale,
                        "first-day": 1,
                        "event-render": _vm.eventRendered,
                        "default-date": _vm.display_date
                      },
                      on: { eventClick: _vm.editEvent }
                    })
                  ]
                : [
                    _vm.days.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.days, function(day) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  " d-flex p-0 mb-4 box--shadowed overflow-hidden"
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "p-4 m-0 d-flex flex-column justify-content-center align-items-center heading-2 text-white day--header",
                                    class: [
                                      { "bg-danger": day.closed },
                                      { "bg-brand": !day.closed }
                                    ]
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("weekDay")(
                                            _vm._f("toDate")(day.date)
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("day")(
                                            _vm._f("toDate")(day.date)
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-4 pt-4 m-0 d-flex flex-column w-100 justify-content-center"
                                  },
                                  [
                                    day.closed
                                      ? _c(
                                          "h3",
                                          { staticClass: "text-center mb-4" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("trans")(
                                                  "general.closed"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(day.elements, function(
                                      element,
                                      index
                                    ) {
                                      return [
                                        element.menus.length <= 0
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge bg-brand text-white heading-3 mb-4",
                                                staticStyle: { opacity: "0.5" }
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(element.turn) + ":"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "badge bg-brand text-white heading-3 mb-4",
                                                attrs: { href: "" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editElement(
                                                      day.date,
                                                      index,
                                                      "turn"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(element.turn) + ":"
                                                  )
                                                ]),
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      element.menus.join(", ")
                                                    ) +
                                                    "\n                            "
                                                )
                                              ]
                                            )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
            ],
            2
          ),
      _vm._v(" "),
      _vm._t("legends", [
        _c(
          "div",
          {
            staticClass: "reservations_calendar__legends",
            attrs: { slot: "legends" },
            slot: "legends"
          },
          [
            _c("ul", { staticClass: "legends__list" }, [
              _c("li", { staticClass: "legend legend--today" }, [
                _c("span", { staticClass: "legend__label" }, [
                  _vm._v(_vm._s(_vm._f("trans")("general.today")))
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "legend legend--holiday" }, [
                _c("span", { staticClass: "legend__label" }, [
                  _vm._v(
                    _vm._s(_vm._f("trans")("general.sundays_and_holidays"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "legend legend--closed" }, [
                _c("span", { staticClass: "legend__label" }, [
                  _vm._v(_vm._s(_vm._f("trans")("general.closed")))
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "modals" } },
        [
          _vm.modal_open
            ? _c("modal-add-menu-calendar", {
                attrs: { menus: _vm.menus },
                on: { close: _vm.closeModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_edit_open
            ? _c("modal-edit-menu-calendar", {
                attrs: { data: _vm.edit_data },
                on: { close: _vm.closeModal }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
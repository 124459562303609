import dateFormat from "dateformat";
import Vue from 'vue';
import moment from 'moment';
import VueMoment from 'vue-moment';
require('moment/locale/eu');
require('moment/locale/es');

var Lang = require('lang.js');
import translations from "./vue-translations";

var lang = new Lang();
window.config = JSON.parse(document.getElementById('config').innerHTML);
lang.setLocale(config.locale);
lang.setMessages(translations);

moment.locale(config.locale);
Vue.use(VueMoment, { moment });

Vue.filter('trans', (...args) => {
    return lang.get(...args);
});
Vue.filter('to_snake_case', (str) => {
    let s = str.replace(/[^\w\s]/g, "");
    s = s.replace(/\s+/g, " ");
    return s.toLowerCase().split(' ').join('_');
});

Vue.filter('prepare_select', (elements, field) => {
    return _.map(elements, element => {
        return {
            label: element[field],
            id: element['id']
        };
    });
});
Vue.filter('prepare_option_select', (elements, field) => {
    return _.map(elements, element => {
        return {
            label: lang.get(element[field]),
            id: element['id']
        };
    });
});
Vue.filter('toDate', value => {
    return new Date(value);
});
Vue.filter('weekDay', value => {
    if (value) {
        return dateFormat(value, 'ddd');
    } else {
        return '';
    }
});
Vue.filter('day', value => {
    if (value) {
        return dateFormat(value, 'dd');
    } else {
        return '';
    }
});
Vue.filter('date', value => {
    if (value) {
        return dateFormat(value, 'dd mmm yyyy');
    } else {
        return '';
    }
});
Vue.filter('monthYear', value => {
    if (value) {
        return dateFormat(value, 'mmmm yyyy');
    } else {
        return '';
    }
});
Vue.filter('monthYearName', value => {
    if (value) {
        return moment(value).format('MMMM YYYY');
    } else {
        return '';
    }
});
Vue.filter('truncate', (text, length, suffix) => {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm._v(
      "\n    " +
        _vm._s(
          _vm._f("trans")("general.datatable_stats", {
            from: _vm.from ? _vm.from : 0,
            to: _vm.to ? _vm.to : 0,
            total: _vm.total
          })
        ) +
        "\n"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
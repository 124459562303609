<template>
    <transition name="fade-shrink-in" appear>
        <div class="modal show" style="padding-right: 17px; display: block;"
             @click.prevent="close(false)">
            <div class="modal-dialog modal-lg modal-dialog-centered" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="myLargeModalLabel">
                            <span>{{ data.date }}</span>
                            <span class="badge bg-brand text-white">{{ turn_name }}</span>
                        </h5>
                        <button type="button" class="close" @click.prevent="close(false)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-for="(element, index) in elements" class="box--shadowed"
                             v-bind:class="{'mt-4': index >= 1}">
                            <div class="d-flex justify-content-between">
                                <h3>{{ element.menu }}</h3>
                                <button class="btn-action btn-action--remove"
                                        @click="deleteElement(index)"></button>
                            </div>
                            <date-range-picker
                                ref="picker"
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                                v-model="elements[index].range"
                                :auto-apply="auto_apply"
                                :ranges="false"
                            >
                                <div slot="input" slot-scope="picker">
                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                </div>
                            </date-range-picker>
                        </div>
                        <div v-if="deletes.length > 0" class="box--shadowed bg-danger text-white"
                             v-bind:class="{'mt-4': elements.length > 0}">
                            <h3>{{ 'general.changes_deletes' | trans }}</h3>
                            <ul>
                                <li v-for="element in deletes">
                                    {{ 'general.element_deleted' | trans({
                                    menu: element.menu,
                                    from: element.range.startDate,
                                    to: element.range.endDate
                                    }) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close(false)">
                            {{ 'general.cancel' | trans }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-brand"
                            @click.prevent="save"
                            v-bind:disabled="save_disabled || save_loading"
                        >
                            <i v-if="save_loading" class="spinner icon-spinner"></i>
                            {{ 'general.save' | trans }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {
        components: {
            DateRangePicker
        },
        data() {
            return {
                url: '',
                turn_name: '',
                elements: [],
                deletes: [],
                save_disabled: false,
                save_loading: false,
                min_date: new Date(),
                auto_apply: true,
            };
        },
        props: ['data'],
        methods: {
            getMenus() {
                var params = this.data;
                axios.get(this.url, {params: params})
                    .then(response => {
                        this.elements = response.data.elements;
                        this.turn_name = response.data.turn;
                    });

            },
            close(reload) {
                this.$emit('close', reload);
            },
            deleteElement(index) {
                this.deletes.push(this.elements[index]);
                this.elements.splice(index, 1);
            },
            save() {
                this.save_loading = true;
                this.save_disabled = true;
                var params = {
                    update: this.elements,
                    delete: this.deletes,
                };
                axios.post(window.config.context_prefix+'/api/restaurant/menu-calendar/update', params)
                    .then(response => {
                        this.save_loading = false;
                        this.save_disabled = false;
                        this.$emit('close', true);
                    }).catch(error => {
                    this.save_loading = false;
                    this.save_disabled = false;
                    this.$emit('close', false);
                });
            }
        },
        mounted() {
            if (this.data.hasOwnProperty('turn')) {
                this.url = window.config.context_prefix+'/api/restaurant/menu-calendar/on-day';
            } else {
                this.url = window.config.context_prefix+'/api/restaurant/menu-calendar/on-range'
            }
            this.getMenus();
        }
    }
</script>

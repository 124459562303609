<template>
    <div class="vue_checkbox_filters">
        <ul>
            <li v-for="option in options">
                <div class="form-group form-check">
                    <input v-bind:name="name" type="checkbox" class="form-check-input"
                           v-bind:id="name + '_' + option.id"
                           @change.prevent="select(option.id)">
                    <label class="form-check-label" v-bind:for="name + '_' + option.id">{{ option.name }}</label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                options: [],
                selected: [],
            };
        },
        props: ['name'],
        methods: {
            getOptions() {
                axios.get(window.config.context_prefix+'/api/' + this.name + '?all=no')
                    .then(response => {
                        this.options = response.data.data;
                    })
            },
            select(key) {
                if (this.selected.includes(key)) {
                    var index = this.selected.indexOf(key);
                    this.selected.splice(index, 1);
                } else {
                    this.selected.push(key);
                }
                this.$emit('filter', this.name, this.selected);
            }
        },
        mounted() {
            this.getOptions();
        }
    }
</script>

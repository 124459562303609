<template>
    <div class="vue_booking_form">
        <h3 v-if="this.current_reservation !== undefined" class="col-12 heading-1 restaurant_booking__title">{{ 'general.edit_book' | trans }}</h3>
        <h3 v-else class="col-12 heading-1 restaurant_booking__title">{{ 'general.book' | trans }}</h3>
        <div v-if="reservation_success" class="col-12 reservation_success">
            <div class="box--shadowed text-brand">
                <h3 class="reservation_success__title">{{ 'general.booking_successfull' | trans }}</h3>
                <ul class="reservation_success__info">
                    <li class="reservation_success__data">
                        <label class="reservation_success__data_label">{{ 'general.restaurant_name' | trans }}:</label>
                        <span class="reservation_success__data_value">{{ restaurant.name }}</span>
                    </li>
                    <li class="reservation_success__data">
                        <label class="reservation_success__data_label">{{ 'general.booking_name' | trans }}:</label>
                        <span class="reservation_success__data_value">{{ reservation.user_name }}</span>
                    </li>
                    <li class="reservation_success__data">
                        <label class="reservation_success__data_label">{{ 'general.date' | trans }}:</label>
                        <span class="reservation_success__data_value">{{ reservation.reservation_date | date }}</span>
                    </li>
                    <li class="reservation_success__data">
                        <label class="reservation_success__data_label">{{ 'general.time' | trans }}:</label>
                        <span class="reservation_success__data_value">{{ reservation.reservation_time }}</span>
                    </li>
                    <li class="reservation_success__data">
                        <label class="reservation_success__data_label">{{ 'general.no_diners' | trans }}:</label>
                        <span class="reservation_success__data_value">{{ reservation.no_people }}</span>
                    </li>
                    <li class="reservation_success__link">
                        <a class="btn btn-brand" href="/cliente/reservas">{{ 'general.my_bookings' | trans }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            <div class="col-12 restaurant_booking__form">
                <div class="form-group form-group--name" v-bind:class="{'has-error' : errors.has('name')}">
                    <label for="name">{{'general.name' | trans }}</label>
                    <input v-model="reservation.user_name" v-validate="'required|max:255'" v-bind:data-vv-as="input.name[locale]" maxlength="255" type="text" class="form-control" id="name" name="name" disabled>
                    <small v-if="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                </div>
                <div class="form-group form-group--phone" v-bind:class="{'has-error' : errors.has('phone')}">
                    <label for="phone">{{'general.phone' | trans }}</label>
                    <input v-model="reservation.user_phone" v-validate="'required|required|numeric|min:9|max:9'" v-bind:data-vv-as="input.phone[locale]" maxlength="255" type="text" class="form-control" id="phone" name="phone" disabled>
                    <small v-if="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</small>
                </div>
                <div class="form-group form-group--no_people" v-bind:class="{'has-error' : errors.has('no_people')}">
                    <label for="no_people">{{'general.no_people' | trans }}</label>
                    <input v-model="reservation.no_people" v-validate="'required|numeric|min_value:1|max_value:30'" v-bind:data-vv-as="input.no_people[locale]" type="number" min="1" max="30" step="1" class="form-control" id="no_people" name="no_people">
                    <small v-if="errors.has('no_people')" class="text-danger">{{ errors.first('no_people') }}</small>
                </div>
                <datepicker
                    v-model="reservation_date"
                    :inline="true"
                    :disabled-dates="disabledDates"
                    :highlighted="highlightedDates"
                    :monday-first="true"
                    :language="lang[locale]"
                    name="reservation_date"
                    @changedMonth="getAvailability"
                    @selected="selectDate"
                    v-validate="'required'"
                    v-bind:data-vv-as="input.reservation_date[locale]"
                >
                    <label slot="beforeCalendarHeader">
                        {{'general.reservation_date' | trans}}
                    </label>
                </datepicker>
                <small v-if="errors.has('reservation_date')" class="text-danger">{{ errors.first('reservation_date') }}</small>
                <div class="form-group form-group--reservation_time" v-bind:class="{'has-error' : errors.has('reservation_time')}">
                    <label for="reservation_time">{{'general.reservation_time' | trans }}</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <i class="icon-clock"></i>
                            </div>
                        </div>
                        <v-select
                            v-model="reservation.reservation_time"
                            :options="availableTimes"
                            id="reservation_time"
                            name="reservation_time"
                            v-bind:disabled="!reservation_date"
                            v-validate="'required'"
                            v-bind:data-vv-as="input.hour[locale]"
                        ></v-select>
                    </div>
                    <small v-if="errors.has('reservation_time')" class="text-danger">{{ errors.first('reservation_time') }}</small>
                </div>
            </div>
            <div class="col-12 restaurant_booking__submit">
                <button class="btn btn-brand btn-lg d-block mx-auto mt-4" @click.prevent="book"
                        v-bind:disabled="loading">
                    <i v-if="loading" class="spinner icon-spinner"></i>
                    {{ 'general.book' | trans }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';

    export default {
        components: {
            Datepicker
        },
        data() {
            return {
                loading: false,
                reservation_success: false,
                reservation: {
                    id: null,
                    restaurant_id: this.restaurant.id,
                    user_id: this.user.id,
                    user_name: this.user.name + " " + this.user.last_name,
                    user_phone: this.user.phone,
                    no_people: null,
                    reservation_time: '',
                    reservation_date: null,
                },
                reservation_date: null,
                disabledDates: {
                    to: new Date(),
                    dates: [],
                },
                highlightedDates: {
                    dates: [],
                },
                availableTimes: [],
                lang: {
                    es: es,
                },
                input: {
                    name: {
                        es: 'nombre',
                        eu: 'izena',
                    },
                    phone: {
                        es: 'teléfono',
                        eu: 'telefonoa',
                    },
                    no_people: {
                        es: 'nº comensales',
                        eu: 'mahaikide kopurua',
                    },
                    hour: {
                        es: 'hora reserva',
                        eu: 'erreserba ordua',
                    },
                    reservation_date: {
                        es: 'día de reserva',
                        eu: 'erreserba eguna',
                    },
                },
            };
        },
        props: ['restaurant', 'user', 'locale' , 'current_reservation'],
        methods: {
            getAvailability(date) {
                var params = {
                    date: date
                };
                axios.get(window.config.context_prefix+'/api/restaurants/' + this.restaurant.id + '/calendar', {params: params})
                    .then(response => {
                        this.disabledDates.days = response.data.days;
                        this.disabledDates.dates = [];
                        var that = this;
                        response.data.closing.forEach(date => {
                            that.disabledDates.dates.push(new Date(date));
                        });
                        response.data.bank_holidays.forEach(date => {
                            that.highlightedDates.dates.push(new Date(date));
                        });
                    });
            },
            selectDate(date) {
                this.reservation.reservation_time = "";
                this.getTurns(date);
            },
            getTurns(date) {
                var params = {
                    date: date
                };
                axios.get(window.config.context_prefix+'/api/restaurants/' + this.restaurant.id + '/available-times', {params: params})
                    .then(response => {
                        this.availableTimes = response.data.times;
                    });
            },
            book() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.loading = true;
                        this.reservation.reservation_date = this.reservation_date;
                        axios.post(window.config.context_prefix+'/api/client/reservations/create', this.reservation)
                            .then(response => {
                                this.loading = false;
                                this.reservation_success = true;
                            }).catch(error => {
                            this.loading = false;
                            console.log('Ooops');
                        })
                    }
                    if(!result){
                        console.log('Oops! Not validate');
                    }
                });
            }
        },
        mounted() {
            if(this.current_reservation !== undefined){
                var date = new Date(this.current_reservation.date_datepicker);
                this.reservation.no_people = this.current_reservation.no_people;
                this.reservation.reservation_time = this.current_reservation.reservation_time;
                this.reservation.reservation_date = this.current_reservation.date;
                this.reservation_date = this.current_reservation.date_datepicker;
                this.reservation.id = this.current_reservation.id;
                this.getAvailability(date);
                this.getTurns(date);
            }else{
                this.getAvailability(new Date());
            }
        }
    }
</script>

<template>
    <div class="vue-turns">
        <div class="mb-4 box--shadowed vue-turns__info">
            <h3 class="d-flex justify-content-between align-items-center heading-2 text-brand">
                <span>{{ 'general.info' | trans }}</span>
                <i class="icon-info"></i>
            </h3>
            <p>
                {{ 'general.tag_list_info' | trans}}
            </p>
        </div>


        <div class="box--shadowed mb-4">
            <div class="d-flex justify-content-between align-items-center vue-turns__header">
                <h4 class="heading-2 text-brand vue-turns__title">
                    {{ 'general.add_caracteristics' | trans }}
                </h4>
                <button class="btn-action btn-action--add" @click="addCategoriesMenu()" v-bind:disabled="loading"></button>
            </div>
            <div class="row mx-0 box--shadowed vue-turns__turn" v-for="(type, index) in categories">
                <div class="col-12 col-md-6 vue-turns__turn__name">

                    <dummy-multilang :langs="langs" field_name="category_name">
                        <template v-for="lang in langs" :slot="lang">
                            <div v-bind:class="{'has-error' : errors.has('tag_name_' + index + '_' + lang)}">
                                    <input
                                        v-model="categories[index]['name'][lang]"
                                        type="text"
                                        :name="'tag_name_' + index  + '_' + lang"
                                        class="form-control"
                                        v-validate="'required|min:3|max:255'"
                                        maxlength="255"
                                        v-bind:data-vv-as="input['tag_name_' + lang][locale]"
                                    >
                            </div>
                        </template>
                    </dummy-multilang>
                    <template v-for="lang in langs">
                        <p class="m-0">
                            <small v-if="errors.has('tag_name_' + index + '_' + lang)" class="text-danger">
                                {{ errors.first('tag_name_' + index + '_' + lang) }}
                            </small>
                        </p>
                    </template>

                </div>
                <div class="col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions mt-4">
                    <button class="btn-action btn-action--remove" @click="removeCategories(index)" v-bind:disabled="loading"></button>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4 vue-turns__submit">
            <button class="btn btn-brand-outline" type="button" @click="save" v-bind:disabled="loading">
                <i v-if="loading" class="icon icon-spinner spinner"></i>
                {{ 'general.save' | trans }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                categories: {},
                loading: false,
                input: {
                    tag_name_es: {
                        es: 'nombre de característica es',
                        eu: 'ezaugarriaren izena es',
                    },
                    tag_name_eu: {
                        es: 'nombre de característica eu',
                        eu: 'ezaugarriaren izena eu',
                    },
                },
            };
        },
        props: ['locale', 'tags', 'langs'],
        methods: {
            getCategories(){
                this.categories = this.tags;
            },
            addCategoriesMenu() {
                this.categories.push({
                    id : null,
                    name : {
                        es : null,
                        eu : null,
                    },
                });
            },
            removeCategories(index) {
                this.categories.splice(index, 1);
            },
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.loading = true;
                        var params = { 'tags' : this.categories };
                        axios.post(window.config.context_prefix+'/api/admin/save-tags', params)
                            .then(response => {
                                this.categories = response.data.tags;
                                this.loading = false;
                            }).catch(error => {
                            this.loading = false;
                            console.log('Ooops...')
                        });
                    }
                    if(!result){
                        console.log('Not validate!');
                    }
                });
            },
        },
        beforeMount() {
            this.getCategories();
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("section", { staticClass: "row restaurant_menus mt-5" }, [
        _c("h3", { staticClass: "col-12 py-0 restaurant_menus__header" }, [
          _c("span", { staticClass: "restaurant_menus__title" }, [
            _vm._v(_vm._s(_vm._f("trans")("general.menus")))
          ])
        ]),
        _vm._v(" "),
        _vm.menus.length > 0
          ? _c(
              "div",
              {
                staticClass: "col-12 carousel slide restaurant_menus__carousel",
                attrs: {
                  id: "restaurant_menus__carousel",
                  "data-ride": "carousel"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "carousel-inner" },
                  _vm._l(_vm.menus, function(menu, index) {
                    return _c(
                      "article",
                      {
                        staticClass: "carousel-item menu",
                        class: { active: index < 1 }
                      },
                      [
                        _c("header", { staticClass: "menu__header" }, [
                          _c(
                            "div",
                            { staticClass: "menu__header__actions" },
                            [
                              _c(
                                "v-popover",
                                { attrs: { offset: "16" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "tooltip-target b3 menu__header__action_link"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("trans")("general.share")
                                        ) + " "
                                      ),
                                      _c("i", { staticClass: "icon-share" })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("template", { slot: "popover" }, [
                                    _c("p", { staticClass: "mb-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("trans")("general.share_url")
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "p-2 mb-2",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.share_url }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard",
                                            value: _vm.share_url,
                                            expression: "share_url"
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.onCopy,
                                            expression: "onCopy",
                                            arg: "success"
                                          }
                                        ],
                                        staticClass: "btn btn-primary",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm._f("trans")(_vm.text))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "close-popover",
                                            rawName: "v-close-popover"
                                          }
                                        ],
                                        staticClass: "btn btn-grey-outline"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("trans")("general.close")
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "text-brand menu__header__title" },
                            [
                              _vm._v(
                                _vm._s(menu.name) +
                                  ": " +
                                  _vm._s(_vm.dateToShow)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "menu__header__subtitle",
                            domProps: { innerHTML: _vm._s(menu.observations) }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "menu__content" },
                          _vm._l(menu.elements, function(elements, key) {
                            return _c("div", [
                              _c("p", [
                                _c("strong", [_vm._v(_vm._s(elements.name))])
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                _vm._l(elements.dishes, function(dish) {
                                  return _c("li", [
                                    _c("span", { staticClass: "dish__name" }, [
                                      _vm._v(_vm._s(dish.name))
                                    ]),
                                    _vm._v(" "),
                                    dish.price !== null
                                      ? _c(
                                          "span",
                                          { staticClass: "dish__price" },
                                          [
                                            _vm._v(
                                              "(" + _vm._s(dish.price) + "€)"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    dish.tags.length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "dish__tags" },
                                          _vm._l(dish.tags, function(tag) {
                                            return _c(
                                              "span",
                                              {
                                                staticClass: "dish_tag",
                                                class: _vm.tagClass(tag.id)
                                              },
                                              [_vm._v(_vm._s(tag.label))]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ])
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("footer", { staticClass: "menu__footer" }, [
                          menu.menu_type_id === 2
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("trans")("general.menu_price")
                                  ) +
                                    ": " +
                                    _vm._s(menu.price) +
                                    " €"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          menu.menu_type_id === 2
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("trans")("general.menu_price_text")
                                  )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          menu.menu_type_id === 2 && menu.half_price !== null
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("trans")("general.half_menu_price")
                                  ) +
                                    " : " +
                                    _vm._s(menu.half_price) +
                                    " €"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "carousel-control-prev",
                    attrs: {
                      href: "#restaurant_menus__carousel",
                      role: "button",
                      "data-slide": "prev"
                    }
                  },
                  [
                    _c("span", {
                      staticClass:
                        "restaurant_menus__carousel__control_icon icon-ctrl-left",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm._f("trans")("general.previous")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "carousel-control-next",
                    attrs: {
                      href: "#restaurant_menus__carousel",
                      role: "button",
                      "data-slide": "next"
                    }
                  },
                  [
                    _c("span", {
                      staticClass:
                        "restaurant_menus__carousel__control_icon icon-ctrl-right",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm._f("trans")("general.next")))
                    ])
                  ]
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass: "col-12 carousel slide restaurant_menus__carousel",
                attrs: {
                  id: "restaurant_menus__carousel",
                  "data-ride": "carousel"
                }
              },
              [
                _c("h3", [
                  _vm._v(_vm._s(_vm._f("trans")("general.no_menu_data")))
                ])
              ]
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box--shadowed" },
    [
      _c(
        "div",
        {
          staticClass:
            "mb-4 d-flex justify-content-between align-items-center vue-closings__header"
        },
        [
          _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-brand",
                attrs: { type: "button" },
                on: { click: _vm.prevMonth }
              },
              [_c("i", { staticClass: "icon icon-arrow-left2" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-brand",
                attrs: { type: "button", disabled: this.disabled },
                on: { click: _vm.currentMonth }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("trans")("general.current_month")) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-brand",
                attrs: { type: "button" },
                on: { click: _vm.nextMonth }
              },
              [_c("i", { staticClass: "icon icon-arrow-right2" })]
            )
          ]),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "heading-2 text-brand vue-closings__title" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("monthYearName")(_vm.display_date)) +
                  "\n        "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loader" }, [
            _c("h3", [_vm._v(_vm._s(_vm._f("trans")("general.loading")))])
          ])
        : _c(
            "div",
            [
              _c("FullCalendar", {
                ref: "fullCalendar",
                attrs: {
                  defaultView: "dayGridMonth",
                  plugins: _vm.calendarPlugins,
                  events: _vm.events,
                  "first-day": 1,
                  header: false,
                  "event-render": _vm.eventRendered,
                  "default-date": _vm.display_date,
                  locale: this.locale
                },
                on: { eventClick: _vm.editEvent }
              }),
              _vm._v(" "),
              _vm._t("legends", [
                _c(
                  "div",
                  {
                    staticClass: "reservations_calendar__legends",
                    attrs: { slot: "legends" },
                    slot: "legends"
                  },
                  [
                    _c("ul", { staticClass: "legends__list" }, [
                      _c("li", { staticClass: "legend legend--today" }, [
                        _c("span", { staticClass: "legend__label" }, [
                          _vm._v(_vm._s(_vm._f("trans")("general.today")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "legend legend--holiday" }, [
                        _c("span", { staticClass: "legend__label" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")("general.sundays_and_holidays")
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "legend legend--closed" }, [
                        _c("span", { staticClass: "legend__label" }, [
                          _vm._v(_vm._s(_vm._f("trans")("general.closed")))
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "modals" } },
        [
          _vm.modal_open
            ? _c("reservations-datatable-modal", {
                attrs: {
                  date: _vm.selected_date,
                  turn: _vm.selected_turn,
                  display_date: _vm._f("date")(_vm.selected_date.startDate),
                  display_turn: _vm.selected_turn_name
                },
                on: { close: _vm.closeModal }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
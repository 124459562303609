<template>
    <div class="vue-restaurant-update">
        <form class="box--shadowed">
            <div class="form-group row">
                <div class="col-12" v-bind:class="{'has-error' : errors.has('name')}">
                    <label for="name" class="col-form-label">{{ 'general.name' | trans }}<span class="required"> *</span></label>
                    <input v-validate="'required|min:3|max:255'" v-bind:data-vv-as="input.name[locale]" v-model="data_restaurant.name" maxlength="255" id="name" type="text" class="form-control" name="name" required autocomplete="name" autofocus>
                    <small v-if="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                </div>
            </div>

            <div class="row">
                <div class="col-12 form-group">

                    <dummy-multilang :langs="langs" field_name="description" required="true">
                        <template v-for="lang in langs" :slot="lang">
                            <div v-bind:class="{'has-error' : errors.has('description_' + lang)}">
                                <VueTrix
                                    v-model="data_restaurant['description'][lang]"
                                    type="text"
                                    :name="'description_' + lang"
                                    v-validate="'required|min:3|max:2000'"
                                    maxlength="2000"
                                    v-bind:data-vv-as="input['description_' + lang][locale]"
                                ></VueTrix>
                            </div>
                        </template>
                    </dummy-multilang>
                    <template v-for="lang in langs">
                        <p class="m-0">
                            <small v-if="errors.has('description_' + lang)" class="text-danger">
                                {{ errors.first('description_' + lang) }}
                            </small>
                        </p>
                    </template>

                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 col-md-6" v-bind:class="{'has-error' : errors.has('email')}">
                    <label for="email" class="col-form-label">{{ 'general.email' | trans }}<span class="required"> *</span></label>
                    <input v-validate="'required|email|min:3|max:255'" v-bind:data-vv-as="input.email[locale]" id="email" v-model="data_restaurant.email" type="email" maxlength="255" class="form-control" name="email" required autocomplete="email" autofocus>
                    <small v-if="errors.has('email')" class="text-danger">{{ errors.first('email') }}</small>
                </div>
                <div class="col-12 col-md-6" v-bind:class="{'has-error' : errors.has('phone')}">
                    <label for="phone" class="col-form-label">{{ 'general.phone' | trans }}<span class="required"> *</span></label>
                    <input v-validate="'required|numeric|min:9|max:9'" v-bind:data-vv-as="input.phone[locale]" v-model="data_restaurant.phone" id="phone" type="number" maxlength="9" class="form-control" name="phone" required autocomplete="phone" autofocus>
                    <small v-if="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12">
                    <dummy-multilang :langs="langs" field_name="web" required="true">
                        <template v-for="lang in langs" :slot="lang">
                            <div v-bind:class="{'has-error' : errors.has('web_' + lang)}">
                                <input
                                    v-model="data_restaurant['web'][lang]"
                                    type="text"
                                    :name="'web_' + lang"
                                    class="form-control"
                                    v-validate="'required|url|min:3|max:255'"
                                    maxlength="255"
                                    v-bind:data-vv-as="input['web_' + lang][locale]"
                                >
                            </div>
                        </template>
                    </dummy-multilang>
                    <template v-for="lang in langs">
                        <p class="m-0">
                            <small v-if="errors.has('web_' + lang)" class="text-danger">
                                {{ errors.first('web_' + lang) }}
                            </small>
                        </p>
                    </template>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12">
                    <dummy-multilang :langs="langs" field_name="schedule" required="true">
                        <template v-for="lang in langs" :slot="lang">
                            <div v-bind:class="{'has-error' : errors.has('schedule_' + lang)}">
                                <VueTrix
                                    v-model="data_restaurant['schedule'][lang]"
                                    type="text"
                                    :name="'schedule_' + lang"
                                    v-validate="'required|min:3|max:2000'"
                                    maxlength="2000"
                                    v-bind:data-vv-as="input['schedule_' + lang][locale]"
                                ></VueTrix>
                            </div>
                        </template>
                    </dummy-multilang>
                    <template v-for="lang in langs">
                        <p class="m-0">
                            <small v-if="errors.has('schedule_' + lang)" class="text-danger">
                                {{ errors.first('schedule_' + lang) }}
                            </small>
                        </p>
                    </template>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12">
                    <dummy-multilang :langs="langs" field_name="address" required="true">
                        <template v-for="lang in langs" :slot="lang">
                            <div v-bind:class="{'has-error' : errors.has('address_' + lang)}">
                                <VueTrix
                                    v-model="data_restaurant['address'][lang]"
                                    type="text"
                                    :name="'address_' + lang"
                                    v-validate="'required|min:3|max:2000'"
                                    maxlength="2000"
                                    v-bind:data-vv-as="input['address_' + lang][locale]"
                                ></VueTrix>
                            </div>
                        </template>
                    </dummy-multilang>
                    <template v-for="lang in langs">
                        <p class="m-0">
                            <small v-if="errors.has('address_' + lang)" class="text-danger">
                                {{ errors.first('address_' + lang) }}
                            </small>
                        </p>
                    </template>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 col-md-6" v-bind:class="{'has-error' : errors.has('postcode')}">
                    <label for="postcode" class="col-form-label">{{ 'general.cp' | trans }}<span class="required"> *</span></label>
                    <input v-validate="'required|numeric|min:5|max:5'" v-bind:data-vv-as="input.postcode[locale]" v-model="data_restaurant.postcode" maxlength="5" id="postcode" type="number" class="form-control" name="postcode" required autocomplete="postcode" autofocus>
                    <small v-if="errors.has('postcode')" class="text-danger">{{ errors.first('postcode') }}</small>
                </div>

                <div class="col-12 col-md-6" v-bind:class="{'has-error' : errors.has('zone_id')}">
                    <label for="zone_id" class="col-form-label">{{ 'general.zone' | trans }}<span class="required"> *</span></label>
                    <v-select v-validate="'required'" v-bind:data-vv-as="input.zone[locale]" v-model="data_restaurant.zone_id" :options="zones | prepare_select('name')" :reduce="zone => zone.id" id="zone_id" name="zone_id"></v-select>
                    <small v-if="errors.has('zone_id')" class="text-danger">{{ errors.first('zone_id') }}</small>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 col-md-6" v-bind:class="{'has-error' : errors.has('food_type_id')}">
                    <label for="food_type_id" class="col-form-label">{{ 'general.food_type' | trans }}<span class="required"> *</span></label>
                    <v-select v-validate="'required'" v-bind:data-vv-as="input.food_type[locale]" v-model="data_restaurant.food_type_id" :options="food_types | prepare_select('name')" :reduce="food_type => food_type.id" id="food_type_id" name="food_type_id"></v-select>
                    <small v-if="errors.has('food_type_id')" class="text-danger">{{ errors.first('food_type_id') }}</small>
                </div>
                <div class="col-12 col-md-6" v-bind:class="{'has-error' : errors.has('price_range_id')}">
                    <label for="price_range_id" class="col-form-label">{{ 'general.price_range' | trans }}<span class="required"> *</span></label>
                    <v-select v-validate="'required'" v-bind:data-vv-as="input.prices[locale]" v-model="data_restaurant.price_range_id" :options="price_ranges | prepare_select('name')" :reduce="price_range => price_range.id" id="price_range_id" name="price_range_id"></v-select>
                    <small v-if="errors.has('price_range_id')" class="text-danger">{{ errors.first('price_range_id') }}</small>
                </div>
            </div>

            <div class="form-group row mt-4">
                <div class="form-check checkbox">
                    <input ref="discount" v-model="data_restaurant.discount" class="form-check-input" name="discount_menu" id="discount" type="checkbox" value="">
                    <label class="form-check-label" for="discount" >{{ 'general.menu_discount_info' | trans }}</label>
                </div>
            </div>

        </form>
        <div class="d-flex justify-content-end mt-4">
            <button class="btn btn-brand-outline" type="button" v-bind:disabled="loading" @click.prevent="saveMenu">
                <i v-if="loading" class="icon icon-spinner spinner"></i>
                {{ 'general.save' | trans }}
            </button>
        </div>
    </div>
</template>

<script>

    import VueTrix from "vue-trix";

    export default {
        components: {VueTrix},
        data() {
            return {
                loading: false,
                zones: [],
                price_ranges: [],
                food_types: [],
                data_restaurant: this.restaurant,
                input: {
                    name: {
                        es: 'nombre',
                        eu: 'izena',
                    },
                    description_es: {
                        es: 'descripción es',
                        eu: 'deskribapena es',
                    },
                    description: {
                        es: 'descripción',
                        eu: 'deskribapena',
                    },
                    description_eu: {
                        es: 'descripción eu',
                        eu: 'deskribapena eu',
                    },
                    email: {
                        es: 'correo electrónico',
                        eu: 'helbide elektronikoa',
                    },
                    phone: {
                        es: 'teléfono',
                        eu: 'telefonoa',
                    },
                    postcode: {
                        es: 'código postal',
                        eu: 'posta kodea',
                    },
                    web: {
                        es: 'página web',
                        eu: 'página web',
                    },
                    web_es: {
                        es: 'página web es',
                        eu: 'webgunea es',
                    },
                    web_eu: {
                        es: 'página web eu',
                        eu: 'webgunea eu',
                    },
                    schedule_es: {
                        es: 'Horario es',
                        eu: 'Ordutegia es',
                    },
                    schedule: {
                        es: 'Horario',
                        eu: 'Ordutegia',
                    },
                    schedule_eu: {
                        es: 'Horario eu',
                        eu: 'Ordutegia eu',
                    },
                    address: {
                        es: 'dirección',
                        eu: 'helbidea',
                    },
                    address_es: {
                        es: 'dirección es',
                        eu: 'helbidea es',
                    },
                    address_eu: {
                        es: 'dirección eu',
                        eu: 'helbidea eu',
                    },
                    zone: {
                        es: 'zona',
                        eu: 'eremua',
                    },
                    food_type: {
                        es: 'tipo de comida',
                        eu: 'janari mota',
                    },
                    prices: {
                        es: 'gama de precios',
                        eu: 'prezioen gama',
                    },
                },
            };
        },
        props: [ 'restaurant', 'langs', 'locale'],
        methods: {
            initializeTranslations() {
                this.data_restaurant['schedule'] = {es: '', eu: ''};
                this.data_restaurant['address'] = {es: '', eu: ''};
                this.data_restaurant['description'] = {es: '', eu: ''};
                this.data_restaurant['web'] = {es: '', eu: ''};
                _.map(this.restaurant.translations, trans => {
                    this.data_restaurant['schedule'][trans.locale] = trans.schedule;
                    this.data_restaurant['address'][trans.locale] = trans.address;
                    this.data_restaurant['description'][trans.locale] = trans.description;
                    this.data_restaurant['web'][trans.locale] = trans.web;
                });
            },
            getZones() {
                axios.get(window.config.context_prefix+"/api/get-zones")
                    .then(response => {
                        this.zones = response.data.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
            },
            getFoodTypes() {
                axios.get(window.config.context_prefix+"/api/food_types")
                    .then(response => {
                        this.food_types = response.data.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
            },
            getPriceRanges() {
                axios.get(window.config.context_prefix+"/api/price_ranges")
                    .then(response => {
                        this.price_ranges = response.data.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
            },
            saveMenu(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        var params = {'restaurant': this.data_restaurant};
                        this.loading = true;
                        axios.post(window.config.context_prefix+"/api/restaurant/save-general-data", params)
                            .then(response => {
                                this.loading = false;
                            }).catch(error => {
                            this.loading = false;
                            console.log('Ooops...');
                        });
                    }
                    if(!result){
                        console.log('Oops! Not validate');
                        this.loading = false;
                    }
                });
            },
        },
        beforeMount() {
            this.initializeTranslations();
            this.getZones();
            this.getFoodTypes();
            this.getPriceRanges();
        },
        mounted() {
            $('.trix-button-group--file-tools').hide();
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-closings" }, [
    _c("div", { staticClass: "mb-4 box--shadowed vue-closings__info" }, [
      _c(
        "h3",
        {
          staticClass:
            "d-flex justify-content-between align-items-center heading-2 text-brand"
        },
        [
          _c("span", [_vm._v(_vm._s(_vm._f("trans")("general.info")))]),
          _vm._v(" "),
          _c("i", { staticClass: "icon-info" })
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("trans")("general.closing_dates_info")) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "box--shadowed vue-closings__wrapper" }, [
      _vm.loading_closing || _vm.loading_calendar
        ? _c("div", { staticClass: "loader" }, [
            _c("h3", [_vm._v(_vm._s(_vm._f("trans")("general.loading")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            {
              staticClass:
                "col-12 mb-4 d-flex justify-content-between align-items-center vue-closings__header"
            },
            [
              _c(
                "h3",
                { staticClass: "heading-2 text-brand vue-closings__title" },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("trans")("general.add_closing")) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-action btn-action--add",
                attrs: { disabled: !_vm.loading },
                on: { click: _vm.openModalEmpty }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-4 vue-closings__calendar" },
            [
              _c("datepicker", {
                attrs: {
                  inline: true,
                  "disabled-dates": _vm.disabledDates,
                  highlighted: _vm.highlightedDates,
                  "monday-first": true,
                  language: _vm.lang[_vm.locale]
                },
                on: {
                  selected: _vm.calendarClick,
                  changedMonth: _vm.getCalendarDates
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-8 vue-closings__lists" }, [
            _c("div", { staticClass: "vue-closings__lists__header" }, [
              _c("h4", { staticClass: "heading-3 mb-2" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm._f("trans")("general.closing")) +
                    "\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "vue-closings__list_wrapper" }, [
              _c("h5", { staticClass: "heading-4 vue-closings__list__title" }, [
                _vm._v(_vm._s(_vm._f("trans")("general.periodic_close")))
              ]),
              _vm._v(" "),
              _vm.closing.days.length > 0
                ? _c(
                    "ul",
                    { attrs: { clas: "vue-closings__list" } },
                    _vm._l(_vm.closing.days, function(day, index) {
                      return _c(
                        "li",
                        { staticClass: "vue-closings__closing" },
                        [
                          _c(
                            "span",
                            { staticClass: "vue-closings__closing__date" },
                            [
                              _vm._v(
                                _vm._s(_vm._f("getDayName")(day.data, _vm.days))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "vue-closings__closing__turns" },
                            _vm._l(day.turns_text, function(turn) {
                              return _c(
                                "span",
                                { staticClass: "vue-closings__closing__turn" },
                                [_vm._v(_vm._s(turn))]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vue-closings__closing__actions" },
                            [
                              _c("button", {
                                staticClass: "btn-action btn-action--edit",
                                attrs: {
                                  title: _vm._f("trans")("general.edit")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.editClosing(day, 1)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("button", {
                                staticClass: "btn-action btn-action--remove",
                                attrs: {
                                  title: _vm._f("trans")("general.delete")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteClosing(day, 1)
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "vue-closings__list_wrapper" }, [
              _c("h5", { staticClass: "heading-4 vue-closings__list__title" }, [
                _vm._v(_vm._s(_vm._f("trans")("general.range_close")))
              ]),
              _vm._v(" "),
              _vm.closing.ranges.length > 0
                ? _c(
                    "ul",
                    { attrs: { clas: "vue-closings__list" } },
                    _vm._l(_vm.closing.ranges, function(range, index) {
                      return _c(
                        "li",
                        { staticClass: "vue-closings__closing" },
                        [
                          _c(
                            "span",
                            { staticClass: "vue-closings__closing__date" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("date")(range.data.from)))
                              ]),
                              _vm._v(" - "),
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("date")(range.data.to)))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(range.turns_text, function(turn) {
                            return _c(
                              "span",
                              { staticClass: "vue-closings__closing__turns" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vue-closings__closing__turn"
                                  },
                                  [_vm._v(_vm._s(turn))]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vue-closings__closing__actions" },
                            [
                              _c("button", {
                                staticClass: "btn-action btn-action--edit",
                                attrs: {
                                  title: _vm._f("trans")("general.edit")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.editClosing(range, 2)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("button", {
                                staticClass: "btn-action btn-action--remove",
                                attrs: {
                                  title: _vm._f("trans")("general.delete")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteClosing(range, 2)
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "vue-closings__list_wrapper" }, [
              _c("h5", { staticClass: "heading-4 vue-closings__list__title" }, [
                _vm._v(_vm._s(_vm._f("trans")("general.punctual_close")))
              ]),
              _vm._v(" "),
              _vm.closing.dates.length > 0
                ? _c(
                    "ul",
                    { attrs: { clas: "vue-closings__list" } },
                    _vm._l(_vm.closing.dates, function(date, index) {
                      return _c(
                        "li",
                        { staticClass: "vue-closings__closing" },
                        [
                          _c(
                            "span",
                            { staticClass: "vue-closings__closing__date" },
                            [_vm._v(_vm._s(_vm._f("date")(date.data)))]
                          ),
                          _vm._v(" "),
                          _vm._l(date.turns_text, function(turn) {
                            return _c(
                              "span",
                              { staticClass: "vue-closings__closing__turns" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vue-closings__closing__turn"
                                  },
                                  [_vm._v(_vm._s(turn))]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vue-closings__closing__actions" },
                            [
                              _c("button", {
                                staticClass: "btn-action btn-action--edit",
                                attrs: {
                                  title: _vm._f("trans")("general.edit")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.editClosing(date, 3)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("button", {
                                staticClass: "btn-action btn-action--remove",
                                attrs: {
                                  title: _vm._f("trans")("general.delete")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteClosing(date, 3)
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "portal",
            { attrs: { to: "modals" } },
            [
              _vm.modal_open
                ? _c("modal-add-closing-element", {
                    attrs: {
                      days: _vm.days,
                      id_selected: _vm.id_selected,
                      type_selected: _vm.type_selected,
                      date_selection: _vm.date_selection,
                      turns_selection: _vm.turns_selection,
                      restaurant_id: _vm.restaurant_id,
                      locale: _vm.locale
                    },
                    on: { close: _vm.closeModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.delete_modal_open
                ? _c("confirmation-modal", {
                    attrs: {
                      url: _vm.delete_modal_url,
                      title: _vm._f("trans")("general.confirm_delete"),
                      text: _vm._f("trans")("general.confirm_delete_p")
                    },
                    on: { close: _vm.closeDeleteModal }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
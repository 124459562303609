var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue_booking_form" }, [
    this.current_reservation !== undefined
      ? _c(
          "h3",
          { staticClass: "col-12 heading-1 restaurant_booking__title" },
          [_vm._v(_vm._s(_vm._f("trans")("general.edit_book")))]
        )
      : _c(
          "h3",
          { staticClass: "col-12 heading-1 restaurant_booking__title" },
          [_vm._v(_vm._s(_vm._f("trans")("general.book")))]
        ),
    _vm._v(" "),
    _vm.reservation_success
      ? _c("div", { staticClass: "col-12 reservation_success" }, [
          _c("div", { staticClass: "box--shadowed text-brand" }, [
            _c("h3", { staticClass: "reservation_success__title" }, [
              _vm._v(_vm._s(_vm._f("trans")("general.booking_successfull")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "reservation_success__info" }, [
              _c("li", { staticClass: "reservation_success__data" }, [
                _c(
                  "label",
                  { staticClass: "reservation_success__data_label" },
                  [
                    _vm._v(
                      _vm._s(_vm._f("trans")("general.restaurant_name")) + ":"
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "reservation_success__data_value" }, [
                  _vm._v(_vm._s(_vm.restaurant.name))
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "reservation_success__data" }, [
                _c(
                  "label",
                  { staticClass: "reservation_success__data_label" },
                  [
                    _vm._v(
                      _vm._s(_vm._f("trans")("general.booking_name")) + ":"
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "reservation_success__data_value" }, [
                  _vm._v(_vm._s(_vm.reservation.user_name))
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "reservation_success__data" }, [
                _c(
                  "label",
                  { staticClass: "reservation_success__data_label" },
                  [_vm._v(_vm._s(_vm._f("trans")("general.date")) + ":")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "reservation_success__data_value" }, [
                  _vm._v(
                    _vm._s(_vm._f("date")(_vm.reservation.reservation_date))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "reservation_success__data" }, [
                _c(
                  "label",
                  { staticClass: "reservation_success__data_label" },
                  [_vm._v(_vm._s(_vm._f("trans")("general.time")) + ":")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "reservation_success__data_value" }, [
                  _vm._v(_vm._s(_vm.reservation.reservation_time))
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "reservation_success__data" }, [
                _c(
                  "label",
                  { staticClass: "reservation_success__data_label" },
                  [_vm._v(_vm._s(_vm._f("trans")("general.no_diners")) + ":")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "reservation_success__data_value" }, [
                  _vm._v(_vm._s(_vm.reservation.no_people))
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "reservation_success__link" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-brand",
                    attrs: { href: "/cliente/reservas" }
                  },
                  [_vm._v(_vm._s(_vm._f("trans")("general.my_bookings")))]
                )
              ])
            ])
          ])
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "col-12 restaurant_booking__form" },
            [
              _c(
                "div",
                {
                  staticClass: "form-group form-group--name",
                  class: { "has-error": _vm.errors.has("name") }
                },
                [
                  _c("label", { attrs: { for: "name" } }, [
                    _vm._v(_vm._s(_vm._f("trans")("general.name")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reservation.user_name,
                        expression: "reservation.user_name"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:255",
                        expression: "'required|max:255'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      "data-vv-as": _vm.input.name[_vm.locale],
                      maxlength: "255",
                      type: "text",
                      id: "name",
                      name: "name",
                      disabled: ""
                    },
                    domProps: { value: _vm.reservation.user_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.reservation,
                          "user_name",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("name")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("name")))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group form-group--phone",
                  class: { "has-error": _vm.errors.has("phone") }
                },
                [
                  _c("label", { attrs: { for: "phone" } }, [
                    _vm._v(_vm._s(_vm._f("trans")("general.phone")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reservation.user_phone,
                        expression: "reservation.user_phone"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|required|numeric|min:9|max:9",
                        expression: "'required|required|numeric|min:9|max:9'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      "data-vv-as": _vm.input.phone[_vm.locale],
                      maxlength: "255",
                      type: "text",
                      id: "phone",
                      name: "phone",
                      disabled: ""
                    },
                    domProps: { value: _vm.reservation.user_phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.reservation,
                          "user_phone",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("phone")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("phone")))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group form-group--no_people",
                  class: { "has-error": _vm.errors.has("no_people") }
                },
                [
                  _c("label", { attrs: { for: "no_people" } }, [
                    _vm._v(_vm._s(_vm._f("trans")("general.no_people")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reservation.no_people,
                        expression: "reservation.no_people"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|numeric|min_value:1|max_value:30",
                        expression:
                          "'required|numeric|min_value:1|max_value:30'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      "data-vv-as": _vm.input.no_people[_vm.locale],
                      type: "number",
                      min: "1",
                      max: "30",
                      step: "1",
                      id: "no_people",
                      name: "no_people"
                    },
                    domProps: { value: _vm.reservation.no_people },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.reservation,
                          "no_people",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("no_people")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("no_people")))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    inline: true,
                    "disabled-dates": _vm.disabledDates,
                    highlighted: _vm.highlightedDates,
                    "monday-first": true,
                    language: _vm.lang[_vm.locale],
                    name: "reservation_date",
                    "data-vv-as": _vm.input.reservation_date[_vm.locale]
                  },
                  on: {
                    changedMonth: _vm.getAvailability,
                    selected: _vm.selectDate
                  },
                  model: {
                    value: _vm.reservation_date,
                    callback: function($$v) {
                      _vm.reservation_date = $$v
                    },
                    expression: "reservation_date"
                  }
                },
                [
                  _c(
                    "label",
                    {
                      attrs: { slot: "beforeCalendarHeader" },
                      slot: "beforeCalendarHeader"
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("trans")("general.reservation_date")) +
                          "\n                "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.errors.has("reservation_date")
                ? _c("small", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.first("reservation_date")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group form-group--reservation_time",
                  class: { "has-error": _vm.errors.has("reservation_time") }
                },
                [
                  _c("label", { attrs: { for: "reservation_time" } }, [
                    _vm._v(_vm._s(_vm._f("trans")("general.reservation_time")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group mb-2" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          options: _vm.availableTimes,
                          id: "reservation_time",
                          name: "reservation_time",
                          disabled: !_vm.reservation_date,
                          "data-vv-as": _vm.input.hour[_vm.locale]
                        },
                        model: {
                          value: _vm.reservation.reservation_time,
                          callback: function($$v) {
                            _vm.$set(_vm.reservation, "reservation_time", $$v)
                          },
                          expression: "reservation.reservation_time"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.errors.has("reservation_time")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("reservation_time")))
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 restaurant_booking__submit" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-brand btn-lg d-block mx-auto mt-4",
                attrs: { disabled: _vm.loading },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.book($event)
                  }
                }
              },
              [
                _vm.loading
                  ? _c("i", { staticClass: "spinner icon-spinner" })
                  : _vm._e(),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("trans")("general.book")) +
                    "\n            "
                )
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "icon-clock" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
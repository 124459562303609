var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-shrink-in", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal show",
        staticStyle: { "padding-right": "17px", display: "block" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.close(false)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title h4",
                    attrs: { id: "myLargeModalLabel" }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", { domProps: { innerHTML: _vm._s(_vm.text) } }),
                _vm._v(" "),
                _vm.show_reason
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "reason" } }, [
                            _vm._v(
                              _vm._s(_vm._f("trans")("general.reason_email"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reason_text,
                                expression: "reason_text"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "reason",
                              id: "reason",
                              cols: "30",
                              rows: "10"
                            },
                            domProps: { value: _vm.reason_text },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.reason_text = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.close")) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    class: "btn-" + _vm.level,
                    attrs: { type: "button", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.confirm($event)
                      }
                    }
                  },
                  [
                    _vm.loading
                      ? _c("i", { staticClass: "spinner icon icon-spinner" })
                      : _vm._e(),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.confirm")) +
                        "\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
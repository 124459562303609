<template>
    <div class="datatable__row">
        <div v-for="(column, key) in columns" class="datatable__cell" v-bind:class="'datatable__cell--' + key"
             v-bind:aria-label="column.title | trans"
             v-bind:style="'width:' + column.width + ';'">
            <template v-if="key === 'actions'">
                <datatable-actions :actions="row[key]" :row="row" @reload="reload"></datatable-actions>
            </template>
            <template v-else>
                <div v-if="column.editable" class="input-group">
                    <input type="text" v-model="row[key]" class="form-control"
                           @change="updateField(row, key)">
                    <div v-if="loading" class="input-group-append">
                        <div class="input-group-text"><i class="icon-spinner spinner"></i></div>
                    </div>
                </div>
                <span v-else v-html="row[key]"></span>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
            };
        },
        props: ['columns', 'row'],
        methods: {
            updateField(row, key) {
                this.loading = true;
                var params = {reservation: row};
                axios.post(this.columns[key].edit_url, params)
                    .then(response => {
                        this.loading = false;
                    }).catch(error => {
                    row[key] = null;
                    this.loading = false;
                    console.log('Ooops...');
                });
            },
            reload() {
                this.$emit('reload');
            }
        }
    }
</script>

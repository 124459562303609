var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-group",
      class: { "has-error": _vm.errors.has(_vm.labelName) }
    },
    [
      _c("label", { attrs: { for: _vm.labelName } }, [
        _vm._v(_vm._s(_vm._f("trans")(_vm.fieldData.label)))
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: _vm.fieldData.validateRules,
            expression: "fieldData.validateRules"
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fieldModel,
            expression: "fieldModel"
          }
        ],
        staticClass: "form-control",
        attrs: {
          "data-vv-as": _vm.input.price[_vm.locale],
          type: "number",
          id: _vm.labelName,
          name: _vm.labelName,
          step: "0.01",
          min: "0"
        },
        domProps: { value: _vm.fieldModel },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.fieldModel = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.errors.has(_vm.labelName)
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.errors.first(_vm.labelName)))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("strong", [_vm._v("€")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
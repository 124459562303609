<template>
    <div class="input-group" v-bind:class="{'has-error' : errors.has(labelName)}">
        <label :for="labelName">{{fieldData.label | trans }}</label>
        <input v-validate="fieldData.validateRules"
               v-bind:data-vv-as="input.price[locale]"
               v-model="fieldModel"
               type="number" class="form-control"
               :id="labelName"
               :name="labelName"
               step="0.01" min="0">
        <div class="input-group-append">
            <span class="input-group-text">
                <strong>&euro;</strong>
            </span>
        </div>
        <small v-if="errors.has(labelName)" class="text-danger">{{ errors.first(labelName) }}</small>
    </div>
</template>

<script>
    export default {
        components: {},
        props: [
            'locale',
            'input',
            'menu',
            'fieldData'
        ],
        data: function () {
            return {
                labelName: this.useKey(),
                fieldModel: null,
            }
        },
        methods: {
            useKey() {
                if (this.fieldData.key) {
                    return this.fieldData.name + '_' + this.fieldData.key;
                }
                return this.fieldData.name;
            }
        },
        watch: {
            fieldModel: function (value) {
                // console.log("Emit € ", value);
                this.$emit('change', value, this.fieldData.model);
            },
        },
        mounted() {
            Vue.nextTick(() => {
                // console.log(this.menu[this.fieldData.name]);
                if (this.menu[this.fieldData.name] !== undefined) {
                    this.fieldModel = this.menu[this.fieldData.name];
                }
                if (this.fieldData.model.length === 3) {
                    this.fieldModel = this.menu[this.fieldData.model[0]][this.fieldData.model[1]][this.fieldData.model[2]]
                }
            });
        }
    }
</script>

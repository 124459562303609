<template>
    <transition name="fade-shrink-in" appear>
        <div class="modal show" style="padding-right: 17px; display: block;"
             @click.prevent="close(false)">
            <div class="modal-dialog modal-dialog-centered" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="myLargeModalLabel">{{ title }}</h5>
                        <button type="button" class="close" @click.prevent="close(false)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p v-html="text"></p>
                        <div v-if="show_reason" class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="reason">{{ 'general.reason_email' | trans }}</label>
                                    <textarea v-model="reason_text" name="reason" id="reason" cols="30"
                                              rows="10" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close(false)">
                            {{ 'general.close' | trans }}
                        </button>
                        <button
                            type="button"
                            class="btn" v-bind:class="'btn-' + level"
                            @click.prevent="confirm"
                            v-bind:disabled="loading"
                        >
                            <i v-if="loading" class="spinner icon icon-spinner"></i>
                            {{ 'general.confirm' | trans }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                reason_text: null,
            };
        },
        props: {
            url: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            method: {
                type: String,
                default: 'POST',
            },
            params: {
                type: Object,
                default: null,
            },
            level: {
                type: String,
                default: 'danger'
            },
            show_reason: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            confirm() {
                this.loading = true;
                var params = this.params;
                if (this.reason_text) {
                    if (!this.params) {
                        this.params = {};
                    }
                    params['reason'] = this.reason_text;
                }
                if (this.method == 'POST') {
                    axios.post(this.url, params)
                        .then(response => {
                            this.loading = false;
                            this.close(true);
                        }).catch(error => {
                        console.log('Ooops');
                        this.loading = false;
                        this.close(false);
                    })
                } else {
                    axios.get(this.url, {params: params})
                        .then(response => {
                            this.loading = false;
                            this.close(true);
                        }).catch(error => {
                        console.log('Ooops');
                        this.loading = false;
                        this.close(false);
                    })
                }

            },
            close(reload) {
                this.$emit('close', reload);
            },
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-datatable row" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.show_buttons || _vm.show_search
        ? _c(
            "div",
            { staticClass: "col-12 vue-datatable__actions" },
            [
              _vm.show_buttons
                ? _c("datatable-buttons", {
                    attrs: { buttons_loading: _vm.buttons_loading },
                    on: {
                      reload: function($event) {
                        return _vm.getData(_vm.rows.current_page)
                      },
                      print: _vm.print,
                      csv: function($event) {
                        return _vm.downloadExport("csv")
                      },
                      xls: function($event) {
                        return _vm.downloadExport("xls")
                      },
                      pdf: function($event) {
                        return _vm.downloadExport("pdf")
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.show_search
                ? _c("datatable-search", { on: { search: _vm.performSearch } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 datatable" },
        [
          _c("datatable-header", {
            key: _vm.headerKey,
            attrs: { columns: _vm.rows.columns, order: _vm.order },
            on: { sort: _vm.performSort }
          }),
          _vm._v(" "),
          _c("datatable-body", {
            attrs: {
              columns: _vm.rows.columns,
              rows: _vm.rows.data,
              loading: _vm.loading
            },
            on: {
              reload: function($event) {
                return _vm.getData(_vm.rows.current_page)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "footer",
        { staticClass: "col-12 vue-datatable__footer" },
        [
          _vm.show_stats
            ? _c("datatable-stats", {
                attrs: {
                  from: _vm.rows.from,
                  to: _vm.rows.to,
                  total: _vm.rows.total
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show_pagination
            ? _c(
                "nav",
                { staticClass: "vue-datatable__pagination" },
                [
                  _c("paginate", {
                    attrs: {
                      "page-count": _vm.rows.last_page,
                      "container-class": "pagination",
                      "prev-text": _vm._f("trans")("pagination.previous"),
                      "next-text": _vm._f("trans")("pagination.next"),
                      "page-class": "page-item",
                      "prev-class": "page-item",
                      "next-class": "page-item",
                      "page-link-class": "page-link",
                      "prev-link-class": "page-link",
                      "next-link-class": "page-link",
                      "click-handler": _vm.getData
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-new-menu" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-between mb-4" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-brand-outline",
            attrs: { type: "button", disabled: _vm.loading },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.saveMenu($event)
              }
            }
          },
          [
            _vm.loading
              ? _c("i", { staticClass: "icon icon-spinner spinner" })
              : _vm._e(),
            _vm._v(_vm._s(_vm._f("trans")("general.save")))
          ]
        )
      ],
      2
    ),
    _vm._v(" "),
    _c("section", { staticClass: "box--shadowed" }, [
      _c("h4", { staticClass: "heading-2 text-brand" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("trans")("general.introduce_menu")) +
            "\n        "
        )
      ]),
      _vm._v(" "),
      _c(
        "form",
        { attrs: { autocomplete: "off" } },
        [
          _c("div", { staticClass: "general" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "form-group col-md-6 col-lg-4",
                  class: { "has-error": _vm.errors.has("type") }
                },
                [
                  _c("label", { attrs: { for: "type" } }, [
                    _vm._v(_vm._s(_vm._f("trans")("general.type")) + " "),
                    _c("span", { staticClass: "required" }, [_vm._v(" *")])
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "type",
                    attrs: {
                      "data-vv-as": _vm.input.menu_type_id[_vm.locale],
                      id: "type",
                      name: "type",
                      options: _vm._f("prepare_select")(_vm.types, "name"),
                      reduce: function(type) {
                        return type.id
                      }
                    },
                    on: { input: _vm.changeMenusCategories },
                    model: {
                      value: _vm.menu.menu_type_id,
                      callback: function($$v) {
                        _vm.$set(_vm.menu, "menu_type_id", $$v)
                      },
                      expression: "menu.menu_type_id"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("type")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("type")))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group col-md-6 col-lg-4",
                  class: { "has-error": _vm.errors.has("private_name") }
                },
                [
                  _c("label", { attrs: { for: "private_name" } }, [
                    _vm._v(
                      _vm._s(_vm._f("trans")("general.menu_private_name"))
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v(" *")])
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3|max:255",
                        expression: "'required|min:3|max:255'"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.menu.private_name,
                        expression: "menu.private_name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      "data-vv-as": _vm.input.private_name[_vm.locale],
                      type: "text",
                      id: "private_name",
                      name: "private_name",
                      maxlength: "255"
                    },
                    domProps: { value: _vm.menu.private_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.menu, "private_name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("private_name")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("private_name")))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-md-6 col-lg-4" },
                [
                  _c(
                    "dummy-multilang",
                    {
                      attrs: {
                        langs: _vm.langs,
                        field_name: "menu_public_name",
                        required: "true"
                      }
                    },
                    [
                      _vm._l(_vm.langs, function(lang) {
                        return _c("template", { slot: lang }, [
                          _c(
                            "div",
                            {
                              class: {
                                "has-error": _vm.errors.has(
                                  "public_name_" + lang
                                )
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.menu["public_name"][lang],
                                    expression: "menu['public_name'][lang]"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|min:3|max:255",
                                    expression: "'required|min:3|max:255'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "public_name_" + lang,
                                  maxlength: "255",
                                  "data-vv-as":
                                    _vm.input["public_name_" + lang][_vm.locale]
                                },
                                domProps: {
                                  value: _vm.menu["public_name"][lang]
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.menu["public_name"],
                                      lang,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.langs, function(lang) {
                    return [
                      _c("p", { staticClass: "m-0" }, [
                        _vm.errors.has("public_name_" + lang)
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.errors.first("public_name_" + lang)
                                  ) +
                                  "\n                                "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.menu.menu_type_id === 2
                ? _c("div", { staticClass: "form-group col-md-6 col-lg-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group",
                        class: { "has-error": _vm.errors.has("price") }
                      },
                      [
                        _c("label", { attrs: { for: "price" } }, [
                          _vm._v(_vm._s(_vm._f("trans")("general.price"))),
                          _c("span", { staticClass: "required" }, [
                            _vm._v(" *")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.menu["price"],
                              expression: "menu['price']"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required_if:type,2|decimal:2",
                              expression: "'required_if:type,2|decimal:2'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            name: "price",
                            id: "price",
                            step: "0.01",
                            min: "0",
                            "data-vv-as": _vm.input.price[_vm.locale]
                          },
                          domProps: { value: _vm.menu["price"] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.menu, "price", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _c("p", { staticClass: "m-0" }, [
                          _vm.errors.has("price")
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("price")))
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.menu.menu_type_id === 2
                ? _c(
                    "div",
                    {
                      staticClass: "form-group col-md-6 col-lg-4",
                      class: { "has-error": _vm.errors.has("max_no_dishes") }
                    },
                    [
                      _c("label", { attrs: { for: "max_no_dishes" } }, [
                        _vm._v(_vm._s(_vm._f("trans")("general.no_dishes"))),
                        _c("span", { staticClass: "required" }, [_vm._v(" *")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "numeric|required_if:type,2|min:0",
                            expression: "'numeric|required_if:type,2|min:0'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.menu.max_no_dishes,
                            expression: "menu.max_no_dishes"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          "data-vv-as": _vm.input.max_no_dishes[_vm.locale],
                          type: "number",
                          id: "max_no_dishes",
                          name: "max_no_dishes",
                          step: "0",
                          min: "0",
                          maxlength: "255"
                        },
                        domProps: { value: _vm.menu.max_no_dishes },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.menu,
                              "max_no_dishes",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("max_no_dishes")
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.errors.first("max_no_dishes")))
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.menu.menu_type_id === 2 && _vm.is_half_price_required
                ? _c("div", { staticClass: "form-group col-md-6 col-lg-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group",
                        class: { "has-error": _vm.errors.has("half_price") }
                      },
                      [
                        _c("label", { attrs: { for: "half_price" } }, [
                          _vm._v(
                            _vm._s(_vm._f("trans")("general.half_price_menu"))
                          ),
                          _c("span", { staticClass: "required" }, [
                            _vm._v(" *")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value:
                                "decimal:2|required_if:half_price_required,true",
                              expression:
                                "'decimal:2|required_if:half_price_required,true'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.menu.half_price,
                              expression: "menu.half_price"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            "data-vv-as": _vm.input.half_price[_vm.locale],
                            type: "number",
                            id: "half_price",
                            name: "half_price",
                            step: "any",
                            min: "0"
                          },
                          domProps: { value: _vm.menu.half_price },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.menu,
                                "half_price",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _c("p", { staticClass: "m-0" }, [
                          _vm.errors.has("half_price")
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("half_price")))
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.menu.menu_type_id === 2 && _vm.is_half_price_required
                ? _c(
                    "div",
                    {
                      staticClass: "form-group col-md-6 col-lg-4",
                      class: {
                        "has-error": _vm.errors.has("half_max_no_dishes")
                      }
                    },
                    [
                      _c("label", { attrs: { for: "half_max_no_dishes" } }, [
                        _vm._v(
                          _vm._s(_vm._f("trans")("general.no_dishes_half"))
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v(" *")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value:
                              "numeric|required_if:half_price_required|min:0",
                            expression:
                              "'numeric|required_if:half_price_required|min:0'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.menu.half_max_no_dishes,
                            expression: "menu.half_max_no_dishes"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          "data-vv-as":
                            _vm.input.half_max_no_dishes[_vm.locale],
                          type: "number",
                          id: "half_max_no_dishes",
                          name: "half_max_no_dishes",
                          step: "0",
                          min: "0",
                          maxlength: "255"
                        },
                        domProps: { value: _vm.menu.half_max_no_dishes },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.menu,
                              "half_max_no_dishes",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("half_max_no_dishes")
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first("half_max_no_dishes"))
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "form-group col-12",
                  class: { "has-error": _vm.errors.has("when_served") }
                },
                [
                  _c("label", { attrs: { for: "when_served" } }, [
                    _vm._v(_vm._s(_vm._f("trans")("general.when_served"))),
                    _c("span", { staticClass: "required" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "icon-info-sign" })
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    attrs: {
                      "data-vv-as": _vm.input.when_served[_vm.locale],
                      id: "when_served",
                      name: "when_served",
                      multiple: "",
                      options: _vm._f("prepare_select")(_vm.turns, "name")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selected-option",
                        fn: function(option) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "vs__selected_tag turn_tag",
                                class: "turn_tag--" + option.id
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "vs__selected_tag__label" },
                                  [_vm._v(_vm._s(option.label))]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.menu.when_served,
                      callback: function($$v) {
                        _vm.$set(_vm.menu, "when_served", $$v)
                      },
                      expression: "menu.when_served"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("when_served")
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("when_served")))
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.menu.menu_type_id === 2
              ? _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-12 my-4 col-form-label" }, [
                    _c("div", { staticClass: "form-check checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.is_half_price_required,
                            expression: "is_half_price_required"
                          }
                        ],
                        ref: "half_price_required",
                        staticClass: "form-check-input",
                        attrs: {
                          name: "half_price_required",
                          id: "half_price_required",
                          type: "checkbox",
                          value: ""
                        },
                        domProps: {
                          checked: Array.isArray(_vm.is_half_price_required)
                            ? _vm._i(_vm.is_half_price_required, "") > -1
                            : _vm.is_half_price_required
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.is_half_price_required,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.is_half_price_required = $$a.concat([
                                    $$v
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.is_half_price_required = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.is_half_price_required = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "half_price_required" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("trans")("general.half_menu_option"))
                          ),
                          _c("span", { staticClass: "required" }, [
                            _vm._v(" *")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check mt-4 checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.menu.full_table_required,
                            expression: "menu.full_table_required"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          name: "full_table_required",
                          id: "full_table_required",
                          type: "checkbox",
                          value: ""
                        },
                        domProps: {
                          checked: Array.isArray(_vm.menu.full_table_required)
                            ? _vm._i(_vm.menu.full_table_required, "") > -1
                            : _vm.menu.full_table_required
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.menu.full_table_required,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.menu,
                                    "full_table_required",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.menu,
                                    "full_table_required",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.menu, "full_table_required", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "full_table_required" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("trans")("general.full_table_option"))
                          )
                        ]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-12" },
                [
                  _c(
                    "dummy-multilang",
                    { attrs: { langs: _vm.langs, field_name: "observations" } },
                    [
                      _vm._l(_vm.langs, function(lang) {
                        return _c("template", { slot: lang }, [
                          _c(
                            "div",
                            {
                              class: {
                                "has-error": _vm.errors.has(
                                  "observations_" + lang
                                )
                              }
                            },
                            [
                              _c("VueTrix", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "min:3|max:2000",
                                    expression: "'min:3|max:2000'"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  name: "observations_" + lang,
                                  maxlength: "2000",
                                  "data-vv-as":
                                    _vm.input["observations_" + lang][
                                      _vm.locale
                                    ]
                                },
                                model: {
                                  value: _vm.menu["observations"][lang],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.menu["observations"],
                                      lang,
                                      $$v
                                    )
                                  },
                                  expression: "menu['observations'][lang]"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.langs, function(lang) {
                    return [
                      _c("p", { staticClass: "m-0" }, [
                        _vm.errors.has("observations_" + lang)
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.errors.first("observations_" + lang)
                                  ) +
                                  "\n                                "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _vm.menu.menu_type_id === 2
            ? [
                _c("div", { staticClass: "row" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 d-flex justify-content-between my-4"
                    },
                    [
                      _c("h4", { staticClass: "heading-2 text-brand" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")("general.menu_category_explication")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-brand",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addCategoryRow($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("trans")("general.add_category"))
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.menu.elements, function(element, key) {
                  return _c(
                    "div",
                    { staticClass: "row mx-2 box--shadowed dish_option" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 dish_option__header_wrapper" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row align-items-center dish_option__header"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 col-md-6 col-lg-4 form-group dish_option__header__option",
                                  class: {
                                    "has-error": _vm.errors.has(
                                      "dish_option_" + key
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "dish_option_" + key } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("trans")("general.dish_option")
                                        )
                                      ),
                                      _c("span", { staticClass: "required" }, [
                                        _vm._v(" *")
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required_if:type,2",
                                        expression: "'required_if:type,2'"
                                      }
                                    ],
                                    attrs: {
                                      "data-vv-as":
                                        _vm.input.dish_option[_vm.locale],
                                      id: "dish_option_" + key,
                                      name: "dish_option_" + key,
                                      reduce: function(dish_option) {
                                        return dish_option.id
                                      },
                                      options: _vm._f("prepare_option_select")(
                                        _vm.dish_option,
                                        "name"
                                      )
                                    },
                                    model: {
                                      value: _vm.menu.elements[key].dish_option,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.menu.elements[key],
                                          "dish_option",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menu.elements[key].dish_option"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.has("dish_option_" + key)
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "dish_option_" + key
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 col-md-4 col-lg-7 col-form-label dish_option__header__required"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-check checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.menu.elements[key].required,
                                            expression:
                                              "menu.elements[key].required"
                                          }
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: {
                                          name: "dish_required_" + key,
                                          id: "dish_required_" + key,
                                          type: "checkbox",
                                          value: ""
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.menu.elements[key].required
                                          )
                                            ? _vm._i(
                                                _vm.menu.elements[key].required,
                                                ""
                                              ) > -1
                                            : _vm.menu.elements[key].required
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.menu.elements[key].required,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.menu.elements[key],
                                                    "required",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.menu.elements[key],
                                                    "required",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.menu.elements[key],
                                                "required",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: { for: "dish_required_" + key }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("trans")(
                                                "general.required"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 col-md-2 col-lg-1 form-group d-flex justify-content-end dish_option__header__action"
                                },
                                [
                                  _c("div", [
                                    _c("button", {
                                      staticClass:
                                        "btn-action btn-action--remove",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.subtractMenuElement(key)
                                        }
                                      }
                                    })
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 dish_option__category" },
                        [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group col-12",
                                class: {
                                  "has-error": _vm.errors.has(
                                    "dish_category_" + key
                                  )
                                }
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "dish_category_" + key } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("trans")("general.category")
                                      )
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v(" *")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-select", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required_if:type,2",
                                      expression: "'required_if:type,2'"
                                    }
                                  ],
                                  attrs: {
                                    "data-vv-as":
                                      _vm.input.category[_vm.locale],
                                    id: "dish_category_" + key,
                                    name: "dish_category_" + key,
                                    options: _vm._f("prepare_select")(
                                      _vm.dish_categories,
                                      "name"
                                    ),
                                    reduce: function(category_id) {
                                      return category_id.id
                                    }
                                  },
                                  model: {
                                    value: _vm.menu.elements[key].category_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.menu.elements[key],
                                        "category_id",
                                        $$v
                                      )
                                    },
                                    expression: "menu.elements[key].category_id"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("dish_category_" + key)
                                  ? _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "dish_category_" + key
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 dish_option__category__dishes" },
                        _vm._l(_vm.menu.elements[key].dishes, function(
                          row,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              staticClass:
                                "row align-items-center dish_option__category__dish"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group col-12 col-lg-6 dish_option__category__dish__name"
                                },
                                [
                                  _c(
                                    "dummy-multilang",
                                    {
                                      attrs: {
                                        langs: _vm.langs,
                                        field_name: "dish_name",
                                        required: "true"
                                      }
                                    },
                                    [
                                      _vm._l(_vm.langs, function(lang) {
                                        return _c("template", { slot: lang }, [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "has-error": _vm.errors.has(
                                                  "dish_name_" +
                                                    key +
                                                    "_" +
                                                    index +
                                                    "_" +
                                                    lang
                                                )
                                              }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.menu["elements"][key][
                                                        "dishes"
                                                      ][index]["dish_name"][
                                                        lang
                                                      ],
                                                    expression:
                                                      "menu['elements'][key]['dishes'][index]['dish_name'][lang]"
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value:
                                                      "required_if:type,2|min:3|max:255",
                                                    expression:
                                                      "'required_if:type,2|min:3|max:255'"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name:
                                                    "dish_name_" +
                                                    key +
                                                    "_" +
                                                    index +
                                                    "_" +
                                                    lang,
                                                  maxlength: "255",
                                                  "data-vv-as":
                                                    _vm.input[
                                                      "dish_name_" + lang
                                                    ][_vm.locale]
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.menu["elements"][key][
                                                      "dishes"
                                                    ][index]["dish_name"][lang]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.menu["elements"][key][
                                                        "dishes"
                                                      ][index]["dish_name"],
                                                      lang,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ])
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.langs, function(lang) {
                                    return [
                                      _c("p", { staticClass: "m-0" }, [
                                        _vm.errors.has(
                                          "dish_name_" +
                                            key +
                                            "_" +
                                            index +
                                            "_" +
                                            lang
                                        )
                                          ? _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "dish_name_" +
                                                          key +
                                                          "_" +
                                                          index +
                                                          "_" +
                                                          lang
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group col-12 col-lg-5 dish_option__category__dish__tags"
                                },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "dish_tags_" + index } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("trans")(
                                            "general.dish_tag_caracteristcs"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm._f("prepare_select")(
                                        _vm.dish_tags,
                                        "name"
                                      ),
                                      id: "dish_tags_" + index,
                                      name: "dish_tags[]",
                                      multiple: "multiple",
                                      reduce: function(tag) {
                                        return tag.id
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selected-option",
                                          fn: function(option) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "vs__selected_tag dish_tag",
                                                  class:
                                                    "dish_tag--" + option.id
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "vs__selected_tag__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.label)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.menu.elements[key].dishes[index]
                                          .tag_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.menu.elements[key].dishes[index],
                                          "tag_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menu.elements[key].dishes[index].tag_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group col-12  col-lg-1 d-flex justify-content-end dish_option__category__dish__action"
                                },
                                [
                                  index === 0
                                    ? _c("div", [
                                        _c("button", {
                                          staticClass:
                                            "btn-action btn-action--add",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.addDish(key)
                                            }
                                          }
                                        })
                                      ])
                                    : _c("div", [
                                        _c("button", {
                                          staticClass:
                                            "btn-action btn-action--remove",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.subtractDish(
                                                index,
                                                key
                                              )
                                            }
                                          }
                                        })
                                      ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.menu.menu_type_id === 1
            ? [
                _c("div", { staticClass: "row" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 d-flex justify-content-between my-4"
                    },
                    [
                      _c("h4", { staticClass: "heading-2 text-brand" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "general.dayly_menu_category_explication"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-brand",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addCarta($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm._f("trans")("general.add_dish")))]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.menu.elements, function(element, key) {
                  return _c(
                    "div",
                    { staticClass: "row mx-2 box--shadowed carta__dish" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-10 col-lg-11 carta__dish__category"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              class: {
                                "has-error": _vm.errors.has(
                                  "dish_category_carta_" + key
                                )
                              }
                            },
                            [
                              _c(
                                "label",
                                {
                                  attrs: { for: "dish_category_carta_" + key }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("trans")("general.category"))
                                  ),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v(" *")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required_if:type,2",
                                    expression: "'required_if:type,2'"
                                  }
                                ],
                                attrs: {
                                  "data-vv-as": _vm.input.category[_vm.locale],
                                  id: "dish_category_" + key,
                                  name: "dish_category_" + key,
                                  options: _vm._f("prepare_select")(
                                    _vm.dish_categories,
                                    "name"
                                  )
                                },
                                model: {
                                  value: _vm.menu.elements[key].category_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.menu.elements[key],
                                      "category_id",
                                      $$v
                                    )
                                  },
                                  expression: "menu.elements[key].category_id"
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors.has("dish_category_carta_" + key)
                                ? _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.first(
                                          "dish_category_carta_" + key
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-2 col-lg-1 col-form-label d-flex justify-content-end align-items-center carta__dish__action"
                        },
                        [
                          _c("button", {
                            staticClass: "btn-action btn-action--remove",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.subtractMenuElement(key)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 form-group carta__dish__name" },
                        [
                          _c(
                            "dummy-multilang",
                            {
                              attrs: {
                                langs: _vm.langs,
                                field_name: "dish_name",
                                required: "true"
                              }
                            },
                            [
                              _vm._l(_vm.langs, function(lang) {
                                return _c("template", { slot: lang }, [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "has-error": _vm.errors.has(
                                          "dish_name_" + key + "_" + lang
                                        )
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.menu["elements"][key][
                                                "dish_name"
                                              ][lang],
                                            expression:
                                              "menu['elements'][key]['dish_name'][lang]"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "required_if:type,1|min:3|max:255",
                                            expression:
                                              "'required_if:type,1|min:3|max:255'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "dish_name_" + key + "_" + lang,
                                          maxlength: "2000",
                                          "data-vv-as":
                                            _vm.input["dish_name_" + lang][
                                              _vm.locale
                                            ]
                                        },
                                        domProps: {
                                          value:
                                            _vm.menu["elements"][key][
                                              "dish_name"
                                            ][lang]
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.menu["elements"][key][
                                                "dish_name"
                                              ],
                                              lang,
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.langs, function(lang) {
                            return [
                              _c("p", { staticClass: "m-0" }, [
                                _vm.errors.has("dish_name_" + key + "_" + lang)
                                  ? _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "dish_name_" + key + "_" + lang
                                              )
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-6 col-lg-8 carta__dish__tags"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "dish_tags_" + key } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("trans")(
                                        "general.dish_tag_caracteristcs"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  options: _vm._f("prepare_select")(
                                    _vm.dish_tags,
                                    "name"
                                  ),
                                  reduce: function(tag) {
                                    return tag.id
                                  },
                                  id: "dish_tags_" + key,
                                  name: "dish_tags_" + key,
                                  multiple: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selected-option",
                                      fn: function(option) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "vs__selected_tag dish_tag",
                                              class: "dish_tag--" + option.id
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "vs__selected_tag__label"
                                                },
                                                [_vm._v(_vm._s(option.label))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.menu.elements[key].tag_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.menu.elements[key],
                                      "tag_id",
                                      $$v
                                    )
                                  },
                                  expression: "menu.elements[key].tag_id"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-3 col-lg-2 carta__dish__price"
                        },
                        [
                          _c("div", { staticClass: "input-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "dish_name_" + key } },
                              [_vm._v(_vm._s(_vm._f("trans")("general.price")))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.menu["elements"][key]["price"],
                                  expression: "menu['elements'][key]['price']"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:0|max:255",
                                  expression: "'min:0|max:255'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "dish_name_" + key,
                                "data-vv-as": _vm.input.price[_vm.locale]
                              },
                              domProps: {
                                value: _vm.menu["elements"][key]["price"]
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.menu["elements"][key],
                                    "price",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "m-0" }, [
                              _vm.errors.has("dish_name_" + key)
                                ? _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.first("dish_name_" + key)
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-3 col-lg-2 carta__dish__price"
                        },
                        [
                          _c("label", { attrs: { for: "type" } }, [
                            _vm._v(
                              _vm._s(_vm._f("trans")("general.price_type")) +
                                " "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              name: "price_type" + key,
                              options: _vm._f("prepare_select")(
                                _vm.price_type_option,
                                "name"
                              )
                            },
                            model: {
                              value: _vm.menu.elements[key].price_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.menu.elements[key],
                                  "price_type",
                                  $$v
                                )
                              },
                              expression: "menu.elements[key].price_type"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("type")
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("type")))
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                })
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex justify-content-end mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-brand-outline",
          attrs: { type: "button", disabled: _vm.loading },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.saveMenu($event)
            }
          }
        },
        [
          _vm.loading
            ? _c("i", { staticClass: "icon icon-spinner spinner" })
            : _vm._e(),
          _vm._v("\n            " + _vm._s(_vm._f("trans")("general.save")))
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("strong", [_vm._v("€")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("strong", [_vm._v("€")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 my-4" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 my-4" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }
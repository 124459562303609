<template>
    <div>
        <div class="vue-datatable-actions btn-group">
            <button v-for="action in actions" type="button" v-bind:class="['btn', action.color]"
                    @click="performAction(action)" :title="'general.buttons.'+ action.name | trans">
                <i class="icon" v-bind:class="action.icon"></i>
            </button>
        </div>
        <portal to="datatable-modals">
            <confirmation-modal
                v-if="confirmation_modal_open"
                :url="confirmation_modal_url"
                :title="title | trans"
                :text="text | trans"
                :level="level"
                :show_reason="show_reason"
                :params="params"
                @close="closeConfrimationModal"
            ></confirmation-modal>
            <restaurant-booking-form-modal
                v-if="modal_open"
                :title="title |trans"
                :reservation_id="confirmation_modal_url"
                @close="closeConfrimationModal"
            ></restaurant-booking-form-modal>
        </portal>
    </div>
</template>

<script>
    import {closeModal, openModal} from "../modal";

    export default {
        data() {
            return {
                confirmation_modal_open: false,
                confirmation_modal_url: null,
                title: null,
                text: null,
                level: 'danger',
                show_reason: false,
                params: {},
                modal_open: false,
            };
        },
        props: ['actions', 'row'],
        methods: {
            performAction(action) {
                switch (action.name) {
                    case 'accept':
                        this.accept();
                        break;
                    case 'reject':
                        this.reject();
                        break;
                    case 'cancel':
                        this.cancel();
                        break;
                    case 'show':
                        this.show();
                        break;
                    case 'copy':
                        this.copy();
                        break;
                    case 'delete':
                        this.delete();
                        break;
                    case 'edit':
                        this.edit();
                        break;
                    case 'edit_rest':
                        this.editRestaurant();
                        break;
                    case 'users':
                        this.users();
                        break;
                    case 'share':
                        this.share();
                        break;
                }
            },
            accept() {
                this.confirmation_modal_url = this.actions.accept.url;
                this.title = 'general.confirm_reservation_h';
                this.text = 'general.confirm_reservation_p';
                this.level = 'success';
                this.show_reason = false;
                this.params = {id: this.row.id};
                this.confirmation_modal_open = true;
                openModal();
            },
            reject() {
                this.confirmation_modal_url = this.actions.reject.url;
                this.title = 'general.reject_reservation_h';
                this.text = 'general.reject_reservation_p';
                this.level = 'danger';
                this.show_reason = true;
                this.params = {id: this.row.id};
                this.confirmation_modal_open = true;
                openModal();
            },
            cancel() {
                this.confirmation_modal_url = this.actions.cancel.url;
                this.title = 'general.cancel_reservation_h';
                this.text = 'general.cancel_reservation_p';
                this.level = 'danger';
                this.show_reason = false;
                this.params = {id: this.row.id};
                this.confirmation_modal_open = true;
                openModal();
            },
            show() {
                window.location.href = this.actions.show.url;
            },
            share() {
                window.location.href = this.actions.share.url;
            },
            copy() {
                window.location.href = this.actions.copy.url;
            },
            delete() {
                this.confirmation_modal_url = this.actions.delete.url;
                this.title = 'general.confirm_delete_h';
                this.text = 'general.confirm_delete_p';
                this.level = 'danger';
                this.show_reason = false;
                this.params = {id: this.row.id};
                this.confirmation_modal_open = true;
                openModal();
            },
            edit() {
                window.location.href = this.actions.edit.url;
            },
            editRestaurant() {
                this.modal_open = true;
                this.title = 'general.edit_reservation_h';
                this.confirmation_modal_url = this.actions.edit.reservation_id;
                openModal();
            },
            closeConfrimationModal(reload) {
                if (reload) {
                    this.$emit('reload');
                }
                this.confirmation_modal_open = false;
                this.modal_open = false;
                closeModal();
            },
            users() {
                window.location.href = this.actions.users.url;
            },
        },
    }
</script>

<template>
    <span>
        {{ 'general.datatable_stats' | trans({from: from ? from : 0, to: to ? to : 0, total: total}) }}
    </span>
</template>

<script>
    export default {
        props: ['from', 'to', 'total'],
    }
</script>

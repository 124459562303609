var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-shrink-in", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal show",
        staticStyle: { "padding-right": "17px", display: "block" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.close(false)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-lg modal-dialog-centered",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title h4",
                    attrs: { id: "myLargeModalLabel" }
                  },
                  [_vm._v(_vm._s(_vm._f("trans")("general.add_closing")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-group col-sm-12" }, [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "periodic_close",
                          name: "type",
                          type: "radio",
                          value: "1"
                        },
                        domProps: { checked: _vm._q(_vm.type, "1") },
                        on: {
                          change: [
                            function($event) {
                              _vm.type = "1"
                            },
                            _vm.changeType
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "periodic_close" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm._f("trans")("general.periodic_close")
                              ) +
                              "\n                                "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "range_close",
                          name: "type",
                          type: "radio",
                          value: "2"
                        },
                        domProps: { checked: _vm._q(_vm.type, "2") },
                        on: {
                          change: [
                            function($event) {
                              _vm.type = "2"
                            },
                            _vm.changeType
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "range_close" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm._f("trans")("general.range_close")) +
                              "\n                                "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "punctual_close",
                          name: "type",
                          type: "radio",
                          value: "3"
                        },
                        domProps: { checked: _vm._q(_vm.type, "3") },
                        on: {
                          change: [
                            function($event) {
                              _vm.type = "3"
                            },
                            _vm.changeType
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "punctual_close" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm._f("trans")("general.punctual_close")
                              ) +
                              "\n                                "
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.type == 1
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.days,
                              reduce: function(day) {
                                return day.id
                              }
                            },
                            on: { input: _vm.selectDay },
                            model: {
                              value: _vm.selection,
                              callback: function($$v) {
                                _vm.selection = $$v
                              },
                              expression: "selection"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: _vm._f("trans")(
                                _vm.select_placeholder
                              ),
                              options: _vm._f("prepare_select")(
                                _vm.turns,
                                "name"
                              ),
                              reduce: function(turn) {
                                return turn.id
                              },
                              disabled: _vm.turns_disabled,
                              multiple: ""
                            },
                            model: {
                              value: _vm.selection_turn,
                              callback: function($$v) {
                                _vm.selection_turn = $$v
                              },
                              expression: "selection_turn"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm.type == 2
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-6" },
                        [
                          _c("date-range-picker", {
                            ref: "picker",
                            attrs: {
                              "locale-data": {
                                firstDay: 1,
                                format: "DD-MM-YYYY"
                              },
                              "min-date": _vm.min_date,
                              "auto-apply": _vm.auto_apply,
                              ranges: false
                            },
                            on: { update: _vm.selectRange },
                            scopedSlots: _vm._u([
                              {
                                key: "input",
                                fn: function(picker) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("date")(picker.startDate)
                                        ) +
                                        " - " +
                                        _vm._s(_vm._f("date")(picker.endDate)) +
                                        "\n                                "
                                    )
                                  ])
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selection,
                              callback: function($$v) {
                                _vm.selection = $$v
                              },
                              expression: "selection"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: _vm._f("trans")(
                                _vm.select_placeholder
                              ),
                              options: _vm._f("prepare_select")(
                                _vm.turns,
                                "name"
                              ),
                              reduce: function(turn) {
                                return turn.id
                              },
                              multiple: ""
                            },
                            model: {
                              value: _vm.selection_turn,
                              callback: function($$v) {
                                _vm.selection_turn = $$v
                              },
                              expression: "selection_turn"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm.type == 3
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-6" },
                        [
                          _c("datepicker", {
                            attrs: {
                              "monday-first": true,
                              language: _vm.lang[_vm.locale],
                              "disabled-dates": { to: new Date() },
                              "input-class": "form-control calendar-input"
                            },
                            on: { selected: _vm.selectDate },
                            model: {
                              value: _vm.selection,
                              callback: function($$v) {
                                _vm.selection = $$v
                              },
                              expression: "selection"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: _vm._f("trans")(
                                _vm.select_placeholder
                              ),
                              options: _vm._f("prepare_select")(
                                _vm.turns,
                                "name"
                              ),
                              reduce: function(turn) {
                                return turn.id
                              },
                              disabled: _vm.turns_disabled,
                              multiple: ""
                            },
                            model: {
                              value: _vm.selection_turn,
                              callback: function($$v) {
                                _vm.selection_turn = $$v
                              },
                              expression: "selection_turn"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.close")) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-brand",
                    attrs: {
                      type: "button",
                      disabled: _vm.save_disabled || _vm.save_loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.save($event)
                      }
                    }
                  },
                  [
                    _vm.save_loading
                      ? _c("i", { staticClass: "spinner icon-spinner" })
                      : _vm._e(),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.save")) +
                        "\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-shrink-in", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal show",
        staticStyle: { "padding-right": "17px", display: "block" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.close(false)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-lg modal-dialog-centered",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title h4",
                    attrs: { id: "myLargeModalLabel" }
                  },
                  [_vm._v(_vm._s(_vm._f("trans")("general.add_period")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("label", { attrs: { for: "select_menu" } }, [
                        _vm._v(_vm._s(_vm._f("trans")("general.select_menu")))
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          placeholder: _vm._f("trans")("general.select_menu"),
                          options: _vm._f("prepare_select")(_vm.menus, "name"),
                          reduce: function(menu) {
                            return menu.id
                          },
                          id: "select_menu",
                          multiple: ""
                        },
                        model: {
                          value: _vm.selected_menu,
                          callback: function($$v) {
                            _vm.selected_menu = $$v
                          },
                          expression: "selected_menu"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm._f("trans")("general.menu_serve_dates"))
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.selected_ranges, function(range, index) {
                        return _c(
                          "div",
                          [
                            _c("date-range-picker", {
                              ref: "picker",
                              refInFor: true,
                              attrs: {
                                "locale-data": {
                                  firstDay: 1,
                                  format: "DD-MM-YYYY"
                                },
                                "min-date": _vm.min_date,
                                "auto-apply": _vm.auto_apply,
                                ranges: false
                              },
                              on: { update: _vm.selectRange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "input",
                                    fn: function(picker) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm._f("date")(picker.startDate)
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm._f("date")(picker.endDate)
                                            ) +
                                            "\n                                    "
                                        )
                                      ])
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.selected_ranges[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.selected_ranges, index, $$v)
                                },
                                expression: "selected_ranges[index]"
                              }
                            }),
                            _vm._v(" "),
                            index == 0
                              ? _c("button", {
                                  staticClass: "btn-action btn-action--add",
                                  on: { click: _vm.addRange }
                                })
                              : _c("button", {
                                  staticClass: "btn-action btn-action--remove",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeRange(index)
                                    }
                                  }
                                })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.cancel")) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-brand",
                    attrs: {
                      type: "button",
                      disabled: _vm.save_disabled || _vm.save_loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.save($event)
                      }
                    }
                  },
                  [
                    _vm.save_loading
                      ? _c("i", { staticClass: "spinner icon-spinner" })
                      : _vm._e(),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.add")) +
                        "\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
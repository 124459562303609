var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "datatable__heading" },
    _vm._l(_vm.columns, function(column, key) {
      return _c(
        "div",
        {
          staticClass: "datatable__cell",
          class: "datatable__cell--" + key,
          style: "width:" + column.width + ";",
          attrs: { "aria-label": _vm._f("trans")(column.title) }
        },
        [
          column.sortable
            ? _c(
                "a",
                {
                  staticClass: "datatable__heading__action",
                  attrs: { href: "", "data-status": "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sortBy(key)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "datatable__heading__label" }, [
                    _vm._v(_vm._s(_vm._f("trans")(column.title)))
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-caret-up",
                    class: [
                      _vm.sort[key] == "desc" ? "text-brand" : "text-disabled"
                    ]
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-caret-down",
                    class: [
                      _vm.sort[key] == "asc" ? "text-brand" : "text-disabled"
                    ]
                  })
                ]
              )
            : _c(
                "span",
                {
                  staticClass: "datatable__heading__action",
                  attrs: { "data-status": "", role: "button" }
                },
                [
                  _c("span", { staticClass: "datatable__heading__label" }, [
                    _vm._v(_vm._s(_vm._f("trans")(column.title)))
                  ])
                ]
              )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
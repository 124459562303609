var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datatable__body" },
    [
      _vm.loading
        ? _c("div", { staticClass: "loader" }, [
            _c("h3", [_vm._v(_vm._s(_vm._f("trans")("general.loading")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.rows.length > 0
        ? _vm._l(_vm.rows, function(row) {
            return _c("datatable-row", {
              key: row.id,
              attrs: { columns: _vm.columns, row: row },
              on: { reload: _vm.reload }
            })
          })
        : !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "datatable__row d-flex justify-content-center pb-2"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("trans")("general.no_data")) +
                  "\n    "
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
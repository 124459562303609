<template>
    <div class="vue-datatable-buttons btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-secondary" @click.prevent="print" v-bind:disabled="buttons_loading">
            <i class="icon-printer"></i> {{'general.print'|trans}}
        </button>
        <button type="button" class="btn btn-secondary" @click.prevent="reload" v-bind:disabled="buttons_loading">
            <i class="icon-loop2"></i> {{'general.reload'|trans}}
        </button>
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-bind:disabled="buttons_loading">
            <i class="icon-download3"></i> {{'general.export'|trans}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click.prevent="exportCSV" v-bind:disabled="buttons_loading">
                <i class="icon-file-excel"></i> CSV</a>
            <a class="dropdown-item" href="#" @click.prevent="exportXLS" v-bind:disabled="buttons_loading">
                <i class="icon-file-excel"></i> EXCEL</a>
            <a class="dropdown-item" href="#" @click.prevent="exportPDF" v-bind:disabled="buttons_loading">
                <i class="icon-file-pdf"></i> PDF</a>
        </div>
        <button v-if="buttons_loading" class="btn" disabled="disabled">
            <i class="icon-spinner spinner"></i>
        </button>
    </div>
</template>

<script>
    export default {
        props: ['buttons_loading'],
        methods: {
            reload() {
                this.$emit('reload');
            },
            print() {
                this.$emit('print');
            },
            exportCSV() {
                this.$emit('csv');
            },
            exportXLS() {
                this.$emit('xls');
            },
            exportPDF() {
                this.$emit('pdf');
            }
        }
    }
</script>

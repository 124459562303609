var openModal = function () {
    document.body.classList.add('modal-open');
    document.body.style.paddingRight = '17px';
    document.body.style.overflow = 'hidden';
}

var closeModal = function () {
    document.body.classList.remove('modal-open');
    setTimeout(() => {
        document.body.removeAttribute('style');
    }, 250)
}

export {openModal, closeModal};

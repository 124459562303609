<template>
    <div class="vue-closings">
        <div class="mb-4 box--shadowed vue-closings__info">
            <h3 class="d-flex justify-content-between align-items-center heading-2 text-brand">
                <span>{{ 'general.info' | trans }}</span>
                <i class="icon-info"></i>
            </h3>
            <p>
                {{'general.closing_dates_info' | trans}}
            </p>
        </div>
        <div class="box--shadowed vue-closings__wrapper">
            <div v-if="loading_closing || loading_calendar" class="loader">
                <h3>{{ 'general.loading' | trans }}</h3>
            </div>
            <div class="row">
                <div class="col-12 mb-4 d-flex justify-content-between align-items-center vue-closings__header">
                    <h3 class="heading-2 text-brand vue-closings__title">
                        {{ 'general.add_closing' | trans }}
                    </h3>
                    <button class="btn-action btn-action--add" @click="openModalEmpty" v-bind:disabled="!loading"></button>
                </div>
                <div class="col-12 col-md-4 vue-closings__calendar">
                    <datepicker
                        :inline="true"
                        :disabled-dates="disabledDates"
                        :highlighted="highlightedDates"
                        :monday-first="true"
                        :language="lang[locale]"
                        @selected="calendarClick"
                        @changedMonth="getCalendarDates"
                    ></datepicker>
                </div>
                <div class="col-12 col-md-8 vue-closings__lists">
                    <div class="vue-closings__lists__header">
                        <h4 class="heading-3 mb-2">
                            {{ 'general.closing' | trans }}
                        </h4>
                    </div>

                    <hr>
                    <div class="vue-closings__list_wrapper">
                        <h5 class="heading-4 vue-closings__list__title">{{ 'general.periodic_close' | trans }}</h5>
                        <ul v-if="closing.days.length > 0" clas="vue-closings__list">
                            <li v-for="(day, index) in closing.days" class="vue-closings__closing">
                                <span class="vue-closings__closing__date">{{ day.data | getDayName(days) }}</span>
                                <span class="vue-closings__closing__turns">
                                    <span v-for="turn in day.turns_text" class="vue-closings__closing__turn">{{ turn }}</span>
                                </span>
                                <div class="vue-closings__closing__actions">
                                    <button class="btn-action btn-action--edit"
                                            @click="editClosing(day, 1)"
                                            v-bind:title="'general.edit' | trans">
                                    </button>
                                    <button class="btn-action btn-action--remove"
                                            @click="deleteClosing(day, 1)"
                                            v-bind:title="'general.delete' | trans">
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <hr>
                    <div class="vue-closings__list_wrapper">
                        <h5 class="heading-4 vue-closings__list__title">{{ 'general.range_close' | trans }}</h5>
                        <ul v-if="closing.ranges.length > 0" clas="vue-closings__list">
                            <li v-for="(range, index) in closing.ranges" class="vue-closings__closing">
                                <span class="vue-closings__closing__date">
                                    <span>{{ range.data.from | date }}</span> - <span>{{ range.data.to | date }}</span>
                                </span>
                                <span v-for="turn in range.turns_text" class="vue-closings__closing__turns">
                                    <span class="vue-closings__closing__turn">{{ turn }}</span>
                                </span>
                                <div class="vue-closings__closing__actions">
                                    <button class="btn-action btn-action--edit"
                                            @click="editClosing(range, 2)"
                                            v-bind:title="'general.edit' | trans">
                                    </button>
                                    <button class="btn-action btn-action--remove"
                                            @click="deleteClosing(range, 2)"
                                            v-bind:title="'general.delete' | trans">
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <hr>
                    <div class="vue-closings__list_wrapper">
                        <h5 class="heading-4 vue-closings__list__title">{{ 'general.punctual_close' | trans }}</h5>
                        <ul v-if="closing.dates.length > 0" clas="vue-closings__list">
                            <li v-for="(date, index) in closing.dates" class="vue-closings__closing">
                                <span class="vue-closings__closing__date">{{ date.data | date }}</span>
                                <span v-for="turn in date.turns_text" class="vue-closings__closing__turns">
                                    <span class="vue-closings__closing__turn">{{ turn }}</span>
                                </span>
                                <div class="vue-closings__closing__actions">
                                    <button class="btn-action btn-action--edit"
                                            @click="editClosing(date, 3)"
                                            v-bind:title="'general.edit' | trans">
                                    </button>
                                    <button class="btn-action btn-action--remove"
                                            @click="deleteClosing(date, 3)"
                                            v-bind:title="'general.delete' | trans">
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <portal to="modals">
                    <modal-add-closing-element
                        v-if="modal_open"
                        :days="days"
                        :id_selected="id_selected"
                        :type_selected="type_selected"
                        :date_selection="date_selection"
                        :turns_selection="turns_selection"
                        :restaurant_id="restaurant_id"
                        :locale="locale"
                        @close="closeModal"
                    ></modal-add-closing-element>
                    <confirmation-modal
                        v-if="delete_modal_open"
                        :url="delete_modal_url"
                        :title="'general.confirm_delete' | trans"
                        :text="'general.confirm_delete_p' | trans"
                        @close="closeDeleteModal"
                    ></confirmation-modal>
                </portal>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    import {days} from '../days';
    import {closeModal, openModal} from "../modal";

    export default {
        components: {
            Datepicker
        },
        data() {
            return {
                days: days[this.locale],
                loading: false,
                loading_calendar: true,
                loading_closing: true,
                current_month_show: new Date(),
                modal_open: false,
                delete_modal_open: false,
                delete_modal_url: '',
                turns: [],
                disabledDates: {
                    to: new Date(new Date().setDate(new Date().getDate() - 1)),
                    dates: [],
                },
                highlightedDates: {
                    dates: [],
                },
                closing: {
                    days: [],
                    ranges: [],
                    dates: []
                },
                lang: {
                    es: es,
                },
                type_selected: 1,
                id_selected: null,
                date_selection: null,
                turns_selection: [],
            };
        },
        props: ['restaurant_id', 'locale'],
        methods: {
            initialize() {
                this.loading = true;
                this.getCalendarDates(this.current_month_show);
                this.getClosingDates();
            },
            getCalendarDates(date) {
                if (!(date instanceof Date)) {
                    date = new Date(date.timestamp)
                }
                this.loading_calendar = true;
                this.current_month_show = date;
                var params = {
                    date: date
                };
                axios.get(window.config.context_prefix+'/api/restaurants/' + this.restaurant_id + '/calendar', {params: params})
                    .then(response => {
                        this.disabledDates.dates = [];
                        var that = this;
                        response.data.closing.forEach(date => {
                            that.disabledDates.dates.push(new Date(date));
                        });
                        response.data.bank_holidays.forEach(date => {
                            that.highlightedDates.dates.push(new Date(date));
                        });
                        this.refreshDisabled();
                    }).catch(error => {
                    console.log('Ooops');
                    this.refreshDisabled();
                });
            },
            getClosingDates() {
                axios.get(window.config.context_prefix+'/api/restaurant/future-closing')
                    .then(response => {
                        this.closing.days = response.data.days;
                        this.closing.ranges = [];
                        this.closing.dates = [];
                        this.loading_closing = false;
                        var that = this;
                        response.data.ranges.forEach(range => {
                            that.closing.ranges.push({
                                data: {
                                    from: new Date(range.data.from),
                                    to: new Date(range.data.to)
                                },
                                id: range.id,
                                turns: range.turns,
                                turns_text: range.turns_text,
                            });
                        });
                        response.data.dates.forEach(date => {
                            that.closing.dates.push({
                                data: new Date(date.data),
                                id: date.id,
                                turns: date.turns,
                                turns_text: date.turns_text,
                            });
                        })
                    }).catch(error => {
                    console.log('Ooops');
                    this.loading_closing = false;
                });
            },
            getTurns() {
                axios.get(window.config.context_prefix+'/api/restaurant/turns')
                    .then(response => {
                        this.turns = response.data.data;
                    }).catch(error => {
                    console.log('Ooops');
                });
            },
            refreshDisabled() {
                var disabled = this.disabledDates;
                var that = this;
                that.disabledDates = {
                    dates: [],
                };
                Vue.nextTick(() => {
                    that.disabledDates = disabled;
                    this.loading_calendar = false;
                });
            },
            calendarClick(date) {
                this.type_selected = 3;
                this.date_selection = date;
                this.turns_selection = [];
                this.openModal();
            },
            editClosing(closing_element, type) {
                this.type_selected = type;
                this.id_selected = closing_element.id;
                if (type === 2) {
                    this.date_selection = {
                        startDate: closing_element['data'].from,
                        endDate: closing_element['data'].to,
                    };
                } else {
                    this.date_selection = closing_element['data'];
                }
                this.turns_selection = closing_element['turns'];
                this.openModal();
            },
            deleteClosing(closing_element, type) {
                var url = null;
                if (type == 1) {
                    url = window.config.context_prefix+'/api/restaurant/closing-days/' + closing_element.id + '/delete';
                } else if (type == 2) {
                    url = window.config.context_prefix+'/api/restaurant/closing-ranges/' + closing_element.id + '/delete';
                } else if (type == 3) {
                    url = window.config.context_prefix+'/api/restaurant/closing-dates/' + closing_element.id + '/delete';
                }
                if (url) {
                    this.delete_modal_url = url;
                    this.delete_modal_open = true;
                    openModal();
                } else {
                    console.log('Ooops');
                }
            },
            openModalEmpty() {
                this.type_selected = 1;
                this.date_selection = null;
                this.turns_selection = [];
                this.openModal();
            },
            openModal() {
                this.modal_open = true;
                openModal();
            },
            closeModal(reload) {
                if (reload) {
                    this.initialize();
                }
                this.modal_open = false;
                closeModal();
            },
            closeDeleteModal(reload) {
                if (reload) {
                    this.initialize();
                }
                this.delete_modal_open = false;
                closeModal();
            }
        },
        mounted() {
            this.getTurns();
            this.initialize();
        },
        filters: {
            getDayName(value, days) {
                return _.find(days, day => {
                    return day.id == value;
                }).label;
            }
        }
    }
</script>


<template>
    <transition name="fade-shrink-in" appear>
        <div class="modal show" style="padding-right: 17px; display: block;" @click.prevent="close(reload)">
            <div class="modal-dialog modal-xl modal-dialog-centered" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 v-if="title" class="modal-title h4 heading-1" id="myLargeModalLabel">{{ title }}</h5>
                        <h5 v-else class="modal-title h4 heading-1" id="myLargeModalLabel">{{ 'general.new_reservation' | trans }}</h5>
                        <button type="button" class="close" @click.prevent="close(reload)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="vue_booking_form">
                            <div v-if="reservation_success" class="col-12 reservation_success">
                                <div class="box--shadowed text-brand">
                                    <h3 class="reservation_success__title pb-4">{{ 'general.booking_successfull' | trans }}</h3>
                                    <ul class="reservation_success__info">
                                        <li class="reservation_success__data">
                                            <label class="reservation_success__data_label">{{ 'general.restaurant_name' | trans }}:</label>
                                            <span class="reservation_success__data_value">{{ restaurant.name }}</span>
                                        </li>
                                        <li class="reservation_success__data">
                                            <label class="reservation_success__data_label">{{ 'general.booking_name' | trans }}:</label>
                                            <span class="reservation_success__data_value">{{ reservation.user_name }}</span>
                                        </li>
                                        <li class="reservation_success__data">
                                            <label class="reservation_success__data_label">{{ 'general.date' | trans }}:</label>
                                            <span class="reservation_success__data_value">{{ reservation.reservation_date | date }}</span>
                                        </li>
                                        <li class="reservation_success__data">
                                            <label class="reservation_success__data_label">{{ 'general.time' | trans }}:</label>
                                            <span class="reservation_success__data_value">{{ reservation.reservation_time }}</span>
                                        </li>
                                        <li class="reservation_success__data">
                                            <label class="reservation_success__data_label">{{ 'general.no_diners' | trans }}:</label>
                                            <span class="reservation_success__data_value">{{ reservation.no_people }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-else>
                                <div class="col-12 restaurant_booking__form">
                                    <div class="form-group form-group--name" v-bind:class="{'has-error' : errors.has('name')}">
                                        <label for="name">{{'general.name' | trans }}</label>
                                        <input v-model="reservation.user_name" v-validate="'required|max:255'" v-bind:data-vv-as="input.name[locale]" maxlength="255" type="text" class="form-control" id="name" name="name" :disabled="disable">
                                        <small v-if="errors.has('name')" class="text-danger">{{ errors.first('name') }}</small>
                                    </div>
                                    <div class="form-group form-group--phone" v-bind:class="{'has-error' : errors.has('phone')}">
                                        <label for="phone">{{'general.phone' | trans }}</label>
                                        <input v-model="reservation.user_phone" v-validate="'required|numeric|min:9|max:9'" v-bind:data-vv-as="input.phone[locale]" maxlength="255" type="text" class="form-control" id="phone" name="phone" :disabled="disable">
                                        <small v-if="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</small>
                                    </div>
                                    <div class="form-group form-group--no_people" v-bind:class="{'has-error' : errors.has('no_people')}">
                                        <label for="no_people">{{'general.no_people' | trans }}</label>
                                        <input v-model="reservation.no_people" v-validate="'required|numeric|min_value:1|max_value:30'" v-bind:data-vv-as="input.no_people[locale]" type="number" min="1" max="30" step="1" class="form-control" id="no_people" name="no_people">
                                        <small v-if="errors.has('no_people')" class="text-danger">{{ errors.first('no_people') }}</small>
                                    </div>
                                    <datepicker
                                        v-model="reservation_date"
                                        :inline="true"
                                        :disabled-dates="disabledDates"
                                        :highlighted="highlightedDates"
                                        :monday-first="true"
                                        :language="lang[locale]"
                                        name="reservation_date"
                                        @changedMonth="getAvailability"
                                        @selected="selectDate"
                                        v-validate="'required'"
                                        v-bind:data-vv-as="input.reservation_date[locale]"
                                    >
                                        <label slot="beforeCalendarHeader">
                                            {{'general.reservation_date' | trans}}
                                        </label>
                                    </datepicker>
                                    <small v-if="errors.has('reservation_date')" class="text-danger">{{ errors.first('reservation_date') }}</small>
                                    <div class="form-group form-group--reservation_time" v-bind:class="{'has-error' : errors.has('reservation_time')}">
                                        <label for="reservation_time">{{'general.reservation_time' | trans }}</label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i class="icon-clock"></i>
                                                </div>
                                            </div>
                                            <v-select
                                                v-model="reservation.reservation_time"
                                                :options="availableTimes"
                                                id="reservation_time"
                                                name="reservation_time"
                                                v-bind:disabled="!reservation_date"
                                                v-validate="'required'"
                                                v-bind:data-vv-as="input.hour[locale]"
                                            ></v-select>
                                        </div>
                                        <small v-if="errors.has('reservation_time')" class="text-danger">{{ errors.first('reservation_time') }}</small>
                                    </div>
                                </div>
                                <div class="col-12 restaurant_booking__submit">
                                    <button class="btn btn-brand btn-lg d-block mx-auto mt-4" @click.prevent="book"
                                            v-bind:disabled="loading">
                                        <i v-if="loading" class="spinner icon-spinner"></i>
                                        {{ 'general.book' | trans }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';

    export default {
        components: {
            Datepicker
        },
        data() {
            return {
                loading: false,
                reservation_success: false,
                reservation: {
                    restaurant_id: this.restaurant ? this.restaurant.id : null,
                    user_id: null,
                    user_name: null,
                    user_phone: null,
                    no_people: null,
                    reservation_time: '',
                    reservation_date: null,
                },
                reservation_date: null,
                restaurant: this.current_restaurant,
                disabledDates: {
                    to: new Date(),
                    dates: [],
                },
                highlightedDates: {
                    dates: [],
                },
                availableTimes: [],
                lang: {
                    es: es,
                },
                locale: window.config.locale,
                input: {
                    name: {
                        es: 'nombre',
                        eu: 'izena',
                    },
                    phone: {
                        es: 'teléfono',
                        eu: 'telefonoa',
                    },
                    no_people: {
                        es: 'nº comensales',
                        eu: 'mahaikide kopurua',
                    },
                    hour: {
                        es: 'hora reserva',
                        eu: 'erreserba ordua',
                    },
                    reservation_date: {
                        es: 'día de reserva',
                        eu: 'erreserba eguna',
                    },
                },
                reload: false,
                disable: false,
            };
        },
        props: ['current_restaurant', 'current_reservation', 'title', 'reservation_id'],
        methods: {
            close(reload) {
                this.$emit('close', reload);
            },
            getAvailability(date) {
                var params = {
                    date: date
                };
                axios.get(window.config.context_prefix+'/api/restaurants/' + this.restaurant.id + '/calendar', {params: params})
                    .then(response => {
                        this.disabledDates.days = response.data.days;
                        this.disabledDates.dates = [];
                        var that = this;
                        response.data.closing.forEach(date => {
                            that.disabledDates.dates.push(new Date(date));
                        });
                        response.data.bank_holidays.forEach(date => {
                            that.highlightedDates.dates.push(new Date(date));
                        });
                    });
            },
            selectDate(date) {
                this.reservation.reservation_time = "";
                this.getTurns(date);
            },
            getTurns(date) {
                var params = {
                    date: date
                };
                axios.get(window.config.context_prefix+'/api/restaurants/' + this.restaurant.id + '/available-times', {params: params})
                    .then(response => {
                        this.availableTimes = response.data.times;
                    });
            },
            book() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.loading = true;
                        this.reservation.reservation_date = this.reservation_date;
                        axios.post(window.config.context_prefix+'/api/restaurant/reservations/create', this.reservation)
                            .then(response => {
                                this.loading = false;
                                this.reservation_success = true;
                                this.reload = true;
                                EventBus.$emit('reload_calendar');
                            }).catch(error => {
                            this.loading = false;
                            console.log('Ooops');
                        })
                    }
                    if(!result){
                        console.log('Oops! Not validate');
                    }
                });
            },
            getReservation(reservation) {
                axios.get(window.config.context_prefix+'/api/restaurant/reservations/'+ reservation +'/getData')
                    .then(response => {
                        var date = new Date(response.data.reservation.date_datepicker);
                        this.reservation.no_people = response.data.reservation.no_people;
                        // this.reservation.reservation_date = response.data.reservation.date;
                        this.reservation_date = response.data.reservation.date;
                        this.reservation.reservation_time = response.data.reservation.reservation_time;
                        this.reservation.user_phone = response.data.reservation.user_phone;
                        this.reservation.user_name = response.data.reservation.user_name;
                        this.reservation.id = response.data.reservation.id;
                        this.restaurant = response.data.restaurant;
                        this.reservation.restaurant_id = response.data.restaurant.id;
                        this.reservation.user_id = response.data.reservation.user_id;
                        if(this.reservation.user_id !== null){
                            this.disable = true;
                        }
                        this.getAvailability(date);
                        this.getTurns(date);
                    }).catch(error => {
                    this.loading = false;
                    console.log('Ooops');
                })
            },
        },
        mounted() {
            if(this.current_reservation !== undefined){
                var date = new Date(this.current_reservation.date_datepicker);
                this.reservation.no_people = this.current_reservation.no_people;
                // this.reservation.reservation_date = this.current_reservation.date;
                this.reservation_date = this.current_reservation.date;
                this.reservation.reservation_time = this.current_reservation.reservation_time;
                this.reservation.id = this.current_reservation.id;
                this.getAvailability(date);
                this.getTurns(date);
            } else {
                if(this.reservation_id !== undefined) {
                    this.getReservation(this.reservation_id);
                } else {
                    this.reservation.restaurant_id = this.current_restaurant.id;
                    this.getAvailability(new Date());
                }
            }
        },
    }
</script>

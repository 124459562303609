var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-filters w-100" }, [
    _c("div", { staticClass: "col-12 mb-5" }, [
      _c(
        "div",
        { staticClass: "row mx-0 box--shadowed" },
        [
          _vm._l(_vm.filters, function(field) {
            return _c(
              "div",
              { class: ["col-12 form-group", field.container_class] },
              [
                _c(
                  "label",
                  { staticClass: "col-form-label", attrs: { for: field.name } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("trans")(field.title)) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                field.type == "text"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data_filters[field.name],
                          expression: "data_filters[field.name]"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: field.name },
                      domProps: { value: _vm.data_filters[field.name] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data_filters,
                            field.name,
                            $event.target.value
                          )
                        }
                      }
                    })
                  : field.type == "number"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data_filters[field.name],
                          expression: "data_filters[field.name]"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        step: "1",
                        min: "0",
                        pattern: "^\\d*(\\.\\d{0,2})?$",
                        id: field.name
                      },
                      domProps: { value: _vm.data_filters[field.name] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data_filters,
                            field.name,
                            $event.target.value
                          )
                        }
                      }
                    })
                  : field.type == "select"
                  ? _c("v-select", {
                      attrs: {
                        options: _vm._f("prepare_select")(
                          field.options,
                          "name"
                        ),
                        reduce: function(type) {
                          return type.id
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selected-option",
                            fn: function(option) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vs__selected_tag turn_tag",
                                    class: "turn_tag--" + option.id
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "vs__selected_tag__label"
                                      },
                                      [_vm._v(_vm._s(option.label))]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.data_filters[field.name],
                        callback: function($$v) {
                          _vm.$set(_vm.data_filters, field.name, $$v)
                        },
                        expression: "data_filters[field.name]"
                      }
                    })
                  : field.type == "multiselect"
                  ? _c("v-select", {
                      attrs: {
                        multiple: "",
                        options: _vm._f("prepare_select")(
                          field.options,
                          "name"
                        ),
                        reduce: function(type) {
                          return type.id
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selected-option",
                            fn: function(option) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vs__selected_tag turn_tag",
                                    class: "turn_tag--" + option.id
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "vs__selected_tag__label"
                                      },
                                      [_vm._v(_vm._s(option.label))]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.data_filters[field.name],
                        callback: function($$v) {
                          _vm.$set(_vm.data_filters, field.name, $$v)
                        },
                        expression: "data_filters[field.name]"
                      }
                    })
                  : field.type == "daterange"
                  ? _c("date-range-picker", {
                      ref: "picker",
                      refInFor: true,
                      attrs: {
                        "locale-data": { firstDay: 1, format: "DD-MM-YYYY" },
                        "min-date": _vm.min_date,
                        "auto-apply": false,
                        ranges: false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "input",
                            fn: function(picker) {
                              return _c("div", {}, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm._f("date")(picker.startDate)) +
                                    " - " +
                                    _vm._s(_vm._f("date")(picker.endDate)) +
                                    "\n                    "
                                )
                              ])
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.data_filters[field.name],
                        callback: function($$v) {
                          _vm.$set(_vm.data_filters, field.name, $$v)
                        },
                        expression: "data_filters[field.name]"
                      }
                    })
                  : field.type == "timerange"
                  ? _c(
                      "div",
                      [
                        _c("vue-timepicker", {
                          attrs: {
                            format: "HH:mm",
                            "minute-interval": 15,
                            "close-on-complete": ""
                          },
                          model: {
                            value: _vm.data_filters[field.name]["from"],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data_filters[field.name],
                                "from",
                                $$v
                              )
                            },
                            expression: "data_filters[field.name]['from']"
                          }
                        }),
                        _vm._v("\n                    -\n                    "),
                        _c("vue-timepicker", {
                          attrs: {
                            format: "HH:mm",
                            "minute-interval": 15,
                            "close-on-complete": ""
                          },
                          model: {
                            value: _vm.data_filters[field.name]["to"],
                            callback: function($$v) {
                              _vm.$set(_vm.data_filters[field.name], "to", $$v)
                            },
                            expression: "data_filters[field.name]['to']"
                          }
                        })
                      ],
                      1
                    )
                  : field.type == "datepicker"
                  ? _c("datepicker", {
                      ref: "myDatepicker",
                      refInFor: true,
                      staticClass: "form-control",
                      attrs: {
                        "monday-first": true,
                        language: _vm.lang[_vm.locale]
                      },
                      on: {
                        input: function($event) {
                          return _vm.changeHandler($event)
                        }
                      },
                      model: {
                        value: _vm.data_filters[field.name],
                        callback: function($$v) {
                          _vm.$set(_vm.data_filters, field.name, $$v)
                        },
                        expression: "data_filters[field.name]"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-grey-outline",
                attrs: { type: "button", name: "clear" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearFilters($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("trans")("general.clear")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-brand ml-2",
                attrs: { type: "button", name: "search" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.applyFilters($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("trans")("general.search")))]
            )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-turns">
        <div class="mb-4 box--shadowed vue-turns__info">
            <h3 class="d-flex justify-content-between align-items-center heading-2 text-brand">
                <span>{{ 'general.info' | trans }}</span>
                <i class="icon-info"></i>
            </h3>
            <p>
                {{ 'general.turn_list_info' | trans }}
            </p>
        </div>
        <div class="box--shadowed">
            <div class="d-flex justify-content-between align-items-center vue-turns__header">
                <h4 class="heading-2 text-brand vue-turns__title">
                    {{ 'general.add_turn' | trans }}
                </h4>
                <button class="btn-action btn-action--add" @click="addTurn" v-bind:disabled="loading"></button>
            </div>
            <div class="mt-3">
                <div class="box--shadowed bg-danger text-white mb-4" id="alert-error" style="display:none">
                    {{'general.error_deleting_turn' | trans}}
                </div>
            </div>
            <div class="row mx-0 box--shadowed vue-turns__turn" v-for="(turn, index) in turns">
                <div class="col-12 col-md-4 vue-turns__turn__name" v-bind:class="{'has-error' : errors.has('name_'+index)}">
                    <input type="text" class="form-control" :name="'name_'+index" v-model="turn.name" v-validate="'required|min:3|max:255'" v-bind:data-vv-as="input.turn[locale]">
                    <small v-if="errors.has('name_'+index)" class="text-danger">{{ errors.first('name_'+index) }}</small>
                </div>
                <div class="col-12 col-md-3 vue-turns__turn__frequencies" v-bind:class="{'has-error' : errors.has('frequencies_'+index)}">
                    <v-select
                        v-validate="'required'"
                        v-bind:data-vv-as="input.search[locale]"
                        v-model="turn.frequencies"
                        :options="frequencies | prepare_select('name')"
                        :reduce="option => option.id"
                        multiple
                        :name="'frequencies_'+index"
                    >
                        <template slot="selected-option" slot-scope="option">
                            <span class="vs__selected_tag turn_tag" :class="'turn_tag--'+option.id">
                                <span class="vs__selected_tag__label">{{option.label}}</span>
                            </span>
                        </template>
                    </v-select>
                    <small v-if="errors.has('frequencies_'+index)" class="text-danger">{{ errors.first('frequencies_'+index) }}</small>
                </div>
                <div class="col-12 col-md-2 vue-turns__turn__start" v-bind:class="{'has-error' : errors.has('since_'+index)}">
                    <vue-timepicker
                        format="HH:mm"
                        :minute-interval="15"
                        close-on-complete
                        hide-clear-button
                        v-model="turn.start"
                        v-validate="'required'"
                        v-bind:data-vv-as="input.data_since[locale]"
                        :name="'since_'+index"
                        @change="changeHandler($event, index)"
                    >
                    </vue-timepicker>
                    <small v-if="errors.has('since_'+index)" class="text-danger">{{ errors.first('since_'+index) }}</small>
                </div>
                <div class="col-12 col-md-2 vue-turns__turn__end" v-bind:class="{'has-error' : errors.has('until_'+index)}">
                    <vue-timepicker
                        format="HH:mm"
                        :minute-interval="15"
                        close-on-complete
                        hide-clear-button
                        v-model="turn.end"
                        v-validate="'required|date_format:HH:mm|date_between:'+turn.start+',23:50'"
                        v-bind:data-vv-as="input.data_until[locale]"
                        :name="'until_'+index"
                        @change="changeHandlerEnd($event, index)"
                    >
                    </vue-timepicker>
                    <small v-if="errors.has('until_'+index)" class="text-danger">{{ errors.first('until_'+index) }}</small>
                </div>
                <div class="col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions">
                    <button class="btn-action btn-action--remove" @click="removeTurn(index)" v-bind:disabled="loading"></button>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4 vue-turns__submit">
            <button class="btn btn-brand-outline" type="button" @click="save" v-bind:disabled="loading">
                <i v-if="loading" class="icon icon-spinner spinner"></i>
                {{ 'general.save' | trans }}
            </button>
        </div>
    </div>
</template>

<script>
    import VueTimepicker from 'vue2-timepicker';

    export default {
        components: {VueTimepicker},
        data() {
            return {
                frequencies: [],
                turns: [],
                loading: true,
                input: {
                    turn: {
                        es: 'nombre del turno',
                        eu: 'txandaren izena',
                    },
                    search: {
                        es: 'turno',
                        eu: 'txanda',
                    },
                    data_since: {
                        es: 'desde',
                        eu: 'hemendik',
                    },
                    data_until: {
                        es: 'hasta',
                        eu: 'hona',
                    },
                },
            };
        },
        props: ['restaurant_id', 'locale'],
        methods: {
            changeHandler (eventData, index,) {
                this.turns[index].start = eventData.displayTime;
            },
            changeHandlerEnd (eventData, index,) {
                this.turns[index].end = eventData.displayTime;
            },
            getFrequencies() {
                axios.get(window.config.context_prefix+'/api/frequencies')
                    .then(response => {
                        this.frequencies = response.data.data;
                    });
            },
            getTurns() {
                this.loading = true;
                axios.get(window.config.context_prefix+'/api/restaurant/turns')
                    .then(response => {
                        this.turns = response.data.data;
                        this.loading = false;
                    }).catch(error => {
                    this.loading = false;
                    console.log('Ooops...')
                    });
            },
            addTurn() {
                this.turns.push({});
            },
            removeTurn(index) {
                this.turns.splice(index, 1);
            },
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $('#alert-error').hide();
                        this.loading = true;
                        axios.post(window.config.context_prefix+'/api/restaurant/turns', this.turns)
                            .then(response => {
                                this.loading = false;
                                if(response.status === 206){
                                    //AVISO DE ERROR:
                                    $('#alert-error').show();
                                }
                            }).catch(error => {
                            this.loading = false;
                            console.log('Ooops...')
                        });
                    }
                    if(!result){
                        console.log('Oops!');
                    }
                });

            },
        },
        mounted() {
            this.getFrequencies();
            this.getTurns();
        },
        watch: {

        }
    }
</script>

<template>
    <div class="vue-input-multi-lang">
        <label :for="fieldData.name + '_' + this.lang" class="input-multi-lang__label"> {{fieldData.label | trans }} -
            {{this.lang}} </label>
        <btn-switch-lang v-on:changeLang="toggleLang"></btn-switch-lang>
        <div v-for="(lang, index) in langs"
             :key="index"
             :lang="lang"
             :class="{'has-error' : errors.has(fieldData.name+'_'+lang)}"
             class="input-multi-lang__input">

            <VueTrix v-if="fieldData.input_type == 'textarea'"
                     v-validate="fieldData.validateRules"
                     v-bind:data-vv-as="fieldInput"
                     v-model="fieldModel[lang]"
                     :id="fieldData.name+'_'+lang"
                     :name="fieldData.name+'_'+lang"
                     type="text" @trix-file-accept="uploadImage"></VueTrix>

            <input v-else
                   v-validate="fieldData.validateRules"
                   v-bind:data-vv-as="fieldInput"
                   v-model="fieldModel[lang]"
                   :id="fieldData.name+'_'+lang"
                   :name="fieldData.name+'_'+lang"
                   type="text" class="form-control">

            <small v-if="errors.has(fieldData.name+'_'+lang)" class="text-danger">
                {{ errors.first(fieldData.name+'_'+lang) }}
            </small>
        </div>

    </div>
</template>
<script>
    import BtnSwitchLang from './../BtnSwitchLang.vue';

    export default {
        components: {
            BtnSwitchLang
        },
        props: [
            'langs',
            'locale',
            'input',
            'menu',
            'fieldData'
        ],
        data: function () {
            return {
                lang: 'es',
                fieldInput: this.hasInput(),
                fieldModel: {
                    'es': null,
                    'eu': null,
                },
            }
        },
        methods: {
            toggleLang(value) {
                // console.log("value: " +value);
                this.lang = value;
            },
            hasInput() {
                if (this.input) {
                    // console.log("Has input: ", this.input[this.fieldData.name], this.input[this.fieldData.name][this.locale])
                    return this.input[this.fieldData.name + '_' + this.locale];
                } else {
                    return '';
                }
            },
            uploadImage($event) {
                $event.preventDefault();
            },
        },
        watch: {
            fieldModel: {
                deep: true,
                handler(value) {
                    // console.log("Emit ", value);
                    this.$emit('change', value, this.fieldData.model);
                }
            },
        },
        mounted() {
            $('.trix-button-group--file-tools').hide();
            Vue.nextTick(() => {
                if (this.menu[this.fieldData.name] !== undefined) {
                    // console.log(this.menu[this.fieldData.name]);
                    this.fieldModel = this.menu[this.fieldData.name];
                }
                if (this.fieldData.model.length === 3) {
                    this.fieldModel = this.menu[this.fieldData.model[0]][this.fieldData.model[1]][this.fieldData.model[2]];
                }
                if (this.fieldData.model.length === 5) {
                    this.fieldModel = this.menu[this.fieldData.model[0]][this.fieldData.model[1]][this.fieldData.model[2]][this.fieldData.model[3]][this.fieldData.model[4]]
                }
            });
        }
    }
</script>


/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

var Lang = require('lang.js');
import translations from './vue-translations.js';
import VeeValidate from "vee-validate";

import validationMessagesEU from 'vee-validate/dist/locale/eu';
import validationMessagesES from 'vee-validate/dist/locale/es';
import vSelect from 'vue-select'
import Paginate from 'vuejs-paginate';
import PortalVue from 'portal-vue';
import UploadImage from 'vue-upload-image';
import './filters';
import VueClipboard from 'vue-clipboard2';
import VTooltip from 'v-tooltip';

Vue.prototype.$http = window.axios;

var lang = new Lang();
window.config = JSON.parse(document.getElementById('config').innerHTML);
lang.setLocale(config.locale);
lang.setMessages(translations);

window.axios.defaults.headers.common['Authorization'] = window.config.token;
//window.axios.defaults.baseURL = 'https://www.donostia.eus/ingurumena/xahuketarikgabe';
Vue.use(VeeValidate, {
    locale: lang.locale,
    dictionary: {
        eu: {messages: validationMessagesEU.messages},
        es: {messages: validationMessagesES.messages},
    },
    useConstraintAttrs: false
});

Vue.use(VTooltip, {
    popover: {
        defaultBaseClass: 'popover',
    }
});
Vue.use(VueClipboard);
Vue.use(PortalVue);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('paginate', Paginate);
Vue.component('upload-image', UploadImage);
Vue.component('v-select', vSelect);
Vue.component('search-input', require('./components/SearchInput').default);
Vue.component('checkbox-filter', require('./components/CheckboxFilters').default);
Vue.component('restaurant-list', require('./components/RestaurantListComponent.vue').default);
Vue.component('datatable', require('./components/Datatable.vue').default);
Vue.component('datatable-header', require('./components/DatatableHeader.vue').default);
Vue.component('datatable-body', require('./components/DatatableBody.vue').default);
Vue.component('datatable-row', require('./components/DatatableRow.vue').default);
Vue.component('datatable-actions', require('./components/DatatableActions.vue').default);
Vue.component('datatable-buttons', require('./components/DatatableButtons.vue').default);
Vue.component('datatable-search', require('./components/DatatableSearch.vue').default);
Vue.component('datatable-stats', require('./components/DatatableStats.vue').default);
Vue.component('filters-component', require('./components/FiltersComponent.vue').default);
Vue.component('new-menu-component', require('./components/NewMenuComponent.vue').default);
Vue.component('booking-form', require('./components/BookingForm').default);
Vue.component('guest-booking-form', require('./components/GuestBookingForm').default);
Vue.component('restaurant-booking-form-modal', require('./components/RestaurantBookingFormModal').default);
Vue.component('closing-dates', require('./components/ClosingDatesSelector').default);
Vue.component('modal-add-closing-element', require('./components/ModalAddClosingElement').default);
Vue.component('restaurant-update-component', require('./components/RestaurantUpdateComponent').default);
Vue.component('restaurant-admin-component', require('./components/RestaurantAdminComponent').default);
Vue.component('turns-list', require('./components/TurnsList').default);
Vue.component('btn-switch-lang', require('./components/BtnSwitchLang').default);
Vue.component('input-multi-lang', require('./components/inputs/InputMultiLang').default);
Vue.component('input-price', require('./components/inputs/InputPrice').default);
Vue.component('confirmation-modal', require('./components/ConfirmationModal').default);
Vue.component('restaurant-gallery', require('./components/Gallery').default);
Vue.component('menu-calendar', require('./components/MenuCalendar').default);
Vue.component('modal-add-menu-calendar', require('./components/ModalAddMenuCalendar').default);
Vue.component('modal-edit-menu-calendar', require('./components/ModalEditMenuCalendar').default);
Vue.component('client-menu-selector', require('./components/ClientMenuSelector').default);
Vue.component('category-list', require('./components/CategoryList').default);
Vue.component('category-list-admin', require('./components/CategoryListAdmin').default);
Vue.component('dummy-multilang', require('./components/inputs/DummyMultilang').default);
Vue.component('reservations-calendar', require('./components/ReservationsCalendar').default);
Vue.component('reservations-datatable-modal', require('./components/ReservationsDatatableModal').default);
Vue.component('show-menu-component', require('./components/ShowMenuComponent').default);
Vue.component('tags-list', require('./components/TagsList').default);
Vue.component('food-type-list', require('./components/FoodTypeList').default);
Vue.component('calendar-holidays-list', require('./components/CalendarHolidaysList').default);
// Vue.component('modal-tooltip', require('./components/ModalTooltip').default); //NON USED


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.EventBus = new Vue();

const app = new Vue({
    el: '#app',
    data() {
        return {
            loading: true,
            restaurant_count: 0,
            restaurant_filters: {
                search: '',
                food_types: [],
                price_ranges: [],
            },
            menu_calendar_filters: {},
            active_view: 'calendar',
            modal_open: false,
        };
    },
    methods: {
        applyFilters(name, selected) {
            this.restaurant_filters[name] = selected;
        },
        update_restaurant_count(count) {
            this.restaurant_count = count;
            this.loading = false;
            Vue.nextTick(function () {
                $('#count_rest').show();
            });
        },
        applyMenuCalendarFilters(name, selected) {
            this.menu_calendar_filters[name] = selected;
        },
        toggleView(view) {
            this.active_view = view;
        },
        openReservationModal(){
            this.modal_open = true;
        },
        closeReservationModal() {
            this.modal_open = false;
        },
        reloadModule() {
            this.$refs.active.getData();
        },
    }
});

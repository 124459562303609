<template>
    <form @submit.prevent="performSearch" class="vue_search_input search__form">
        <input type="text" v-model="search" class="search__input">
        <button type="submit" class="search__submit">{{ 'general.search' | trans }}</button>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                search: '',
            };
        },
        methods: {
            performSearch() {
                this.$emit('filter', 'search', this.search);
            }
        }
    }
</script>

<template>
    <transition name="fade-shrink-in" appear>
        <div class="modal show" style="padding-right: 17px; display: block;"
             @click.prevent="close(false)">
            <div class="modal-dialog modal-lg modal-dialog-centered" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="myLargeModalLabel">{{ 'general.add_closing' | trans }}</h5>
                        <button type="button" class="close" @click.prevent="close(false)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-sm-12">
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        id="periodic_close"
                                        name="type"
                                        type="radio"
                                        v-model="type"
                                        value="1"
                                        @change="changeType"
                                    >
                                    <label class="form-check-label" for="periodic_close">
                                        {{ 'general.periodic_close' | trans }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        id="range_close"
                                        name="type"
                                        type="radio"
                                        v-model="type"
                                        value="2"
                                        @change="changeType"
                                    >
                                    <label class="form-check-label" for="range_close">
                                        {{ 'general.range_close' | trans }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        id="punctual_close"
                                        name="type"
                                        type="radio"
                                        v-model="type"
                                        value="3"
                                        @change="changeType"
                                    >
                                    <label class="form-check-label" for="punctual_close">
                                        {{ 'general.punctual_close' | trans }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="type == 1">
                            <div class="form-group col-sm-6">
                                <v-select
                                    v-model="selection"
                                    :options="days"
                                    :reduce="day => day.id"
                                    @input="selectDay"
                                ></v-select>
                            </div>
                            <div class="col-sm-6">
                                <v-select
                                    v-model="selection_turn"
                                    :placeholder="select_placeholder | trans"
                                    :options="turns | prepare_select('name')"
                                    :reduce="turn => turn.id"
                                    v-bind:disabled="turns_disabled"
                                    multiple
                                ></v-select>
                            </div>
                        </div>
                        <div class="row" v-else-if="type == 2">
                            <div class="form-group col-sm-6">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                                    v-model="selection"
                                    :min-date="min_date"
                                    :auto-apply="auto_apply"
                                    :ranges="false"
                                    @update="selectRange"
                                >
                                    <div slot="input" slot-scope="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </div>
                                </date-range-picker>
                            </div>
                            <div class="form-group col-sm-6">
                                <v-select
                                    v-model="selection_turn"
                                    :placeholder="select_placeholder | trans"
                                    :options="turns | prepare_select('name')"
                                    :reduce="turn => turn.id"
                                    multiple
                                ></v-select>
                            </div>
                        </div>
                        <div class="row" v-else-if="type == 3">
                            <div class="form-group col-sm-6">
                                <datepicker
                                    :monday-first="true"
                                    v-model="selection"
                                    :language="lang[locale]"
                                    :disabled-dates="{to: new Date()}"
                                    input-class="form-control calendar-input"
                                    @selected="selectDate"
                                ></datepicker>
                            </div>
                            <div class="col-sm-6">
                                <v-select
                                    v-model="selection_turn"
                                    :placeholder="select_placeholder | trans"
                                    :options="turns | prepare_select('name')"
                                    :reduce="turn => turn.id"
                                    v-bind:disabled="turns_disabled"
                                    multiple
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close(false)">
                            {{ 'general.close' | trans }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-brand"
                            @click.prevent="save"
                            v-bind:disabled="save_disabled || save_loading"
                        >
                            <i v-if="save_loading" class="spinner icon-spinner"></i>
                            {{ 'general.save' | trans }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';

    export default {
        components: {
            Datepicker,
            DateRangePicker
        },
        data() {
            return {
                id: this.id_selected,
                type: this.type_selected,
                selection: this.date_selection,
                selection_turn: this.turns_selection,
                turns: [],
                turns_disabled: true,
                select_placeholder: "general.all_turns",
                min_date: new Date(),
                auto_apply: true,
                lang: {
                    es: es,
                },
                save_disabled: true,
                save_loading: false,
            };
        },
        props: {
            days: {
                required: true,
            },
            id_selected: {
                default: null,
            },
            type_selected: {
                type: Number,
                default: 1,
            },
            date_selection: {
                required: false
            },
            turns_selection: {
                type: Array,
                required: false,
            },
            restaurant_id: {
                required: true,
            },
            locale: {
                type: String,
                required: true
            }
        },
        methods: {
            close(reload) {
                this.$emit('close', reload);
            },
            changeType() {
                this.id = null;
                if (this.type == 2) {
                    this.selection = {
                        startDate: null,
                        endDate: null
                    };
                } else {
                    this.selection = null;
                }
                this.turns = [];
                this.selection_turn = [];
                this.save_disabled = true;
            },
            getTurns(params) {
                this.save_disabled = false;
                this.turns_disabled = true;
                axios.get(window.config.context_prefix+'/api/restaurant/turns-for-date', {params: params})
                    .then(response => {
                        this.turns = response.data;
                        this.turns_disabled = false;
                    });
            },
            selectDay(ignore_turns) {
                if (ignore_turns !== true) {
                    this.selection_turn = [];
                }
                var params = {day: this.selection};
                this.getTurns(params);
            },
            selectRange(ignore_turns) {
                if (ignore_turns !== true) {
                    this.selection_turn = [];
                }
                var params = {};
                this.getTurns(params);
            },
            selectDate(date, ignore_turns) {
                if (ignore_turns !== true) {
                    this.selection_turn = [];
                }
                this.selection = date;
                var params = {date: this.selection};
                this.getTurns(params);
            },
            save() {
                this.save_loading = true;
                var params = {
                    id: this.id,
                    type: this.type,
                    data: this.selection,
                    turns: this.selection_turn,
                };
                axios.post(window.config.context_prefix+'/api/restaurant/closing-elements/save', params)
                    .then(response => {
                        this.close(true);
                    }).catch(error => {
                    console.log('Ooops');
                    this.close(false);
                });
            }
        },
        mounted() {
            if (this.type == 1 && this.selection) {
                this.selectDay(true);
            } else if (this.type == 2 && this.selection) {
                this.selectRange(true);
            } else if (this.type == 3 && this.selection) {
                this.selectDate(this.selection, true);
            }
        }
    }
</script>

<style scoped>
    .vue-daterange-picker {
        display: block;
    }
</style>

<style>
    .modal {
        display: block;
        background: rgba(0, 0, 0, .5);
        z-index: 9999;
    }

    .fade-shrink-in-enter-active,
    .fade-shrink-in-leave-active {
        transition: all .25s ease;
    }

    .fade-shrink-in-enter,
    .fade-shrink-in-leave-to {
        opacity: 0;
        transform: scale(1.1);
    }

    .form-control.calendar-input {
        background-color: white !important;
    }
</style>

var days = {
    es: [
        {label: 'Lunes', id: 1},
        {label: 'Martes', id: 2},
        {label: 'Miércoles', id: 3},
        {label: 'Jueves', id: 4},
        {label: 'Viernes', id: 5},
        {label: 'Sábado', id: 6},
        {label: 'Domingo', id: 0},
    ],
    eu: [
        {label: 'Astelehena', id: 1},
        {label: 'Asteartea', id: 2},
        {label: 'Asteazkena', id: 3},
        {label: 'Osteguna', id: 4},
        {label: 'Ostirala', id: 5},
        {label: 'Larunbata', id: 6},
        {label: 'Igandea', id: 0},
    ],
};

export {days};

<template>
    <section class="container vue_restaurant_list">
        <div v-if="loading" class="row">
            {{ 'general.loading' | trans }}
        </div>
        <div v-else-if="restaurants.data.length > 0" class="row">
            <article v-for="(restaurant, index) in restaurants.data" class="restaurant_list__item">
                <a :href="route_restaurant(restaurant.id)" class="restaurant_list__item__link" title="Reservar">
                    <figure class="restaurant_list__item__image"
                            v-bind:style="'background-image:url(' + restaurant.featured_image + ')'">
                    </figure>
                </a>
                <div class="restaurant_list__item__info">
                    <a :href="route_restaurant(restaurant.id)" class="restaurant_list__item__link" title="Reservar">
                        <h3 class="restaurant_list__item__name">{{ restaurant.name | truncate(30, '...') }}</h3>
                    </a>
                    <div class="restaurant_list__item__additional_info">
                        <span class="restaurant_list__item__price">
                            {{ restaurant.price_symbol }}
                        </span>
                    </div>
                    <p class="restaurant_list__item__description">
                        <span v-html="$options.filters.truncate(restaurant.description, 100, '...')" v-if="(restaurant.description)">
                        </span>
                    </p>
                </div>
                <a :href="route_restaurant(restaurant.id)" class="restaurant_list__item__link" title="Reservar">
                    <span class="restaurant_list__item__link__anchor">{{ 'general.book' | trans }}</span>
                </a>
            </article>
            <button v-if="restaurants.meta.last_page > restaurants.meta.current_page" class="btn btn-primary"
                    @click="getRestaurants(restaurants.meta.current_page + 1)">
                {{ "general.load_more" | trans }}
            </button>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                restaurants: {
                    data: [],
                    links: {},
                    meta: {
                        current_page: 0,
                        last_page: 0,
                    },
                },
                loading: true
            };
        },
        props: ['filters', 'route_show'],
        watch: {
            filters: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    this.getRestaurants(1);
                }
            }
        },
        methods: {
            getRestaurants(page) {
                this.loading = true;
                var params = this.filters;
                params['page'] = page;
                axios.get(window.config.context_prefix+'/api/restaurants', {params: params})
                    .then(response => {
                        this.restaurants.links = response.data.links;
                        this.restaurants.meta = response.data.meta;
                        if (this.restaurants.meta.current_page === 1) {
                            this.restaurants.data = response.data.data;
                        } else {
                            var that = this;
                            response.data.data.forEach(restaurant => {
                                that.restaurants.data.push(restaurant);
                            });
                        }
                        this.$emit('restaurants_loaded', response.data.meta.total);
                        this.loading = false;
                    }).catch(error => {
                    this.loading = false;
                });
            },
            route_restaurant: function(restaurant_id){
                return this.route_show+'/'+restaurant_id;
            }
        }
    }
</script>

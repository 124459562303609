var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-shrink-in", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal show",
        staticStyle: { "padding-right": "17px", display: "block" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.close(false)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-xl modal-dialog-centered",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title h4",
                    attrs: { id: "myLargeModalLabel" }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("trans")("general.reservations_details")
                        ) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm._f("trans")("general.date")))
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.display_date))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm._f("trans")("general.turn")))
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.display_turn))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", {
                          staticClass: "d-none d-sm-block",
                          staticStyle: { "margin-bottom": "27px" }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check form-check-inline" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_complete,
                                  expression: "is_complete"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                id: "complete_cb",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.is_complete)
                                  ? _vm._i(_vm.is_complete, "1") > -1
                                  : _vm.is_complete
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.is_complete,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.is_complete = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.is_complete = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.is_complete = $$c
                                    }
                                  },
                                  function($event) {
                                    $event.preventDefault()
                                    return _vm.openConfirmationModal($event)
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "complete_cb" }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm._f("trans")("general.full"))
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("hr", {
                    staticClass: "mb-5",
                    staticStyle: { margin: "10px -13px" }
                  }),
                  _vm._v(" "),
                  _c("datatable", {
                    attrs: {
                      url: "/api/restaurant/reservations",
                      prop_filters: _vm.filters
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("trans")("general.cancel")) +
                        "\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "portal",
          { attrs: { to: "datatable-modals" } },
          [
            _vm.confirmation_modal_open
              ? _c("confirmation-modal", {
                  attrs: {
                    url: _vm.confirmation_modal_url,
                    title: _vm._f("trans")(_vm.title),
                    text: _vm._f("trans")(_vm.text),
                    level: _vm.level,
                    params: _vm.params
                  },
                  on: { close: _vm.closeConfrimationModal }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-filters w-100">
        <div class="col-12 mb-5">
            <div class="row mx-0 box--shadowed">
                <div v-for="field in filters" v-bind:class="['col-12 form-group', field.container_class]">

                    <label :for="field.name" class="col-form-label">
                        {{ field.title | trans }}
                    </label>

                    <input v-if="field.type == 'text'" type="text"
                        v-model="data_filters[field.name]"
                        class="form-control"
                        :id="field.name">

                    <input v-else-if="field.type == 'number'" type="number" step="1" min="0" pattern="^\d*(\.\d{0,2})?$"
                           v-model="data_filters[field.name]"
                           class="form-control"
                           :id="field.name">

                    <v-select v-else-if="field.type == 'select'"
                              v-model="data_filters[field.name]"
                              :options="field.options | prepare_select('name')"
                              :reduce="type => type.id">
                        <template slot="selected-option" slot-scope="option">
                            <span class="vs__selected_tag turn_tag" :class="'turn_tag--'+option.id">
                                <span class="vs__selected_tag__label">{{option.label}}</span>
                            </span>
                        </template>
                    </v-select>

                    <v-select v-else-if="field.type == 'multiselect'"
                              v-model="data_filters[field.name]"
                              multiple
                              :options="field.options | prepare_select('name')"
                              :reduce="type => type.id">
                        <template slot="selected-option" slot-scope="option">
                            <span class="vs__selected_tag turn_tag" :class="'turn_tag--'+option.id">
                                <span class="vs__selected_tag__label">{{option.label}}</span>
                            </span>
                        </template>
                    </v-select>

                    <date-range-picker
                        v-else-if="field.type == 'daterange'"
                        ref="picker"
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                        v-model="data_filters[field.name]"
                        :min-date="min_date"
                        :auto-apply="false"
                        :ranges="false"
                    >
                        <div slot="input" slot-scope="picker">
                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                    </date-range-picker>

                    <div v-else-if="field.type == 'timerange'">
                        <vue-timepicker
                            format="HH:mm"
                            :minute-interval="15"
                            close-on-complete
                            v-model="data_filters[field.name]['from']">
                        </vue-timepicker>
                        -
                        <vue-timepicker
                            format="HH:mm"
                            :minute-interval="15"
                            close-on-complete
                            v-model="data_filters[field.name]['to']">
                        </vue-timepicker>
                    </div>

                    <datepicker
                        ref="myDatepicker"
                        class="form-control"
                        v-else-if="field.type == 'datepicker'"
                        :monday-first="true"
                        :language="lang[locale]"
                        v-model="data_filters[field.name]"
                        @input="changeHandler($event)"
                    ></datepicker>

                </div>
                <div class="col-12 d-flex justify-content-end">
                    <button type="button" class="btn btn-grey-outline" name="clear" @click.prevent="clearFilters">{{ 'general.clear' | trans }}</button>
                    <button type="button" class="btn btn-brand ml-2" name="search" @click.prevent="applyFilters">{{ 'general.search' | trans }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    import VueTimepicker from 'vue2-timepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';

    export default {
        components: {
            DateRangePicker,
            VueTimepicker,
            Datepicker
        },
        data() {
            return {
                data_filters: {
                    daterange: {startDate: null, endDate: null},
                    timerange: {from: null, to: null},
                    turns: [],
                },
                min_date: new Date(),
                lang: {
                    es: es,
                },
            };
        },
        props: ['filters', 'locale'],
        methods: {
            initFilters() {
                this.filters.forEach(filter => {
                    if (filter.type == 'number' || filter.type == 'select' || filter.type == 'datepicker') {
                        this.data_filters[filter.name] = filter.value ? filter.value : null;
                    } else if (filter.type == 'daterange') {
                        this.data_filters[filter.name] = {
                            startDate: null,
                            endDate: null,
                        }
                    } else if (filter.type == 'timerange') {
                        this.data_filters[filter.name] = {
                            from: null,
                            to: null,
                        }
                    } else if (filter.type == 'multiselect') {
                        this.data_filters[filter.name] = [];
                    } else {
                        this.data_filters[filter.name] = null;
                    }
                });
            },
            applyFilters() {
                this.$parent.$emit('filtered', this.data_filters);
            },
            clearFilters() {
                this.initFilters();
                this.data_filters.date = '';
                this.data_filters.daterange.startDate = null;
                this.data_filters.daterange.endDate = null;
                this.data_filters.timerange.from = null;
                this.data_filters.timerange.to = null;
                this.data_filters.turns = [];
                this.$parent.$emit('filtered', this.data_filters);
            },
            changeHandler(dateEvent) {
                var d = new Date(dateEvent);
                this.data_filters.date = d.getUTCFullYear() +"-"+ (d.getUTCMonth()+1) +"-"+  this.isLessThanTen(d.getUTCDate());
            },
            isLessThanTen(val){
                if(val < 10){
                    return '0' + val;
                }else{
                    return val;
                }
            },
        },
        mounted() {
            this.initFilters();
        },
    }
</script>

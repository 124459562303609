<template>
    <div class="vue-turns">
        <div class="mb-4 box--shadowed vue-turns__info">
            <h3 class="d-flex justify-content-between align-items-center heading-2 text-brand">
                <span>{{ 'general.info' | trans }}</span>
                <i class="icon-info"></i>
            </h3>
            <p>
               {{ 'general.calendar_holidays_explanation' | trans }}
            </p>
        </div>
        <div class="box--shadowed">
            <div class="d-flex justify-content-between align-items-center vue-turns__header">
                <h4 class="heading-2 text-brand vue-turns__title">
                    {{ 'general.laboral_calendar' | trans }}
                    <small>{{ 'general.add_more_days' | trans }}</small>
                </h4>

                <button class="btn-action btn-action--add" @click="addTurn" v-bind:disabled="loading"></button>
            </div>
            <div class="mt-3">
                <div class="box--shadowed bg-danger text-white mb-4" id="alert-error" style="display:none">
                    {{'general.error_with_holidays' | trans}}
                </div>
            </div>
            <div class="row mx-0 box--shadowed vue-turns__turn" v-for="(turn, index) in turns">
                <div class="col-12 col-md-4 vue-turns__turn__name" v-bind:class="{'has-error' : errors.has('date_'+index)}">
                    <datepicker
                        class="form-control"
                        :name="'date_' +index"
                        v-validate="'required'"
                        v-bind:data-vv-as="input.date[locale]"
                        :monday-first="true"
                        v-model="turn.date"
                        :language="lang[locale]"
                        @input="changeHandler($event,index)"
                    ></datepicker>
                    <small v-if="errors.has('date_'+index)" class="text-danger">{{ errors.first('date_'+index) }}</small>
                </div>
                <div class="col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions">
                    <button class="btn-action btn-action--remove" @click="removeTurn(index)" v-bind:disabled="loading"></button>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4 vue-turns__submit">
            <button class="btn btn-brand-outline" type="button" @click="save" v-bind:disabled="loading">
                <i v-if="loading" class="icon icon-spinner spinner"></i>
                {{ 'general.save' | trans }}
            </button>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';

    export default {
        components: {Datepicker},
        data() {
            return {
                turns: [],
                loading: false,
                input: {
                    date: {
                        es: 'fecha',
                        eu: 'data',
                    },
                },
                lang: {
                    es: es,
                },
                format: "dd MMM YYYY",
            };
        },
        props: ['dates', 'locale'],
        methods: {
            addTurn() {
                this.turns.push({ id: null});
            },
            removeTurn(index) {
                this.turns.splice(index, 1);
            },
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $('#alert-error').hide();
                        this.loading = true;
                        var params = { 'holidays' : this.turns };
                        axios.post(window.config.context_prefix+'/api/admin/save-bank-holidays', params)
                            .then(response => {
                                this.turns = response.data.dates;
                                this.loading = false;
                            }).catch(error => {
                                $('#alert-error').show();
                                this.loading = false;
                                console.log('Ooops...')
                        });
                    }
                    if(!result){
                        console.log('Oops!');
                    }
                });
            },
            changeHandler(dateEvent, index) {
                var d = new Date(dateEvent);
                this.turns[index].date = d.getUTCFullYear() +"-"+ (d.getUTCMonth()+1) +"-"+  this.isLessThanTen(d.getUTCDate());
            },
            isLessThanTen(val){
                if(val < 10){
                    return '0' + val;
                }else{
                    return val;
                }
            },
        },
        beforeMount(){
            this.turns = this.dates;
        },
        watch: {

        }
    }
</script>

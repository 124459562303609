var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "vue_search_input search__form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.performSearch($event)
        }
      }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        staticClass: "search__input",
        attrs: { type: "text" },
        domProps: { value: _vm.search },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "search__submit", attrs: { type: "submit" } },
        [_vm._v(_vm._s(_vm._f("trans")("general.search")))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-datatable row">
        <slot></slot>
        <div class="col-12 vue-datatable__actions" v-if="show_buttons || show_search">
            <datatable-buttons
                v-if="show_buttons"
                @reload="getData(rows.current_page)"
                @print="print"
                @csv="downloadExport('csv')"
                @xls="downloadExport('xls')"
                @pdf="downloadExport('pdf')"
                :buttons_loading="buttons_loading"
            ></datatable-buttons>
            <datatable-search v-if="show_search" @search="performSearch"></datatable-search>
        </div>
        <div class="col-12 datatable">
            <datatable-header :columns="rows.columns" :order="order" :key="headerKey"
                              @sort="performSort"></datatable-header>
            <datatable-body
                :columns="rows.columns"
                :rows="rows.data"
                :loading="loading"
                @reload="getData(rows.current_page)"
            ></datatable-body>
        </div>
        <footer class="col-12 vue-datatable__footer">
            <datatable-stats
                v-if="show_stats"
                :from="rows.from"
                :to="rows.to"
                :total="rows.total"
            ></datatable-stats>
            <nav class="vue-datatable__pagination" v-if="show_pagination">
                <paginate
                    :page-count="rows.last_page"
                    container-class="pagination"
                    :prev-text="'pagination.previous' | trans"
                    :next-text="'pagination.next' | trans"
                    page-class="page-item"
                    prev-class="page-item"
                    next-class="page-item"
                    page-link-class="page-link"
                    prev-link-class="page-link"
                    next-link-class="page-link"
                    :click-handler="getData"
                ></paginate>
            </nav>
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rows: {
                    columns: [],
                    current_page: 0,
                    data: [],
                    from: 0,
                    last_page: 0,
                    per_page: 0,
                    to: 0,
                    total: 0,
                },
                loading: true,
                filters: {},
                order: {},
                buttons_loading: false,
                headerKey: 0,
            };
        },
        props: {
            url: {
                required: true,
                type: String,
            },
            prop_filters: {
                default: null
            },
            show_buttons: {
                default: true,
            },
            show_search: {
                default: true,
            },
            show_stats: {
                default: true,
            },
            show_pagination: {
                default: true,
            }
        },
        methods: {
            getData(page) {
                if(page == 'undefined') {
                    page = 1;
                }
                var params = {
                    page: page,
                    filters: this.filters,
                    order: this.order
                };
                this.loading = true;
                axios.get(this.url, {params: params})
                    .then(response => {
                        this.rows.columns = response.data.columns;
                        this.rows.current_page = response.data.current_page;
                        this.rows.from = response.data.from;
                        this.rows.last_page = response.data.last_page;
                        this.rows.to = response.data.to;
                        this.rows.total = response.data.total;
                        this.rows.data = response.data.data;
                        this.loading = false;
                        this.headerKey += 1;
                    }).catch(error => {
                    this.loading = false;
                    console.log('Ooops...');
                });
            },
            applyFilters(filters) {
                this.filters = filters;
                this.getData(1);
            },
            performSearch(search) {
                this.filters['search'] = search;
                this.getData(1);
            },
            performSort(sort) {
                this.order = sort;
                this.headerKey += 1;
                this.getData(1);
            },
            print() {
                this.buttons_loading = true;
                var params = {
                    filters: this.filters,
                    order: this.order,
                    action: 'print',
                };
                axios.get(this.url, {params: params})
                    .then(response => {
                        var w = window.open("test", "_blank");
                        w.document.open();
                        w.document.write(response.data);
                        w.document.close();
                        this.buttons_loading = false;
                    }).catch(error => {
                    console.log('Ooops...');
                    this.buttons_loading = false;
                });
            },
            downloadExport(type) {
                this.buttons_loading = true;
                var params = {
                    filters: this.filters,
                    order: this.order,
                    action: type,
                };
                axios.get(this.url, {params: params})
                    .then(response => {
                        var a = document.createElement("a");
                        a.href = response.data.file;
                        a.download = response.data.name;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        this.buttons_loading = false;
                    }).catch(error => {
                    console.log('Ooops...');
                    this.buttons_loading = false;
                });
            },
        },
        mounted() {
            if (this.prop_filters) {
                this.filters = this.prop_filters;
            }
            this.getData(1);
            this.$on('filtered', this.applyFilters);
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container vue_restaurant_list" }, [
    _vm.loading
      ? _c("div", { staticClass: "row" }, [
          _vm._v(
            "\n        " + _vm._s(_vm._f("trans")("general.loading")) + "\n    "
          )
        ])
      : _vm.restaurants.data.length > 0
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(_vm.restaurants.data, function(restaurant, index) {
              return _c("article", { staticClass: "restaurant_list__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "restaurant_list__item__link",
                    attrs: {
                      href: _vm.route_restaurant(restaurant.id),
                      title: "Reservar"
                    }
                  },
                  [
                    _c("figure", {
                      staticClass: "restaurant_list__item__image",
                      style:
                        "background-image:url(" +
                        restaurant.featured_image +
                        ")"
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "restaurant_list__item__info" }, [
                  _c(
                    "a",
                    {
                      staticClass: "restaurant_list__item__link",
                      attrs: {
                        href: _vm.route_restaurant(restaurant.id),
                        title: "Reservar"
                      }
                    },
                    [
                      _c("h3", { staticClass: "restaurant_list__item__name" }, [
                        _vm._v(
                          _vm._s(_vm._f("truncate")(restaurant.name, 30, "..."))
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "restaurant_list__item__additional_info" },
                    [
                      _c(
                        "span",
                        { staticClass: "restaurant_list__item__price" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(restaurant.price_symbol) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "restaurant_list__item__description" },
                    [
                      restaurant.description
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.truncate(
                                  restaurant.description,
                                  100,
                                  "..."
                                )
                              )
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "restaurant_list__item__link",
                    attrs: {
                      href: _vm.route_restaurant(restaurant.id),
                      title: "Reservar"
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "restaurant_list__item__link__anchor" },
                      [_vm._v(_vm._s(_vm._f("trans")("general.book")))]
                    )
                  ]
                )
              ])
            }),
            _vm._v(" "),
            _vm.restaurants.meta.last_page > _vm.restaurants.meta.current_page
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.getRestaurants(
                          _vm.restaurants.meta.current_page + 1
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("trans")("general.load_more")) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-new-menu">
        <div class="d-flex justify-content-between mb-4">
            <slot></slot>
            <button class="btn btn-brand-outline" type="button" v-bind:disabled="loading" @click.prevent="saveMenu">
                <i v-if="loading" class="icon icon-spinner spinner"></i>{{ 'general.save' | trans }}</button>
        </div>

        <section class="box--shadowed">
            <h4 class="heading-2 text-brand">
                {{ 'general.introduce_menu' | trans }}
            </h4>

            <form autocomplete="off">
                <div class="general">
                    <div class="row">
                        <div class="form-group col-md-6 col-lg-4" v-bind:class="{'has-error' : errors.has('type')}">
                            <label for="type">{{'general.type' | trans }} <span class="required"> *</span></label>
                            <v-select v-validate="'required'"
                                      v-bind:data-vv-as="input.menu_type_id[locale]"
                                      v-model="menu.menu_type_id"
                                      ref="type" id="type" name="type"
                                      v-on:input="changeMenusCategories"
                                      :options="types | prepare_select('name')"
                                      :reduce="type => type.id">
                            </v-select>
                            <small v-if="errors.has('type')" class="text-danger">{{ errors.first('type') }}</small>
                        </div>

                        <div class="form-group col-md-6 col-lg-4" v-bind:class="{'has-error' : errors.has('private_name')}">
                            <label for="private_name">{{'general.menu_private_name' | trans }}<span class="required"> *</span></label>
                            <input v-validate="'required|min:3|max:255'" v-bind:data-vv-as="input.private_name[locale]" v-model="menu.private_name" type="text" class="form-control" id="private_name" name="private_name" maxlength="255">
                            <small v-if="errors.has('private_name')" class="text-danger">{{ errors.first('private_name') }}</small>
                        </div>

                        <div class="form-group col-md-6 col-lg-4">
                            <dummy-multilang :langs="langs" field_name="menu_public_name" required="true">
                                <template v-for="lang in langs" :slot="lang">
                                    <div v-bind:class="{'has-error' : errors.has('public_name_' + lang)}">
                                        <input
                                            v-model="menu['public_name'][lang]"
                                            type="text"
                                            :name="'public_name_' + lang"
                                            class="form-control"
                                            v-validate="'required|min:3|max:255'"
                                            maxlength="255"
                                            v-bind:data-vv-as="input['public_name_' + lang][locale]"
                                        >
                                    </div>
                                </template>
                            </dummy-multilang>
                            <template v-for="lang in langs">
                                <p class="m-0">
                                    <small v-if="errors.has('public_name_' + lang)" class="text-danger">
                                        {{ errors.first('public_name_' + lang) }}
                                    </small>
                                </p>
                            </template>
                        </div>
                    </div>

                    <div class="row">
                        <div v-if="menu.menu_type_id === 2" class="form-group col-md-6 col-lg-4">
                            <div class="input-group" v-bind:class="{'has-error' : errors.has('price')}">
                                <label for="price">{{ 'general.price' | trans }}<span class="required"> *</span></label>
                                <input
                                    v-model="menu['price']"
                                    type="number"
                                    name="price"
                                    id="price"
                                    class="form-control"
                                    step="0.01" min="0"
                                    v-validate="'required_if:type,2|decimal:2'"
                                    v-bind:data-vv-as="input.price[locale]">
                                <div class="input-group-append">
                                        <span class="input-group-text">
                                            <strong>&euro;</strong>
                                        </span>
                                </div>
                                <p class="m-0"><small v-if="errors.has('price')" class="text-danger">{{ errors.first('price') }}</small></p>
                            </div>
                        </div>

                        <div v-if="menu.menu_type_id === 2" class="form-group col-md-6 col-lg-4" v-bind:class="{'has-error' : errors.has('max_no_dishes')}">
                            <label for="max_no_dishes">{{'general.no_dishes' | trans }}<span class="required"> *</span></label>
                            <input v-validate="'numeric|required_if:type,2|min:0'" v-bind:data-vv-as="input.max_no_dishes[locale]" v-model="menu.max_no_dishes" type="number" class="form-control" id="max_no_dishes" name="max_no_dishes" step="0" min="0" maxlength="255">
                            <small v-if="errors.has('max_no_dishes')" class="text-danger">{{ errors.first('max_no_dishes') }}</small>
                        </div>
                    </div>

                    <div class="row">
                        <div v-if="menu.menu_type_id === 2 && is_half_price_required" class="form-group col-md-6 col-lg-4">
                            <div class="input-group" v-bind:class="{'has-error' : errors.has('half_price')}">
                                <label for="half_price">{{'general.half_price_menu' | trans }}<span class="required"> *</span></label>
                                <input
                                    v-validate="'decimal:2|required_if:half_price_required,true'"
                                    v-bind:data-vv-as="input.half_price[locale]"
                                    v-model="menu.half_price"
                                    type="number"
                                    class="form-control"
                                    id="half_price"
                                    name="half_price"
                                    step="any" min="0">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <strong>&euro;</strong>
                                    </span>
                                </div>
                                <p class="m-0"><small v-if="errors.has('half_price')" class="text-danger">{{ errors.first('half_price') }}</small></p>
                            </div>
                        </div>
                        <div v-if="menu.menu_type_id === 2 && is_half_price_required" class="form-group col-md-6 col-lg-4" v-bind:class="{'has-error' : errors.has('half_max_no_dishes')}">
                            <label for="half_max_no_dishes">{{'general.no_dishes_half' | trans }}<span class="required"> *</span></label>
                            <input v-validate="'numeric|required_if:half_price_required|min:0'" v-bind:data-vv-as="input.half_max_no_dishes[locale]" v-model="menu.half_max_no_dishes" type="number" class="form-control" id="half_max_no_dishes" name="half_max_no_dishes" step="0" min="0" maxlength="255">
                            <small v-if="errors.has('half_max_no_dishes')" class="text-danger">{{ errors.first('half_max_no_dishes') }}</small>
                        </div>
                    </div>



                    <div class="row">
                        <div class="form-group col-12" v-bind:class="{'has-error' : errors.has('when_served')}">
                            <label for="when_served">{{'general.when_served' | trans }}<span class="required"> *</span> <i class="icon-info-sign"></i></label>
                            <v-select v-validate="'required'"
                                      v-bind:data-vv-as="input.when_served[locale]"
                                      v-model="menu.when_served"
                                      id="when_served" name="when_served" multiple
                                      :options="turns | prepare_select('name')" >
                                    <template slot="selected-option" slot-scope="option">
                                        <span class="vs__selected_tag turn_tag" :class="'turn_tag--'+option.id">
                                            <span class="vs__selected_tag__label">{{option.label}}</span>
                                        </span>
                                    </template>
                            </v-select>
                            <small v-if="errors.has('when_served')" class="text-danger">{{ errors.first('when_served') }}</small>
                        </div>
                    </div>

                    <div v-if="menu.menu_type_id === 2" class="form-group row">
                        <div class="col-12 my-4 col-form-label">
                            <div class="form-check checkbox">
                                <input v-model="is_half_price_required" ref="half_price_required" class="form-check-input" name="half_price_required" id="half_price_required" type="checkbox" value="">
                                <label class="form-check-label" for="half_price_required">{{ 'general.half_menu_option' | trans }}<span class="required"> *</span></label>
                            </div>
                            <div class="form-check mt-4 checkbox">
                                <input v-model="menu.full_table_required" class="form-check-input" name="full_table_required" id="full_table_required" type="checkbox" value="">
                                <label class="form-check-label" for="full_table_required">{{ 'general.full_table_option' | trans }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-12">
                            <dummy-multilang :langs="langs" field_name="observations">
                                <template v-for="lang in langs" :slot="lang">
                                    <div v-bind:class="{'has-error' : errors.has('observations_' + lang)}">
                                        <VueTrix
                                            v-model="menu['observations'][lang]"
                                            type="text"
                                            :name="'observations_' + lang"
                                            v-validate="'min:3|max:2000'"
                                            maxlength="2000"
                                            v-bind:data-vv-as="input['observations_' + lang][locale]"
                                        ></VueTrix>
                                    </div>
                                </template>
                            </dummy-multilang>
                            <template v-for="lang in langs">
                                <p class="m-0">
                                    <small v-if="errors.has('observations_' + lang)" class="text-danger">
                                        {{ errors.first('observations_' + lang) }}
                                    </small>
                                </p>
                            </template>
                        </div>
                    </div>
                </div>

                <template v-if="menu.menu_type_id === 2" class="menu">
                    <div class="row">
                        <div class="col-12 my-4">
                            <hr>
                        </div>
                        <div class="col-12 d-flex justify-content-between my-4">
                            <h4 class="heading-2 text-brand">{{ 'general.menu_category_explication' | trans }}</h4>
                            <button class="btn btn-brand" type="button" @click.prevent="addCategoryRow">{{ 'general.add_category' | trans }}</button>
                        </div>
                    </div>

                    <div class="row mx-2 box--shadowed dish_option" v-for="(element, key) in menu.elements">

                        <div class="col-12 dish_option__header_wrapper">
                            <div class="row align-items-center dish_option__header">
                                <div class="col-12 col-md-6 col-lg-4 form-group dish_option__header__option" v-bind:class="{'has-error' : errors.has('dish_option_'+key)}">
                                    <label :for="'dish_option_'+key">{{'general.dish_option' | trans }}<span class="required"> *</span></label>
                                    <v-select v-validate="'required_if:type,2'"
                                            v-bind:data-vv-as="input.dish_option[locale]"
                                            v-model="menu.elements[key].dish_option"
                                            :id="'dish_option_'+key"
                                            :name="'dish_option_'+key"
                                            :reduce="dish_option => dish_option.id"
                                            :options="dish_option | prepare_option_select('name')" ></v-select>
                                    <small v-if="errors.has('dish_option_'+key)" class="text-danger">{{ errors.first('dish_option_'+key) }}</small>
                                </div>
                                <div class="col-12 col-md-4 col-lg-7 col-form-label dish_option__header__required">
                                    <div class="form-check checkbox">
                                        <input v-model="menu.elements[key].required" class="form-check-input" :name="'dish_required_'+key" :id="'dish_required_'+key" type="checkbox" value="">
                                        <label class="form-check-label" :for="'dish_required_'+key">{{ 'general.required' | trans }}</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 col-lg-1 form-group d-flex justify-content-end dish_option__header__action">
                                    <div>
                                        <button class="btn-action btn-action--remove" type="button" @click.prevent="subtractMenuElement(key)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 dish_option__category">
                            <div class="row align-items-center">
                                <div class="form-group col-12" v-bind:class="{'has-error' : errors.has('dish_category_'+key)}">
                                    <label :for="'dish_category_'+key">{{'general.category' | trans }}<span class="required"> *</span></label>
                                    <v-select v-validate="'required_if:type,2'"
                                              v-bind:data-vv-as="input.category[locale]"
                                              v-model="menu.elements[key].category_id"
                                              :id="'dish_category_'+key"
                                              :name="'dish_category_'+key"
                                              :options="dish_categories | prepare_select('name')"
                                              :reduce="category_id => category_id.id"
                                    ></v-select>
                                    <small v-if="errors.has('dish_category_'+key)" class="text-danger">{{ errors.first('dish_category_'+key) }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 dish_option__category__dishes">
                            <div v-for="(row, index) in menu.elements[key].dishes" class="row align-items-center dish_option__category__dish">
                                <div class="form-group col-12 col-lg-6 dish_option__category__dish__name">
                                    <dummy-multilang :langs="langs" field_name="dish_name" required="true">
                                        <template v-for="lang in langs" :slot="lang">
                                            <div v-bind:class="{'has-error' : errors.has('dish_name_' + key + '_' + index + '_' + lang)}">
                                                <input
                                                    v-model="menu['elements'][key]['dishes'][index]['dish_name'][lang]"
                                                    type="text"
                                                    :name="'dish_name_' + key + '_' + index + '_' + lang"
                                                    class="form-control"
                                                    v-validate="'required_if:type,2|min:3|max:255'"
                                                    maxlength="255"
                                                    v-bind:data-vv-as="input['dish_name_' + lang][locale]"
                                                >
                                            </div>
                                        </template>
                                    </dummy-multilang>
                                    <template v-for="lang in langs">
                                        <p class="m-0">
                                            <small v-if="errors.has('dish_name_' + key + '_' + index + '_' + lang)" class="text-danger">
                                                {{ errors.first('dish_name_' + key + '_' + index + '_' + lang) }}
                                            </small>
                                        </p>
                                    </template>
                                </div>
                                <div class="form-group col-12 col-lg-5 dish_option__category__dish__tags">
                                    <label :for="'dish_tags_'+index">{{'general.dish_tag_caracteristcs' | trans }}</label>
                                    <v-select v-model="menu.elements[key].dishes[index].tag_id"
                                              :options="dish_tags | prepare_select('name')"
                                              :id="'dish_tags_'+index"
                                              name="dish_tags[]"
                                              multiple="multiple"
                                              :reduce="tag => tag.id"
                                    >

                                        <template slot="selected-option" slot-scope="option">
                                            <span class="vs__selected_tag dish_tag" :class="'dish_tag--'+option.id">
                                                <span class="vs__selected_tag__label">{{option.label}}</span>
                                            </span>
                                        </template>
                                    </v-select>

                                </div>
                                <div class="form-group col-12  col-lg-1 d-flex justify-content-end dish_option__category__dish__action">
                                    <div v-if="index === 0">
                                        <button class="btn-action btn-action--add" type="button" @click.prevent="addDish(key)"></button>
                                    </div>
                                    <div v-else>
                                        <button class="btn-action btn-action--remove" type="button" @click.prevent="subtractDish(index, key)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>


                <template v-if="menu.menu_type_id === 1" class="carta">
                    <div class="row">
                        <div class="col-12 my-4">
                            <hr>
                        </div>
                        <div class="col-12 d-flex justify-content-between my-4">
                            <h4 class="heading-2 text-brand">{{ 'general.dayly_menu_category_explication' | trans }}</h4>
                            <button class="btn btn-brand" type="button" @click.prevent="addCarta">{{ 'general.add_dish' | trans }}</button>
                        </div>
                    </div>
                    <div class="row mx-2 box--shadowed carta__dish" v-for="(element, key) in menu.elements">
                        <div class="col-10 col-lg-11 carta__dish__category">
                            <div class="form-group" v-bind:class="{'has-error' : errors.has('dish_category_carta_'+key)}">
                                <label :for="'dish_category_carta_'+key">{{'general.category' | trans }}<span class="required"> *</span></label>
                                <v-select v-validate="'required_if:type,2'"
                                          v-bind:data-vv-as="input.category[locale]"
                                          v-model="menu.elements[key].category_id"
                                          :id="'dish_category_'+key"
                                          :name="'dish_category_'+key"
                                          :options="dish_categories | prepare_select('name')" >
                                </v-select>
                                <small v-if="errors.has('dish_category_carta_'+key)" class="text-danger">{{ errors.first('dish_category_carta_'+key) }}</small>
                            </div>
                        </div>
                        <div class="col-2 col-lg-1 col-form-label d-flex justify-content-end align-items-center carta__dish__action">
                            <button class="btn-action btn-action--remove" type="button" @click.prevent="subtractMenuElement(key)"></button>
                        </div>
                        <div class="col-12 form-group carta__dish__name">
                            <dummy-multilang :langs="langs" field_name="dish_name" required="true">
                                <template v-for="lang in langs" :slot="lang">
                                    <div v-bind:class="{'has-error' : errors.has('dish_name_' + key + '_' + lang)}">
                                        <input
                                            v-model="menu['elements'][key]['dish_name'][lang]"
                                            type="text"
                                            :name="'dish_name_' + key + '_' + lang"
                                            class="form-control"
                                            v-validate="'required_if:type,1|min:3|max:255'"
                                            maxlength="2000"
                                            v-bind:data-vv-as="input['dish_name_' + lang][locale]"
                                        >
                                    </div>
                                </template>
                            </dummy-multilang>
                            <template v-for="lang in langs">
                                <p class="m-0">
                                    <small v-if="errors.has('dish_name_' + key + '_' + lang)" class="text-danger">
                                        {{ errors.first('dish_name_' + key + '_' + lang) }}
                                    </small>
                                </p>
                            </template>

                        </div>
                        <div class="col-12 col-md-6 col-lg-8 carta__dish__tags">
                            <div class="form-group">
                                <label :for="'dish_tags_'+ key">{{'general.dish_tag_caracteristcs' | trans }}</label>
                                <v-select
                                    v-model="menu.elements[key].tag_id"
                                    :options="dish_tags | prepare_select('name')"
                                    :reduce="tag => tag.id"
                                    :id="'dish_tags_'+key"
                                    :name="'dish_tags_'+key"
                                    multiple
                                >
                                    <template slot="selected-option" slot-scope="option">
                                            <span class="vs__selected_tag dish_tag" :class="'dish_tag--'+option.id">
                                                <span class="vs__selected_tag__label">{{option.label}}</span>
                                            </span>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2 carta__dish__price">
                            <div class="input-group">
                                <label :for="'dish_name_' + key ">{{ 'general.price' | trans }}</label>
                                <input
                                    v-model="menu['elements'][key]['price']"
                                    type="text"
                                    :name="'dish_name_' + key"
                                    class="form-control"
                                    v-validate="'min:0|max:255'"
                                    v-bind:data-vv-as="input.price[locale]">
                                <p class="m-0"><small v-if="errors.has('dish_name_' + key)" class="text-danger">{{ errors.first('dish_name_' + key) }}</small></p>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2 carta__dish__price">
                            <label for="type">{{'general.price_type' | trans }} </label>
                            <v-select
                                      v-model="menu.elements[key].price_type"
                                      :name="'price_type' + key"
                                      :options="price_type_option | prepare_select('name')"
                            >
                            </v-select>
                            <small v-if="errors.has('type')" class="text-danger">{{ errors.first('type') }}</small>
                        </div>

                    </div>
                </template>

            </form>
        </section>
        <div class="d-flex justify-content-end mt-4">
            <button class="btn btn-brand-outline" type="button" v-bind:disabled="loading" @click.prevent="saveMenu">
                <i v-if="loading" class="icon icon-spinner spinner"></i>
                {{ 'general.save' | trans }}</button>
        </div>
    </div>
</template>

<script>

    import VueTrix from "vue-trix";

    export default {
        components: {VueTrix},
        data() {
            return {
                showInput:{},
                dish_option: {},
                price_type_option: {},
                loading: false,
                types: [],
                is_half_price_required: false,
                menu: {
                    menu_type_id: null,
                    private_name: null,
                    when_served: [],
                    price: null,
                    half_price: null,
                    half_max_no_dishes: null,
                    max_no_dishes: null,
                    full_table_required: false,
                    observations: {
                        es: null,
                        eu: null,
                    },
                    public_name: {
                        es: null,
                        eu: null,
                    },
                    elements: [],
                },
                dish_categories: [],
                dish_tags: [],
                turns: [],
                input: {
                    private_name: {
                        es: 'nombre interno',
                        eu: 'barneko izena',
                    },
                    menu_type_id: {
                        es: 'tipo',
                        eu: 'mota',
                    },
                    public_name: {
                        es: 'nombre público',
                        eu: 'izen publikoa',
                    },
                    public_name_es: {
                        es: 'nombre público es',
                        eu: 'izen publikoa es',
                    },
                    public_name_eu: {
                        es: 'nombre público eu',
                        eu: 'izen publikoa eu',
                    },
                    price: {
                        es: 'precio',
                        eu: 'prezioa',
                    },
                    half_price: {
                        es: 'precio medio menú',
                        eu: 'menu erdiaren prezioa',
                    },
                    max_no_dishes: {
                        es: 'número máximo de platos',
                        eu: 'plateren kopuru maximoa',
                    },
                    half_max_no_dishes: {
                        es: 'número máximo de platos del medio menú',
                        eu: 'menu erdiaren plateren kopuru maximoa',
                    },
                    observations: {
                        es: 'observaciones',
                        eu: 'oharrak',
                    },
                    observations_es: {
                        es: 'observaciones es',
                        eu: 'oharrak es',
                    },
                    observations_eu: {
                        es: 'observaciones eu',
                        eu: 'oharrak eu',
                    },
                    category: {
                        es: 'categoría',
                        eu: 'kategoria',
                    },
                    dish_option: {
                        es: 'opción de plato',
                        eu: 'plater aukera',
                    },
                    dish_name_es: {
                        es: 'nombre del plato es',
                        eu: 'plateraren izena es',
                    },
                    dish_name_eu: {
                        es: 'nombre del plato eu',
                        eu: 'plateraren izena eu',
                    },
                    when_served: {
                        es: 'cuando se sirve',
                        eu: 'noiz zerbitzatzen da',
                    },
                },
            };
        },
        props: {
            langs: {
                type: Array,
                required: true,
            },
            locale: {
                type: String,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
            prop_menu: {
                type: Object,
                required: false,
                default: null,
            },
            copy: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        methods: {
            saveMenu() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.loading = true;
                        var params = {'menu': this.menu};
                        axios.post(window.config.context_prefix+"/api/restaurant/menus/store", params)
                            .then(response => {
                                Vue.nextTick(() => {
                                    this.menu = response.data;
                                    var that = this;
                                    setTimeout(() => {
                                        that.loading = false;
                                    }, 1000);
                                });
                            }).catch(error => {
                                //TODO: genera mensaje error.
                                this.loading = false;
                        });
                    }
                    if(!result){
                        console.log('Oops! Not validate');
                        this.loading = false;
                    }
                });
            },
            getTypes() {
                axios.get(window.config.context_prefix+"/api/restaurant/menu-types", null)
                    .then(response => {
                        this.types = response.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
            },
            changeMenusCategories() {
                var params = {'menu_type_id': this.menu.menu_type_id};
                axios.post(window.config.context_prefix+"/api/restaurant/dish-categories", params)
                    .then(response => {
                        this.dish_categories = response.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
                this.menu.elements = [];
                if(this.menu.menu_type_id === 1){
                    this.cleanMenuAttributes();
                    this.addCarta();
                }else{
                    this.addCategoryRow();
                }
            },
            cleanMenuAttributes(){
                this.menu.price = null;
                this.menu.half_price = null;
                this.menu.half_max_no_dishes = null;
                this.menu.max_no_dishes = null;
                this.menu.full_table_required = false;
            },
            getMenusTags() {
                axios.get(window.config.context_prefix+"/api/restaurant/dish-tags")
                    .then(response => {
                        this.dish_tags = response.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
            },
            addCarta() {
                this.menu.elements.push({
                    category_id : null,
                    dish_name : {
                        es : null,
                        eu : null,
                    },
                    tag_id : [],
                    price: null,
                    price_type: null,
                });
            },
            subtractMenuElement(key) {
                this.menu.elements.splice(key, 1);
            },
            addCategoryRow() {
                this.menu.elements.push({
                    dish_option : null,
                    required : false,
                    category_id : null,
                    dishes : [{
                        dish_name : {
                            es : null,
                            eu : null,
                        },
                        tag_id : [],
                    }],
                });
            },
            addDish(key){
                this.menu.elements[key].dishes.push({
                    dish_name : {
                        es : null,
                        eu : null,
                    },
                    tag_id : [],
                });
            },
            subtractDish(key, index) {
                this.menu.elements[index].dishes.splice(key, 1);
            },
            toggleLang: function(toggler, lang) {
                if(lang == 'es'){
                    lang = 'eu';
                }
                toggler.$set('current-lang',lang);
            },
            getTurns() {
                axios.get(window.config.context_prefix+'/api/restaurant/turns')
                    .then(response => {
                        this.turns = response.data.data;
                    }).catch(error => {
                    this.loading = false;
                    console.log('Ooops...')
                });
            },
            getDishOption() {
                axios.get(window.config.context_prefix+'/api/restaurant/dish-options')
                    .then(response => {
                        this.dish_option = response.data;
                    }).catch(error => {
                    this.loading = false;
                        console.log('Ooops...')
                });
            },
            getPriceTypeOption() {
                axios.get(window.config.context_prefix+'/api/get-price-types')
                    .then(response => {
                        this.price_type_option = response.data.data;
                    }).catch(error => {
                    this.loading = false;
                        console.log('Ooops...')
                });
            },
            loadMenusCategories() {
                var params = {'menu_type_id': this.menu.menu_type_id};
                axios.post(window.config.context_prefix+"/api/restaurant/dish-categories", params)
                    .then(response => {
                        this.dish_categories = response.data;
                    }).catch(error => {
                    console.log('Ooops...');
                });
            },
        },
        mounted() {
            $('.trix-button-group--file-tools').hide();
            this.getTypes();
            this.getMenusTags();
            this.getTurns();
            this.getDishOption();
            this.getPriceTypeOption();

            if(this.prop_menu !== null){
                this.menu = this.prop_menu;
                if(this.copy){
                    this.menu.id = null;
                }
                if(this.menu.menu_type_id === 2) {
                    this.is_half_price_required = this.prop_menu.half_menu;
                }
            }
        },
        watch: {
            'menu.menu_type_id': function () {
                this.loadMenusCategories();
            },
        }
    }

</script>


<template>
    <div class="box--shadowed">

        <div class="mb-4 d-flex justify-content-between align-items-center vue-closings__header">
            <div class="btn-group" role="group">
                <button type="button" class="btn btn-brand" @click="prevMonth">
                    <i class="icon icon-arrow-left2"></i>
                </button>
                <button type="button" class="btn btn-brand" @click="currentMonth" v-bind:disabled="this.disabled">
                    {{ 'general.current_month' | trans }}
                </button>
                <button type="button" class="btn btn-brand" @click="nextMonth">
                    <i class="icon icon-arrow-right2"></i>
                </button>
            </div>
            <h3 class="heading-2 text-brand vue-closings__title">
                {{ display_date | monthYearName }}
            </h3>
        </div>

        <div v-if="loading" class="loader">
            <h3>{{ 'general.loading' | trans }}</h3>
        </div>
        <div v-else>
            <FullCalendar
                ref="fullCalendar"
                defaultView="dayGridMonth"
                :plugins="calendarPlugins"
                :events="events"
                :first-day="1"
                :header="false"
                :event-render="eventRendered"
                :default-date="display_date"
                @eventClick="editEvent"
                :locale="this.locale"
            />

            <slot name="legends">
                <div class="reservations_calendar__legends" slot="legends">
                    <ul class="legends__list">
                        <li class="legend legend--today">
                            <span class="legend__label">{{ 'general.today' | trans}}</span>
                        </li>
                        <li class="legend legend--holiday">
                            <span class="legend__label">{{ 'general.sundays_and_holidays' | trans }}</span>
                        </li>
                        <li class="legend legend--closed">
                            <span class="legend__label">{{ 'general.closed' | trans }}</span>
                        </li>
                    </ul>
                </div>
            </slot>
        </div>
        <portal to="modals">
            <reservations-datatable-modal
                v-if="modal_open"
                :date="selected_date"
                :turn="selected_turn"
                :display_date="selected_date.startDate | date"
                :display_turn="selected_turn_name"
                @close="closeModal"
            ></reservations-datatable-modal>
        </portal>

    </div>
</template>

<script>
    import {closeModal, openModal} from "../modal";
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import '@fullcalendar/core/main.css';
    import '@fullcalendar/daygrid/main.css';
    import '@fullcalendar/core/locales/es.js';
    import '@fullcalendar/core/locales/eu.js';

    export default {
        components: {
            FullCalendar,
        },
        data() {
            return {
                loading: true,
                disabled: true,
                calendarPlugins: [dayGridPlugin],
                display_date: new Date(),
                events: [],
                header: {
                    left: '',
                    center: '',
                    right: 'title',
                },
                modal_open: false,
                selected_date: null,
                selected_turn: null,
                selected_turn_name: null,
            };
        },
        props: ['locale'],
        methods: {
            getData(date) {
                this.loading = true;
                if(date == 'undefined') {
                    date = this.display_date;
                }
                var params = {
                    date: date,
                };
                axios.get(window.config.context_prefix+'/api/restaurant/reservations/events-month', {params: params})
                    .then(response => {
                        this.events = response.data;
                    })
                this.loading = false;
            },
            eventRendered(info) {
                if (info.event.title) {
                    info.el.setAttribute('title', info.event.title);
                    info.el.setAttribute('data-toggle', 'tooltip');
                    $(info.el).tooltip();
                }
            },
            editEvent(info) {
                if(info.el.text){
                    var date = new Date(info.event.start);
                    this.selected_date = {
                        startDate: new Date(date.getTime() + 12 * 60 * 60 * 1000),
                        endDate: new Date(date.getTime() + 12 * 60 * 60 * 1000)
                    };
                    this.selected_turn = info.event.extendedProps.turn_id;
                    this.selected_turn_name = info.event.extendedProps.turn_name;
                    Vue.nextTick(() => {
                        this.modal_open = true;
                        openModal();
                    });
                }
            },
            closeModal() {
                this.modal_open = false;
                closeModal();
            },
            prevMonth() {
                var newMonth = this.display_date.setMonth(this.display_date.getMonth() - 1);
                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.prev();
                if (this.isCurrentMonth()) {
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
                this.display_date = calendarApi.getDate();
            },
            nextMonth() {
                var newMonth = this.display_date.setMonth(this.display_date.getMonth() + 1);
                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.next();
                if (this.isCurrentMonth()) {
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
                this.display_date = calendarApi.getDate();
            },
            currentMonth() {
                this.display_date = new Date();
                var newMonth = this.display_date.setMonth(this.display_date.getMonth());
                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.gotoDate(newMonth);
                this.disabled = true;
                this.display_date = calendarApi.getDate();
            },
            isCurrentMonth() {
                var this_month = new Date();
                return this_month.getMonth() === this.display_date.getMonth();
            },
        },
        mounted() {
            EventBus.$on('reload_calendar', payload => {
                this.getData(this.display_date);
            });
            this.getData(this.display_date);
        },
        beforeDestroy() {
            EventBus.$off('reload_calendar');
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-gallery" },
    [
      _c(
        "div",
        { staticClass: "box--shadowed vue-gallery__upload" },
        [
          _c("upload-image", {
            attrs: {
              url: _vm.url,
              button_class: "btn btn-brand-outline",
              "data-label": _vm._f("trans")("general.upload_images"),
              button_html: _vm._f("trans")("general.upload_images_btn")
            },
            on: { "upload-image-success": _vm.getMedia }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box--shadowed mt-5 vue-gallery__gallery" },
        [
          _c(
            "h3",
            {
              staticClass:
                "d-flex mb-4 justify-content-between align-items-center heading-2 text-brand gallery__title"
            },
            [_c("span", [_vm._v(_vm._s(_vm._f("trans")("general.gallery")))])]
          ),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "row",
              on: {
                start: function($event) {
                  _vm.drag = true
                },
                end: function($event) {
                  _vm.drag = false
                },
                update: _vm.updateMedia
              },
              model: {
                value: _vm.gallery,
                callback: function($$v) {
                  _vm.gallery = $$v
                },
                expression: "gallery"
              }
            },
            _vm._l(_vm.gallery, function(image) {
              return _c(
                "div",
                {
                  key: image.id,
                  staticClass: "col-6 col-md-4 col-lg-3 mb-4 gallery__image",
                  class: { "gallery__image--featured": image.featured },
                  attrs: { title: image.featured ? "Featured" : "" }
                },
                [
                  _c("figure", {
                    staticClass: "gallery__image__wrapper",
                    style: "background-image: url(" + image.url + ");"
                  }),
                  _vm._v(" "),
                  _c(
                    "figcaption",
                    { staticClass: "gallery__image__actions" },
                    [
                      image.featured
                        ? [
                            image.gallery
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "gallery__image__action gallery__image__action--hide",
                                    attrs: { title: "Hide" },
                                    on: {
                                      click: function($event) {
                                        return _vm.hideMediaFromGallery(
                                          image.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon icon-eye-blocked"
                                    })
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "gallery__image__action gallery__image__action--show",
                                    attrs: { title: "show" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showMediaOnGallery(image.id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "icon icon-eye" })]
                                )
                          ]
                        : [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "gallery__image__action gallery__image__action--feature",
                                attrs: { title: "Feature" },
                                on: {
                                  click: function($event) {
                                    return _vm.openFeatureModal(image.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "icon icon-star-full" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "gallery__image__action gallery__image__action--delete",
                                attrs: { title: "Delete" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDeleteModal(image.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "icon icon-bin" })]
                            )
                          ]
                    ],
                    2
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "modals" } },
        [
          _vm.delete_modal_open
            ? _c("confirmation-modal", {
                attrs: {
                  url: _vm.modal_url,
                  title: _vm._f("trans")("general.confirm_delete"),
                  text: _vm._f("trans")("general.confirm_delete_p"),
                  params: { gallery: _vm.gallery, id: this.restaurant_id }
                },
                on: { close: _vm.closeDeleteModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.feature_modal_open
            ? _c("confirmation-modal", {
                attrs: {
                  url: _vm.modal_url,
                  title: _vm._f("trans")("general.confirm_feature"),
                  text: _vm._f("trans")("general.confirm_feature_p"),
                  params: { gallery: _vm.gallery, id: this.restaurant_id },
                  level: "success"
                },
                on: { close: _vm.closeFeatureModal }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-turns" }, [
    _c("div", { staticClass: "mb-4 box--shadowed vue-turns__info" }, [
      _c(
        "h3",
        {
          staticClass:
            "d-flex justify-content-between align-items-center heading-2 text-brand"
        },
        [
          _c("span", [_vm._v(_vm._s(_vm._f("trans")("general.info")))]),
          _vm._v(" "),
          _c("i", { staticClass: "icon-info" })
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n           " +
            _vm._s(_vm._f("trans")("general.calendar_holidays_explanation")) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box--shadowed" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center vue-turns__header"
          },
          [
            _c("h4", { staticClass: "heading-2 text-brand vue-turns__title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("general.laboral_calendar")) +
                  "\n                "
              ),
              _c("small", [
                _vm._v(_vm._s(_vm._f("trans")("general.add_more_days")))
              ])
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-action btn-action--add",
              attrs: { disabled: _vm.loading },
              on: { click: _vm.addTurn }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "div",
            {
              staticClass: "box--shadowed bg-danger text-white mb-4",
              staticStyle: { display: "none" },
              attrs: { id: "alert-error" }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("general.error_with_holidays")) +
                  "\n            "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm._l(_vm.turns, function(turn, index) {
          return _c(
            "div",
            { staticClass: "row mx-0 box--shadowed vue-turns__turn" },
            [
              _c(
                "div",
                {
                  staticClass: "col-12 col-md-4 vue-turns__turn__name",
                  class: { "has-error": _vm.errors.has("date_" + index) }
                },
                [
                  _c("datepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "date_" + index,
                      "data-vv-as": _vm.input.date[_vm.locale],
                      "monday-first": true,
                      language: _vm.lang[_vm.locale]
                    },
                    on: {
                      input: function($event) {
                        return _vm.changeHandler($event, index)
                      }
                    },
                    model: {
                      value: turn.date,
                      callback: function($$v) {
                        _vm.$set(turn, "date", $$v)
                      },
                      expression: "turn.date"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("date_" + index)
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("date_" + index)))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions"
                },
                [
                  _c("button", {
                    staticClass: "btn-action btn-action--remove",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.removeTurn(index)
                      }
                    }
                  })
                ]
              )
            ]
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-end mt-4 vue-turns__submit" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-brand-outline",
            attrs: { type: "button", disabled: _vm.loading },
            on: { click: _vm.save }
          },
          [
            _vm.loading
              ? _c("i", { staticClass: "icon icon-spinner spinner" })
              : _vm._e(),
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("trans")("general.save")) +
                "\n        "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
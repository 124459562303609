var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-input-multi-lang" },
    [
      _c(
        "label",
        {
          staticClass: "input-multi-lang__label",
          attrs: { for: _vm.field_name + "_" + this.current_lang }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("trans")("general." + _vm.field_name)) +
              " - " +
              _vm._s(this.current_lang) +
              " "
          ),
          _vm.required
            ? _c("span", { staticClass: "required" }, [_vm._v("*")])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("btn-switch-lang", { on: { changeLang: _vm.toggleLang } }),
      _vm._v(" "),
      _vm._l(_vm.langs, function(lang) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current_lang === lang,
                expression: "current_lang === lang"
              }
            ],
            staticClass: "input-multi-lang__input"
          },
          [_vm._t(lang)],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
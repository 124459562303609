<template xmlns="http://www.w3.org/1999/html">

    <section v-if="loading" class="row restaurant_menus mt-5">
        <h3 class="col-12 py-0 restaurant_menus__header">
            <span class="restaurant_menus__title">{{ 'general.menus' | trans }}</span>
        </h3>
        <div v-if="menus.length > 0" id="restaurant_menus__carousel" class="col-12 carousel slide restaurant_menus__carousel" data-ride="carousel">
            <div class="carousel-inner">
                <article v-for="(menu, index) in menus" class="carousel-item menu" v-bind:class="{'active': index < 1}">
                    <header class="menu__header">
                        <div class="menu__header__actions">
                            <!--https://github.com/Akryum/v-tooltip#usage-->
                            <v-popover offset="16">
                                <a class="tooltip-target b3 menu__header__action_link">{{ 'general.share' | trans }} <i class="icon-share"></i></a>

                                <template slot="popover">
                                    <p class="mb-2">{{ 'general.share_url' | trans }}</p>
                                    <input class="p-2 mb-2" type="text" :value="share_url">
                                    <a href="#" v-clipboard="share_url" class="btn btn-primary" @click.prevent v-clipboard:success="onCopy">{{ text | trans }}</a>
                                    <a v-close-popover class="btn btn-grey-outline">{{ 'general.close' | trans }}</a>
                                </template>
                            </v-popover>
                        </div>

                        <h4 class="text-brand menu__header__title">{{menu.name}}: {{dateToShow}}</h4>
                        <p class="menu__header__subtitle" v-html="menu.observations"> </p>

                    </header>
                    <div class="menu__content">
                        <div v-for="(elements, key) in menu.elements">
                            <p><strong>{{elements.name}}</strong></p>
                            <ul>
                                <li v-for="dish in elements.dishes">
                                    <span class="dish__name">{{dish.name}}</span>
                                    <span v-if="dish.price !== null" class="dish__price">({{dish.price}}€)</span>
                                    <span class="dish__tags" v-if="dish.tags.length > 0">
                                        <span class="dish_tag" :class="tagClass(tag.id)" v-for="tag in dish.tags">{{tag.label}}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <footer class="menu__footer">
                        <p v-if="menu.menu_type_id === 2">{{ 'general.menu_price' | trans}}: {{menu.price }} €</p>
                        <p v-if="menu.menu_type_id === 2">{{ 'general.menu_price_text' | trans}}</p>
                        <p v-if="menu.menu_type_id === 2 && menu.half_price !== null">{{ 'general.half_menu_price' | trans}} : {{menu.half_price}} €</p>
                    </footer>
                </article>
            </div>

            <a class="carousel-control-prev" href="#restaurant_menus__carousel" role="button" data-slide="prev">
                <span class="restaurant_menus__carousel__control_icon icon-ctrl-left" aria-hidden="true"></span>
                <span class="sr-only">{{ 'general.previous' | trans }}</span>
            </a>
            <a class="carousel-control-next" href="#restaurant_menus__carousel" role="button" data-slide="next">
                <span class="restaurant_menus__carousel__control_icon icon-ctrl-right" aria-hidden="true"></span>
                <span class="sr-only">{{ 'general.next' | trans }}</span>
            </a>
        </div>

        <div v-else id="restaurant_menus__carousel" class="col-12 carousel slide restaurant_menus__carousel" data-ride="carousel">
            <h3>{{'general.no_menu_data' | trans}}</h3>
        </div>

    </section>

</template>


<script>

    export default {
        data() {
            return {
                menus: [],
                dateToShow: '',
                turn_id: null,
                loading: false,
                share_url: null,
                btn_class: '',
                text: 'general.copy',
            }
        },
        props: ['date' , 'turn', 'restaurant', 'locale', 'url'],
        methods: {
            getTurns() {
                this.loading = false;
                axios.post(window.config.context_prefix+'/api/client/reservations/get-available-menus', {date: this.date, turn: this.turn, restaurant: this.restaurant})
                    .then(response => {
                        this.menus = response.data.menus;
                        this.turn_id = response.data.turn[0];
                        var dateToShow = new Date(Date.parse(this.date));
                        this.dateToShow = this.formatDate(dateToShow);
                        this.loading = true;
                        this.generateURL();
                    });
            },
            formatDate(date){
                var day = this.isLessThanTen(date.getDate());
                var monthIndex = this.isLessThanTen(date.getMonth()+1);
                var year = date.getFullYear();
                if(this.locale === 'es'){
                    return day + '/' + monthIndex + '/' + year;
                }else{
                    return year + '/' + monthIndex + '/' + day;
                }
            },
            formatURLDate(date){
                var day = this.isLessThanTen(date.getDate());
                var monthIndex = this.isLessThanTen(date.getMonth()+1);
                var year = date.getFullYear();
                return year + '-' + monthIndex + '-' + day;
            },
            isLessThanTen(val){
                if(val < 10){
                    return '0' + val;
                }else{
                    return val;
                }
            },
            generateURL(){
                var params = jQuery.param({
                    'turn': this.turn,
                    'date': this.formatURLDate(this.date),
                });
                this.share_url =  this.url + '/restaurant/'+this.restaurant + '?'+  params;
                //http://aplicacion-restaurantes.local/restaurant/1?turn=10:10&date=2019-01-07
            },
            onCopy() {
                this.btn_class = 'copied-code';
                this.text = 'general.copied';
                setTimeout(() => {
                    this.btn_class = '';
                    this.text = 'general.copy';
                }, 3000);
            },
            tagClass(tag_id){
                return 'dish_tag--'+tag_id;
            }
        },
        mounted() {
            this.getTurns();
        },
        watch: {
            date: function() {
                this.getTurns();
            },
            turn: function() {
                this.getTurns();
            },
        }
    }
</script>


<style>
    .popover-inner {
        background: white;
        color: black;
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(0,0,0, .1);
    }

    .popover-arrow {
        border-color: red;
    }
</style>

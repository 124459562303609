var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-turns" }, [
    _c("div", { staticClass: "mb-4 box--shadowed vue-turns__info" }, [
      _c(
        "h3",
        {
          staticClass:
            "d-flex justify-content-between align-items-center heading-2 text-brand"
        },
        [
          _c("span", [_vm._v(_vm._s(_vm._f("trans")("general.info")))]),
          _vm._v(" "),
          _c("i", { staticClass: "icon-info" })
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("trans")("general.turn_list_info")) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box--shadowed" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center vue-turns__header"
          },
          [
            _c("h4", { staticClass: "heading-2 text-brand vue-turns__title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("general.add_turn")) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-action btn-action--add",
              attrs: { disabled: _vm.loading },
              on: { click: _vm.addTurn }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "div",
            {
              staticClass: "box--shadowed bg-danger text-white mb-4",
              staticStyle: { display: "none" },
              attrs: { id: "alert-error" }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("general.error_deleting_turn")) +
                  "\n            "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm._l(_vm.turns, function(turn, index) {
          return _c(
            "div",
            { staticClass: "row mx-0 box--shadowed vue-turns__turn" },
            [
              _c(
                "div",
                {
                  staticClass: "col-12 col-md-4 vue-turns__turn__name",
                  class: { "has-error": _vm.errors.has("name_" + index) }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: turn.name,
                        expression: "turn.name"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3|max:255",
                        expression: "'required|min:3|max:255'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "name_" + index,
                      "data-vv-as": _vm.input.turn[_vm.locale]
                    },
                    domProps: { value: turn.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(turn, "name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("name_" + index)
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("name_" + index)))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-12 col-md-3 vue-turns__turn__frequencies",
                  class: { "has-error": _vm.errors.has("frequencies_" + index) }
                },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    attrs: {
                      "data-vv-as": _vm.input.search[_vm.locale],
                      options: _vm._f("prepare_select")(
                        _vm.frequencies,
                        "name"
                      ),
                      reduce: function(option) {
                        return option.id
                      },
                      multiple: "",
                      name: "frequencies_" + index
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function(option) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "vs__selected_tag turn_tag",
                                  class: "turn_tag--" + option.id
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "vs__selected_tag__label" },
                                    [_vm._v(_vm._s(option.label))]
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: turn.frequencies,
                      callback: function($$v) {
                        _vm.$set(turn, "frequencies", $$v)
                      },
                      expression: "turn.frequencies"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("frequencies_" + index)
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("frequencies_" + index)))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-12 col-md-2 vue-turns__turn__start",
                  class: { "has-error": _vm.errors.has("since_" + index) }
                },
                [
                  _c("vue-timepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    attrs: {
                      format: "HH:mm",
                      "minute-interval": 15,
                      "close-on-complete": "",
                      "hide-clear-button": "",
                      "data-vv-as": _vm.input.data_since[_vm.locale],
                      name: "since_" + index
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeHandler($event, index)
                      }
                    },
                    model: {
                      value: turn.start,
                      callback: function($$v) {
                        _vm.$set(turn, "start", $$v)
                      },
                      expression: "turn.start"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("since_" + index)
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("since_" + index)))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-12 col-md-2 vue-turns__turn__end",
                  class: { "has-error": _vm.errors.has("until_" + index) }
                },
                [
                  _c("vue-timepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value:
                          "required|date_format:HH:mm|date_between:" +
                          turn.start +
                          ",23:50",
                        expression:
                          "'required|date_format:HH:mm|date_between:'+turn.start+',23:50'"
                      }
                    ],
                    attrs: {
                      format: "HH:mm",
                      "minute-interval": 15,
                      "close-on-complete": "",
                      "hide-clear-button": "",
                      "data-vv-as": _vm.input.data_until[_vm.locale],
                      name: "until_" + index
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeHandlerEnd($event, index)
                      }
                    },
                    model: {
                      value: turn.end,
                      callback: function($$v) {
                        _vm.$set(turn, "end", $$v)
                      },
                      expression: "turn.end"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("until_" + index)
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("until_" + index)))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions"
                },
                [
                  _c("button", {
                    staticClass: "btn-action btn-action--remove",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.removeTurn(index)
                      }
                    }
                  })
                ]
              )
            ]
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-end mt-4 vue-turns__submit" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-brand-outline",
            attrs: { type: "button", disabled: _vm.loading },
            on: { click: _vm.save }
          },
          [
            _vm.loading
              ? _c("i", { staticClass: "icon icon-spinner spinner" })
              : _vm._e(),
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("trans")("general.save")) +
                "\n        "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-datatable-search" }, [
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c(
        "label",
        {
          staticClass: "mr-2 vue-datatable-search__label",
          attrs: { for: "search" }
        },
        [_vm._v(_vm._s(_vm._f("trans")("general.find")))]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.term,
            expression: "term"
          }
        ],
        staticClass: "form-control vue-datatable-search__input",
        attrs: { type: "text", name: "search" },
        domProps: { value: _vm.term },
        on: {
          change: _vm.search,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.term = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
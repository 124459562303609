<template>
    <div class="box--shadowed">
        <div class="mb-4 d-flex justify-content-between align-items-center vue-closings__header">
            <div class="btn-group" role="group">
                <!--                <button v-if="!isCurrentMonth" type="button" class="btn btn-brand" @click="prevMonth">-->
                <button type="button" class="btn btn-brand" @click="prevMonth">
                    <i class="icon icon-arrow-left2"></i>
                </button>
                <button type="button" class="btn btn-brand" @click="currentMonth" v-bind:disabled="isCurrentMonth">
                    {{ 'general.current_month' | trans }}
                </button>
                <button type="button" class="btn btn-brand" @click="nextMonth">
                    <i class="icon icon-arrow-right2"></i>
                </button>
            </div>
            <h3 class="heading-2 text-brand vue-closings__title">
                {{ display_date | monthYearName }}
            </h3>
            <button class="btn-action btn-action--add" @click="openModal"></button>
        </div>

        <div v-if="loading" class="loader">
            <h3>{{ 'general.loading' | trans }}</h3>
        </div>
        <div v-else>

            <template v-if="current_view === 'calendar'">
                <FullCalendar
                    ref="fullCalendar"
                    defaultView="dayGridMonth"
                    :plugins="calendarPlugins"
                    :events="events"
                    :header="false"
                    :locale="this.locale"
                    :first-day="1"
                    :event-render="eventRendered"
                    :default-date="display_date"
                    @eventClick="editEvent"
                />
            </template>

            <template v-else>
                <div v-if="days.length > 0">
                    <div v-for="day in days" class=" d-flex p-0 mb-4 box--shadowed overflow-hidden">
                        <h3 class="p-4 m-0 d-flex flex-column justify-content-center align-items-center heading-2 text-white day--header"
                            v-bind:class="[{'bg-danger': day.closed}, {'bg-brand': !day.closed}]">
                            <span>{{ day.date | toDate | weekDay }}</span>
                            <span>{{ day.date | toDate | day }}</span>
                        </h3>
                        <div class="px-4 pt-4 m-0 d-flex flex-column w-100 justify-content-center">
                            <h3 v-if="day.closed" class="text-center mb-4">{{ 'general.closed' | trans }}</h3>
                            <template v-for="(element, index) in day.elements">
                                <span v-if="element.menus.length <= 0"
                                      class="badge bg-brand text-white heading-3 mb-4"
                                      style="opacity: 0.5;"
                                ><strong>{{ element.turn }}:</strong></span>
                                <a href="" v-else
                                   class="badge bg-brand text-white heading-3 mb-4"
                                   @click.prevent="editElement(day.date, index, 'turn')"
                                >
                                    <strong>{{ element.turn }}:</strong>
                                    {{ element.menus.join(', ') }}
                                </a>
                            </template>
                        </div>
                    </div>
                </div>
            </template>

        </div>

        <slot name="legends">
            <div class="reservations_calendar__legends" slot="legends">
                <ul class="legends__list">
                    <li class="legend legend--today">
                        <span class="legend__label">{{ 'general.today' | trans}}</span>
                    </li>
                    <li class="legend legend--holiday">
                        <span class="legend__label">{{ 'general.sundays_and_holidays' | trans }}</span>
                    </li>
                    <li class="legend legend--closed">
                        <span class="legend__label">{{ 'general.closed' | trans }}</span>
                    </li>
                </ul>
            </div>
        </slot>

        <portal to="modals">
            <modal-add-menu-calendar
                v-if="modal_open"
                :menus="menus"
                @close="closeModal"
            ></modal-add-menu-calendar>
            <modal-edit-menu-calendar
                v-if="modal_edit_open"
                :data="edit_data"
                @close="closeModal"
            ></modal-edit-menu-calendar>
        </portal>
    </div>
</template>

<script>
    import dateFormat from "dateformat";
    import {closeModal, openModal} from "../modal";
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import '@fullcalendar/core/main.css';
    import '@fullcalendar/daygrid/main.css';

    export default {
        components: {
            FullCalendar,
        },
        data() {
            return {
                calendarPlugins: [dayGridPlugin],
                current_view: this.active_view,
                display_date: new Date(this.date),
                loading: true,
                menus: [],
                days: [],
                events: [],
                modal_open: false,
                modal_edit_open: false,
                edit_data: null,
                hover_event: {
                    entered: false,
                    target: 'app',
                    title: '',
                    content: '',
                }
            };
        },
        props: ['date', 'active_view', 'locale'],
        computed: {
            isCurrentMonth() {
                var this_month = new Date();
                return this_month.getMonth() === this.display_date.getMonth();
            },
        },
        watch: {
            active_view(val) {
                this.current_view = val;
            }
        },
        methods: {
            getMenuDates() {
                this.loading = true;
                var params = {
                    date: dateFormat(this.display_date, 'yyyy-mm-dd'),
                };
                this.days = [];
                axios.get(window.config.context_prefix+'/api/restaurant/menu-calendar', {params: params})
                    .then(response => {
                        this.days = response.data.days;
                        this.events = response.data.events;
                        this.loading = false;
                    });
            },
            getMenus() {
                axios.get(window.config.context_prefix+'/api/restaurant/all-menus')
                    .then(response => {
                        this.menus = response.data.data;
                    });
            },
            nextMonth() {
                var year = this.display_date.getFullYear();
                var month = this.display_date.getMonth() + 1;
                this.display_date = new Date(year, month, 1);
                this.getMenuDates();
            },
            prevMonth() {
                var year = this.display_date.getFullYear();
                var month = this.display_date.getMonth() - 1;
                this.display_date = new Date(year, month, 1);
                if (this.isCurrentMonth) {
                    this.display_date = new Date();
                }
                this.getMenuDates();
            },
            currentMonth() {
                this.display_date = new Date();
                this.getMenuDates();
            },
            openModal() {
                this.modal_open = true;
                openModal();
            },
            closeModal(reload) {
                if (reload) {
                    this.getMenuDates();
                }
                this.modal_open = false;
                this.modal_edit_open = false;
                closeModal();
            },
            editElement(date, element_id, type) {
                this.edit_data = {
                    date: date,
                };
                this.edit_data[type] = element_id;
                this.modal_edit_open = true;
                openModal();
            },
            editEvent(info) {
                var e_start = info.event.start;
                var e_end = info.event.end;
                var days = (e_end.getTime() - e_start.getTime()) / (1000 * 3600 * 24);
                var date = '';
                if (days > 1) {
                    e_end = new Date(e_end);
                    e_end.setDate(e_end.getDate() - 1);
                    date = this.$options.filters.date(e_start) + ' - ' + this.$options.filters.date(e_end)
                } else {
                    date = this.$options.filters.date(e_start);
                }
                this.edit_data = {
                    date: date,
                    from: info.event.start,
                    to: info.event.end,
                    menu: info.event.extendedProps.menu_id,
                };
                this.modal_edit_open = true;
                openModal();
            },
            eventRendered(info) {
                if (info.event.title) {
                    info.el.setAttribute('title', info.event.title);
                    info.el.setAttribute('data-toggle', 'tooltip');
                    $(info.el).tooltip();
                }
            },
        },
        mounted() {
            this.getMenus();
            this.getMenuDates();
        }
    }
</script>

<style>
    .fc-day-grid-event {
        cursor: pointer;
    }
</style>

<template>
    <div class="vue-gallery">
        <div class="box--shadowed vue-gallery__upload">
            <upload-image
                :url="url"
                button_class="btn btn-brand-outline"
                @upload-image-success="getMedia"
                :data-label="'general.upload_images' | trans"
                :button_html="'general.upload_images_btn' | trans"
            >
            </upload-image>
        </div>
        <div class="box--shadowed mt-5 vue-gallery__gallery">
            <h3 class="d-flex mb-4 justify-content-between align-items-center heading-2 text-brand gallery__title">
                <span>{{ 'general.gallery' | trans}}</span>
            </h3>
            <draggable
                class="row"
                v-model="gallery"
                @start="drag=true"
                @end="drag=false"
                @update="updateMedia"
            >
                <div v-for="image in gallery" :key="image.id" class="col-6 col-md-4 col-lg-3 mb-4 gallery__image"
                        v-bind:class="{'gallery__image--featured': image.featured}"
                        :title="image.featured ? 'Featured': ''">
                    <figure class="gallery__image__wrapper" v-bind:style="'background-image: url('+image.url+');'">
                    </figure>
                    <figcaption class="gallery__image__actions">
                        <template v-if="image.featured">
                            <button class="gallery__image__action gallery__image__action--hide" v-if="image.gallery"
                                    title="Hide" @click="hideMediaFromGallery(image.id)">
                                <i class="icon icon-eye-blocked"></i>
                            </button>
                            <button class="gallery__image__action gallery__image__action--show" v-else title="show"
                                    @click="showMediaOnGallery(image.id)">
                                <i class="icon icon-eye"></i>
                            </button>
                        </template>
                        <template v-else>
                            <button class="gallery__image__action gallery__image__action--feature" title="Feature"
                                    @click="openFeatureModal(image.id)">
                                <i class="icon icon-star-full"></i>
                            </button>
                            <button class="gallery__image__action gallery__image__action--delete" title="Delete"
                                    @click="openDeleteModal(image.id)">
                                <i class="icon icon-bin"></i>
                            </button>
                        </template>
                    </figcaption>
                </div>
            </draggable>
        </div>
        <portal to="modals">
            <confirmation-modal
                v-if="delete_modal_open"
                :url="modal_url"
                :title="'general.confirm_delete' | trans"
                :text="'general.confirm_delete_p' | trans"
                :params="{ gallery: gallery, id: this.restaurant_id}"
                @close="closeDeleteModal"
            ></confirmation-modal>
            <confirmation-modal
                v-if="feature_modal_open"
                :url="modal_url"
                :title="'general.confirm_feature' | trans"
                :text="'general.confirm_feature_p' | trans"
                :params="{ gallery: gallery, id: this.restaurant_id}"
                level="success"
                @close="closeFeatureModal"
            ></confirmation-modal>
        </portal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import {closeModal, openModal} from "../modal";

    export default {
        components: {
            draggable,
        },
        data() {
            return {
                gallery: [],
                loading: true,
                delete_modal_open: false,
                feature_modal_open: false,
                modal_url: window.config.context_prefix+'/api/'+ this.role +'/media/update',
            };
        }
        ,
        props: ['url', 'restaurant_id', 'role'],
        methods:
            {
                getMedia() {
                    axios.get(window.config.context_prefix+'/api/'+ this.role +'/media?id='+ this.restaurant_id)
                        .then(response => {
                            this.gallery = response.data;
                            this.loading = false;
                        })
                },
                updateMedia() {
                    this.loading = true;
                    axios.post(window.config.context_prefix+'/api/'+ this.role +'/media/update', {gallery: this.gallery, id:this.restaurant_id})
                        .then(response => {
                            this.getMedia();
                        })
                },
                hideMediaFromGallery(media_id) {
                    this.resetMediaChanges();
                    _.map(this.gallery, image => {
                        if (image.id == media_id) {
                            image.changes = {
                                gallery: false,
                            }
                        }
                        return image;
                    });
                    this.updateMedia();
                },
                showMediaOnGallery(media_id) {
                    this.resetMediaChanges();
                    _.map(this.gallery, image => {
                        if (image.id == media_id) {
                            image.changes = {
                                gallery: true,
                            }
                        }
                        return image;
                    });
                    this.updateMedia();
                },
                resetMediaChanges() {
                    _.map(this.gallery, image => {
                        image.changes = {};
                        return image;
                    });
                },
                openDeleteModal(media_id) {
                    this.delete_modal_open = true;
                    openModal();
                    this.resetMediaChanges();
                    _.map(this.gallery, image => {
                        if (image.id == media_id) {
                            image.changes = {
                                gallery: false,
                            }
                        }
                        return image;
                    });
                },
                closeDeleteModal(success) {
                    this.delete_modal_open = false;
                    closeModal();
                    if (success) {
                        this.getMedia();
                    }
                },
                openFeatureModal(media_id) {
                    this.feature_modal_open = true;
                    openModal();
                    this.resetMediaChanges();
                    _.map(this.gallery, image => {
                        if (image.featured) {
                            image.changes = {
                                featured: false,
                            };
                            if (!image.gallery) {
                                image.changes['gallery'] = true;
                            }
                        }
                        if (image.id == media_id) {
                            image.changes = {
                                featured: true,
                            }
                        }
                        return image;
                    });
                },
                closeFeatureModal(success) {
                    this.feature_modal_open = false;
                    closeModal();
                    if (success) {
                        this.getMedia();
                    }
                },
            }
        ,
        mounted() {
            this.getMedia();
        }
    }
</script>

<template>
    <transition name="fade-shrink-in" appear>
        <div class="modal show" style="padding-right: 17px; display: block;"
             @click.prevent="close(false)">
            <div class="modal-dialog modal-lg modal-dialog-centered" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="myLargeModalLabel">{{ 'general.add_period' | trans }}</h5>
                        <button type="button" class="close" @click.prevent="close(false)">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <label for="select_menu">{{ 'general.select_menu' | trans }}</label>
                                <v-select
                                    v-model="selected_menu"
                                    :placeholder="'general.select_menu' | trans"
                                    :options="menus | prepare_select('name')"
                                    :reduce="menu => menu.id"
                                    id="select_menu"
                                    multiple
                                ></v-select>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12">
                                <label>{{ 'general.menu_serve_dates' | trans }}</label>
                                <div v-for="(range, index) in selected_ranges">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                                        v-model="selected_ranges[index]"
                                        :min-date="min_date"
                                        :auto-apply="auto_apply"
                                        :ranges="false"
                                        @update="selectRange"
                                    >
                                        <div slot="input" slot-scope="picker">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </div>
                                    </date-range-picker>
                                    <button v-if="index == 0" class="btn-action btn-action--add"
                                            @click="addRange"></button>
                                    <button v-else class="btn-action btn-action--remove"
                                            @click="removeRange(index)"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close(false)">
                            {{ 'general.cancel' | trans }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-brand"
                            @click.prevent="save"
                            v-bind:disabled="save_disabled || save_loading"
                        >
                            <i v-if="save_loading" class="spinner icon-spinner"></i>
                            {{ 'general.add' | trans }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {
        components: {
            DateRangePicker
        },
        data() {
            return {
                save_disabled: false,
                save_loading: false,
                selected_menu: [],
                selected_ranges: [''],
                min_date: new Date(),
                auto_apply: true,
            };
        },
        props: ['menus'],
        methods: {
            close(reload) {
                this.$emit('close', reload);
            },
            selectRange() {

            },
            addRange() {
                this.selected_ranges.push('');
            },
            removeRange(index) {
                this.selected_ranges.splice(index, 1);
            },
            save() {
                this.save_loading = true;
                this.save_disabled = true;
                var params = {
                    menus: this.selected_menu,
                    ranges: this.selected_ranges,
                };
                axios.post(window.config.context_prefix+'/api/restaurant/menu-calendar/save', params)
                    .then(response => {
                        this.save_loading = false;
                        this.save_disabled = false;
                        this.$emit('close', true);
                    }).catch(error => {
                    this.save_loading = false;
                    this.save_disabled = false;
                    this.$emit('close', false);
                });
            }
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.key },
    [
      _vm.is_full_table_error
        ? _c(
            "div",
            { staticClass: "box--shadowed bg-danger text-white mb-4" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("trans")("general.same_menu")) +
                  "\n            "
              ),
              _c(
                "ul",
                _vm._l(_vm.selected_menu_error, function(menu) {
                  return _c("li", [_vm._v(_vm._s(menu.name))])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.diners, function(diner, index) {
        return _c(
          "div",
          {
            staticClass: "collapse_container box--shadowed",
            class: { "mt-4": index > 0 }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center collapse__toggler",
                attrs: {
                  "data-toggle": "collapse",
                  "data-target": "#diner_" + index,
                  "aria-expanded": "false",
                  "aria-controls": "#diner_" + index
                }
              },
              [
                _c(
                  "h3",
                  {
                    staticClass: "collapse__toggler__label text-brand",
                    class: {
                      "text-danger":
                        _vm.maxed_menu(index) ||
                        !_vm.hasMenuAndIsRequiredDishSelectedFor(index)
                    }
                  },
                  [
                    _vm.maxed_menu(index) ||
                    !_vm.isRequiredDishSelectedFor(index)
                      ? _c("span", [_vm._v("*")])
                      : _vm._e(),
                    _vm._v(" "),
                    diner.name
                      ? _c("span", [_vm._v(_vm._s(diner.name))])
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("trans")("general.diner")) +
                              " " +
                              _vm._s(index + 1)
                          )
                        ]),
                    _vm._v(" "),
                    diner.menu
                      ? [
                          _c("span", [
                            _vm._v("(" + _vm._s(diner.menu.name) + ")")
                          ]),
                          _vm._v(" "),
                          diner.menu.menu_type_id == 2
                            ? _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.getMenuPrice(index)) +
                                    "\n                        "
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "icon-ctrl collapse__toggler__icon text-brand"
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "collapse", attrs: { id: "diner_" + index } },
              [
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "name_" + index } }, [
                        _vm._v(
                          _vm._s(_vm._f("trans")("general.diner_name")) + " *"
                        )
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.diners[index].name,
                            expression: "diners[index].name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name_" + index, type: "text" },
                        domProps: { value: _vm.diners[index].name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.diners[index],
                              "name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "menu_id_" + index } }, [
                          _vm._v(_vm._s(_vm._f("trans")("general.menu")))
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          ref: "menu_id",
                          refInFor: true,
                          attrs: {
                            id: "menu_id_" + index,
                            name: "menu_id",
                            options: _vm._f("prepare_select")(
                              _vm.menus,
                              "name"
                            ),
                            reduce: function(menu) {
                              return menu.id
                            }
                          },
                          on: {
                            input: function($event) {
                              return _vm.selectMenuForDiner(index)
                            }
                          },
                          model: {
                            value: _vm.diners[index].menu_id,
                            callback: function($$v) {
                              _vm.$set(_vm.diners[index], "menu_id", $$v)
                            },
                            expression: "diners[index].menu_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.diners[index].menu &&
                    _vm.diners[index].menu.menu_type_id == 1
                      ? [
                          _c("hr", { staticClass: "full-width" }),
                          _vm._v(" "),
                          _c("h3", { staticClass: "mb-3" }, [
                            _vm._v(_vm._s(_vm._f("trans")("general.dishes")))
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.diners[index].menu.elements, function(
                            category
                          ) {
                            return [
                              _c("h5", { staticClass: "my-2" }, [
                                _vm._v(_vm._s(category.name))
                              ]),
                              _vm._v(" "),
                              _vm._l(category.dishes, function(dish) {
                                return _c(
                                  "div",
                                  { staticClass: "form-group form-check" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.diners[index].dishes,
                                          expression: "diners[index].dishes"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "dish_" + index + "_" + dish.id
                                      },
                                      domProps: {
                                        value: dish.id,
                                        checked: Array.isArray(
                                          _vm.diners[index].dishes
                                        )
                                          ? _vm._i(
                                              _vm.diners[index].dishes,
                                              dish.id
                                            ) > -1
                                          : _vm.diners[index].dishes
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.diners[index].dishes,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = dish.id,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.diners[index],
                                                  "dishes",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.diners[index],
                                                  "dishes",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.diners[index],
                                              "dishes",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: {
                                          for: "dish_" + index + "_" + dish.id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(dish.name) +
                                            " ("
                                        ),
                                        dish.price !== null
                                          ? _c("span", [
                                              _vm._v(_vm._s(dish.price))
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        dish.price_type !== null
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    dish.price_type.label
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          ")\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ]
                          })
                        ]
                      : _vm.diners[index].menu &&
                        _vm.diners[index].menu.menu_type_id == 2
                      ? [
                          _vm.maxed_menu(index)
                            ? [
                                _c("hr", { staticClass: "full-width" }),
                                _vm._v(" "),
                                _c("strong", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("trans")("general.maxed_out", {
                                        number: _vm.maxNoPlatesForSelection(
                                          index
                                        )
                                      })
                                    )
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.maxed_menu(index) &&
                          !_vm.isRequiredDishSelectedFor(index)
                            ? [
                                _c("hr", { staticClass: "full-width" }),
                                _vm._v(" "),
                                _c("strong", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("trans")(
                                        "general.select_dish_required"
                                      )
                                    )
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("hr", { staticClass: "full-width" }),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.diners[index].menu.observations_locale
                              )
                            }
                          }),
                          _vm._v(" "),
                          _vm.diners[index].menu.half_menu
                            ? [
                                _c("hr", { staticClass: "full-width" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "row px-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-check form-check-inline"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.diners[index].half_menu,
                                            expression:
                                              "diners[index].half_menu"
                                          }
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: {
                                          type: "radio",
                                          id: "diner_menu_full_" + index
                                        },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm.diners[index].half_menu,
                                            false
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.diners[index],
                                              "half_menu",
                                              false
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: {
                                            for: "diner_menu_full_" + index
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm._f("trans")(
                                                  "general.full_menu"
                                                )
                                              ) +
                                              " (" +
                                              _vm._s(
                                                _vm.diners[index].menu.price
                                              ) +
                                              " €)\n                                    "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-check form-check-inline"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.diners[index].half_menu,
                                            expression:
                                              "diners[index].half_menu"
                                          }
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: {
                                          type: "radio",
                                          id: "diner_menu_half_" + index
                                        },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm.diners[index].half_menu,
                                            true
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.diners[index],
                                              "half_menu",
                                              true
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: {
                                            for: "diner_menu_half_" + index
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm._f("trans")(
                                                  "general.half_menu"
                                                )
                                              ) +
                                              " (" +
                                              _vm._s(
                                                _vm.diners[index].menu
                                                  .half_price
                                              ) +
                                              " €)\n                                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.diners[index].menu.observations_locale ||
                          _vm.diners[index].menu.half_menu
                            ? _c("hr", { staticClass: "full-width" })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h3", [
                            _vm._v(_vm._s(_vm._f("trans")("general.dishes")))
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.diners[index].menu.elements, function(
                            category,
                            c
                          ) {
                            return [
                              category.dish_option == 1 &&
                              category.required == 1
                                ? [
                                    _c("h5", { staticClass: "my-2" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(category.name) +
                                          "\n                                    "
                                      ),
                                      _c("small", [
                                        _vm._v(
                                          "* " +
                                            _vm._s(
                                              _vm._f("trans")(
                                                "general.one_required"
                                              )
                                            )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(category.dishes, function(dish, i) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass: "form-group form-check"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.diners[index].dishes,
                                                expression:
                                                  "diners[index].dishes"
                                              }
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              type: "checkbox",
                                              id:
                                                "dish_" +
                                                index +
                                                "_" +
                                                c +
                                                "_" +
                                                i
                                            },
                                            domProps: {
                                              value: dish.id,
                                              checked: Array.isArray(
                                                _vm.diners[index].dishes
                                              )
                                                ? _vm._i(
                                                    _vm.diners[index].dishes,
                                                    dish.id
                                                  ) > -1
                                                : _vm.diners[index].dishes
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.diners[index].dishes,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = dish.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.diners[index],
                                                        "dishes",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.diners[index],
                                                        "dishes",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.diners[index],
                                                    "dishes",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: {
                                                for:
                                                  "dish_" +
                                                  index +
                                                  "_" +
                                                  c +
                                                  "_" +
                                                  i
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(dish.name) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ]
                                : category.required == 1
                                ? [
                                    _c("h5", { staticClass: "my-2" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(category.name) +
                                          "\n                                    "
                                      ),
                                      _c("small", [
                                        _vm._v(
                                          "* " +
                                            _vm._s(
                                              _vm._f("trans")(
                                                "general.at_least_one_required"
                                              )
                                            )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(category.dishes, function(dish, i) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass: "form-group form-check"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.diners[index].dishes,
                                                expression:
                                                  "diners[index].dishes"
                                              }
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              type: "checkbox",
                                              id:
                                                "dish_" +
                                                index +
                                                "_" +
                                                c +
                                                "_" +
                                                i
                                            },
                                            domProps: {
                                              value: dish.id,
                                              checked: Array.isArray(
                                                _vm.diners[index].dishes
                                              )
                                                ? _vm._i(
                                                    _vm.diners[index].dishes,
                                                    dish.id
                                                  ) > -1
                                                : _vm.diners[index].dishes
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.diners[index].dishes,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = dish.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.diners[index],
                                                        "dishes",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.diners[index],
                                                        "dishes",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.diners[index],
                                                    "dishes",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: {
                                                for:
                                                  "dish_" +
                                                  index +
                                                  "_" +
                                                  c +
                                                  "_" +
                                                  i
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(dish.name) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ]
                                : [
                                    _c("h5", { staticClass: "my-2" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(category.name) +
                                          "\n                                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(category.dishes, function(dish, i) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass: "form-group form-check"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.diners[index].dishes,
                                                expression:
                                                  "diners[index].dishes"
                                              }
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              type: "checkbox",
                                              id:
                                                "dish_" +
                                                index +
                                                "_" +
                                                c +
                                                "_" +
                                                i
                                            },
                                            domProps: {
                                              value: dish.id,
                                              checked: Array.isArray(
                                                _vm.diners[index].dishes
                                              )
                                                ? _vm._i(
                                                    _vm.diners[index].dishes,
                                                    dish.id
                                                  ) > -1
                                                : _vm.diners[index].dishes
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.diners[index].dishes,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = dish.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.diners[index],
                                                        "dishes",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.diners[index],
                                                        "dishes",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.diners[index],
                                                    "dishes",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: {
                                                for:
                                                  "dish_" +
                                                  index +
                                                  "_" +
                                                  c +
                                                  "_" +
                                                  i
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(dish.name) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ]
                            ]
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "box--square bg-brand-light text-white mt-4 align-items-center",
          attrs: { disabled: _vm.loading },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.save(false)
            }
          }
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm._f("trans")("general.save")))]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.trans("general.save_selection_p")
              )
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "box--square bg-brand text-white mt-1 align-items-center",
          attrs: {
            disabled:
              _vm.loading ||
              _vm.is_full_table_error ||
              !_vm.can_confirm ||
              !_vm.is_all_required_selected
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.save(true)
            }
          }
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm._f("trans")("general.confirm")))]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.trans("general.confirm_selection_p")
              )
            }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-turns" }, [
    _c("div", { staticClass: "mb-4 box--shadowed vue-turns__info" }, [
      _c(
        "h3",
        {
          staticClass:
            "d-flex justify-content-between align-items-center heading-2 text-brand"
        },
        [
          _c("span", [_vm._v(_vm._s(_vm._f("trans")("general.info")))]),
          _vm._v(" "),
          _c("i", { staticClass: "icon-info" })
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("trans")("general.food_type_list_info")) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box--shadowed mb-4" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center vue-turns__header"
          },
          [
            _c("h4", { staticClass: "heading-2 text-brand vue-turns__title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("general.add_caracteristics")) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-action btn-action--add",
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.addCategoriesMenu()
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "div",
            {
              staticClass: "box--shadowed bg-danger text-white mb-4",
              staticStyle: { display: "none" },
              attrs: { id: "alert-error" }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("general.error_deleting_food_type")) +
                  "\n            "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm._l(_vm.categories, function(type, index) {
          return _c(
            "div",
            { staticClass: "row mx-0 box--shadowed vue-turns__turn" },
            [
              _c(
                "div",
                { staticClass: "col-12 col-md-6 vue-turns__turn__name" },
                [
                  _c(
                    "dummy-multilang",
                    {
                      attrs: { langs: _vm.langs, field_name: "category_name" }
                    },
                    [
                      _vm._l(_vm.langs, function(lang) {
                        return _c("template", { slot: lang }, [
                          _c(
                            "div",
                            {
                              class: {
                                "has-error": _vm.errors.has(
                                  "tag_name_" + index + "_" + lang
                                )
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.categories[index]["name"][lang],
                                    expression:
                                      "categories[index]['name'][lang]"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|min:3|max:255",
                                    expression: "'required|min:3|max:255'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "tag_name_" + index + "_" + lang,
                                  maxlength: "255",
                                  "data-vv-as":
                                    _vm.input["tag_name_" + lang][_vm.locale]
                                },
                                domProps: {
                                  value: _vm.categories[index]["name"][lang]
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.categories[index]["name"],
                                      lang,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.langs, function(lang) {
                    return [
                      _c("p", { staticClass: "m-0" }, [
                        _vm.errors.has("tag_name_" + index + "_" + lang)
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.errors.first(
                                      "tag_name_" + index + "_" + lang
                                    )
                                  ) +
                                  "\n                        "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions mt-4"
                },
                [
                  _c("button", {
                    staticClass: "btn-action btn-action--remove",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.removeCategories(index)
                      }
                    }
                  })
                ]
              )
            ]
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-end mt-4 vue-turns__submit" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-brand-outline",
            attrs: { type: "button", disabled: _vm.loading },
            on: { click: _vm.save }
          },
          [
            _vm.loading
              ? _c("i", { staticClass: "icon icon-spinner spinner" })
              : _vm._e(),
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("trans")("general.save")) +
                "\n        "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-datatable-search">
        <div class="d-flex align-items-center">
            <label class="mr-2 vue-datatable-search__label" for="search">{{'general.find' | trans}}</label>
            <input v-model="term" type="text" name="search" class="form-control vue-datatable-search__input" @change="search">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                term: '',
            }
        },
        methods: {
            search() {
                this.$emit("search", this.term);
            }
        }
    }
</script>

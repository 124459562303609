var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vue-datatable-actions btn-group" },
        _vm._l(_vm.actions, function(action) {
          return _c(
            "button",
            {
              class: ["btn", action.color],
              attrs: {
                type: "button",
                title: _vm._f("trans")("general.buttons." + action.name)
              },
              on: {
                click: function($event) {
                  return _vm.performAction(action)
                }
              }
            },
            [_c("i", { staticClass: "icon", class: action.icon })]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "datatable-modals" } },
        [
          _vm.confirmation_modal_open
            ? _c("confirmation-modal", {
                attrs: {
                  url: _vm.confirmation_modal_url,
                  title: _vm._f("trans")(_vm.title),
                  text: _vm._f("trans")(_vm.text),
                  level: _vm.level,
                  show_reason: _vm.show_reason,
                  params: _vm.params
                },
                on: { close: _vm.closeConfrimationModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_open
            ? _c("restaurant-booking-form-modal", {
                attrs: {
                  title: _vm._f("trans")(_vm.title),
                  reservation_id: _vm.confirmation_modal_url
                },
                on: { close: _vm.closeConfrimationModal }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-turns">
        <div class="mb-4 box--shadowed vue-turns__info">
            <h3 class="d-flex justify-content-between align-items-center heading-2 text-brand">
                <span>{{ 'general.info' | trans }}</span>
                <i class="icon-info"></i>
            </h3>
            <p>
                {{ 'general.category_list_info' | trans }}
            </p>
        </div>
        <div v-for="type in menu_types" class="box--shadowed mb-4">
            <div class="d-flex justify-content-between align-items-center vue-turns__header">
                <h4 class="heading-2 text-brand vue-turns__title">
                    {{ 'general.add_category_to' | trans }} {{type.name}}
                </h4>
                <button class="btn-action btn-action--add" @click="addCategoriesMenu(type.id)" v-bind:disabled="loading"></button>
            </div>
            <p>
                {{ 'general.category_list_default' | trans }}:
                <span v-for="(element, key) in default_categories[type.id]">
                    <span v-if="key !== 0">, </span><span>{{ element.name[locale] }}</span>
                </span>
            </p>
            <div class="row mx-0 box--shadowed vue-turns__turn" v-for="(element, key) in categories[type.id]">
                <div class="col-12 col-md-6 vue-turns__turn__name">

                    <dummy-multilang :langs="langs" field_name="category_name">
                        <template v-for="lang in langs" :slot="lang">
                            <div v-bind:class="{'has-error' : errors.has('category_name_' + type.id + '_' + key + '_' + lang)}">
                                    <input
                                        v-model="categories[type.id][key]['name'][lang]"
                                        type="text"
                                        :name="'category_name_' + type.id + '_' + key + '_' + lang"
                                        class="form-control"
                                        v-validate="'required|min:3|max:255'"
                                        maxlength="255"
                                        v-bind:data-vv-as="input['category_name_' + lang][locale]"
                                    >
                            </div>
                        </template>
                    </dummy-multilang>
                    <template v-for="lang in langs">
                        <p class="m-0">
                            <small v-if="errors.has('category_name_' + type.id + '_' + key + '_' + lang)" class="text-danger">
                                {{ errors.first('category_name_' + type.id + '_' + key + '_' + lang) }}
                            </small>
                        </p>
                    </template>

                </div>
                <div class="col-12 col-md-1 d-flex justify-content-end vue-turns__turn__actions mt-4">
                    <button class="btn-action btn-action--remove" @click="removeCategories(type.id,key)" v-bind:disabled="loading"></button>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4 vue-turns__submit">
            <button class="btn btn-brand-outline" type="button" @click="save" v-bind:disabled="loading">
                <i v-if="loading" class="icon icon-spinner spinner"></i>
                {{ 'general.save' | trans }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                categories: {
                    1: {},
                    2: {},
                },
                default_categories: {
                    1: {},
                    2: {},
                },
                loading: false,
                input: {
                    category_name_es: {
                        es: 'nombre de categoría es',
                        eu: 'kategoria izena es',
                    },
                    category_name_eu: {
                        es: 'nombre de categoría eu',
                        eu: 'kategoria izena eu',
                    },
                },
            };
        },
        props: ['restaurant_id', 'locale', 'menu_types', 'langs'],
        methods: {
            getCategories() {
                this.loading = true;
                var params = {
                    'restaurant_id': this.restaurant_id,
                };
                axios.post(window.config.context_prefix+'/api/restaurant/restaurant-categories', params)
                    .then(response => {
                        this.categories[1] = response.data.carta_categories;
                        this.categories[2] = response.data.menu_categories;
                        this.default_categories[1] = response.data.admin_carta_categories;
                        this.default_categories[2] = response.data.admin_menu_categories;
                        this.loading = false;
                    }).catch(error => {
                    this.loading = false;
                    console.log('Ooops...')
                    });
            },

            addCategoriesMenu(type) {
                this.categories[type].push({
                    id : null,
                    name : {
                        es : null,
                        eu : null,
                    },
                });
            },
            removeCategories(type,index) {
                this.categories[type].splice(index, 1);
            },
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.loading = true;
                        var params = { 'categories' : this.categories };
                        axios.post(window.config.context_prefix+'/api/restaurant/save-categories', params)
                            .then(response => {
                                this.categories[1] = response.data.carta_categories;
                                this.categories[2] = response.data.menu_categories;
                                this.loading = false;
                            }).catch(error => {
                            this.loading = false;
                            console.log('Ooops...')
                        });
                    }
                    if(!result){
                        console.log('Not validate!');
                    }
                });
            },
        },
        mounted() {
            this.getCategories();
        },
    }
</script>

<template>
    <header class="datatable__heading">
        <div v-for="(column, key) in columns" class="datatable__cell"
             v-bind:class="'datatable__cell--' + key"
             v-bind:aria-label="column.title | trans"
             v-bind:style="'width:' + column.width + ';'">
            <a v-if="column.sortable" href="" class="datatable__heading__action" data-status=""
               @click.prevent="sortBy(key)">
                <span class="datatable__heading__label">{{ column.title | trans }}</span>

                <i class="fa fa-caret-up" :class="[sort[key] == 'desc' ? 'text-brand' : 'text-disabled']"></i>
                <i class="fa fa-caret-down" :class="[sort[key] == 'asc' ? 'text-brand' : 'text-disabled']"></i>
                <!--
                <i v-if="sort[key] == 'asc'" class="icon icon-sort-amount-asc datatable__heading__icon"></i>
                <i v-else-if="sort[key] == 'desc'"
                   class="icon icon-sort-amount-desc datatable__heading__icon"></i>
                -->
            </a>
            <span v-else class="datatable__heading__action" data-status="" role="button">
                <span class="datatable__heading__label">{{ column.title | trans }}</span>
            </span>
        </div>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                sort: this.order,
            };
        },
        props: ['columns', 'order'],
        methods: {
            sortBy(key) {
                if (this.sort[key] == 'asc') {
                    this.sort[key] = 'desc';
                } else if (this.sort[key] == 'desc') {
                    this.sort[key] = null;
                } else {
                    this.sort[key] = 'asc';
                }
                this.$emit('sort', this.sort);
            },
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-input-multi-lang" },
    [
      _c(
        "label",
        {
          staticClass: "input-multi-lang__label",
          attrs: { for: _vm.fieldData.name + "_" + this.lang }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm._f("trans")(_vm.fieldData.label)) +
              " -\n        " +
              _vm._s(this.lang) +
              " "
          )
        ]
      ),
      _vm._v(" "),
      _c("btn-switch-lang", { on: { changeLang: _vm.toggleLang } }),
      _vm._v(" "),
      _vm._l(_vm.langs, function(lang, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "input-multi-lang__input",
            class: {
              "has-error": _vm.errors.has(_vm.fieldData.name + "_" + lang)
            },
            attrs: { lang: lang }
          },
          [
            _vm.fieldData.input_type == "textarea"
              ? _c("VueTrix", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.fieldData.validateRules,
                      expression: "fieldData.validateRules"
                    }
                  ],
                  attrs: {
                    "data-vv-as": _vm.fieldInput,
                    id: _vm.fieldData.name + "_" + lang,
                    name: _vm.fieldData.name + "_" + lang,
                    type: "text"
                  },
                  on: { "trix-file-accept": _vm.uploadImage },
                  model: {
                    value: _vm.fieldModel[lang],
                    callback: function($$v) {
                      _vm.$set(_vm.fieldModel, lang, $$v)
                    },
                    expression: "fieldModel[lang]"
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.fieldData.validateRules,
                      expression: "fieldData.validateRules"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fieldModel[lang],
                      expression: "fieldModel[lang]"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    "data-vv-as": _vm.fieldInput,
                    id: _vm.fieldData.name + "_" + lang,
                    name: _vm.fieldData.name + "_" + lang,
                    type: "text"
                  },
                  domProps: { value: _vm.fieldModel[lang] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.fieldModel, lang, $event.target.value)
                    }
                  }
                }),
            _vm._v(" "),
            _vm.errors.has(_vm.fieldData.name + "_" + lang)
              ? _c("small", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.errors.first(_vm.fieldData.name + "_" + lang)
                      ) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="vue-input-multi-lang">
        <label :for="field_name + '_' + this.current_lang" class="input-multi-lang__label">
            {{ 'general.' + field_name | trans }} - {{this.current_lang}} <span v-if="required" class="required">*</span>
        </label>

        <btn-switch-lang v-on:changeLang="toggleLang"></btn-switch-lang>

        <div v-for="lang in langs" v-show="current_lang === lang" class="input-multi-lang__input">

            <slot :name="lang"></slot>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                current_lang: 'es',
            };
        },
        props: ['langs', 'field_name', 'required'],
        methods: {
            toggleLang() {
                if(this.current_lang === 'es') {
                    this.current_lang = 'eu';
                } else {
                    this.current_lang = 'es';
                }
            }
        }
    }
</script>
